import {
  Call,
  Queue,
  Side,
  User,
  UserCallPoint,
  CallPointType,
  OtherSide
} from "compass.js";
import { store } from "src/store";
import {
  getUserSide,
  shouldShowCallForUser,
  getCallPointTitle,
  getCallPointNumber
} from "./call";
import { trackEvent, TrackCategory, TrackAction } from "./track";
import { notificationDismiss, notificationShow } from "src/store/actions";
import listenInEnterSound from "src/assets/sounds/listenin-enter.mp3";
import listenInLeaveSound from "src/assets/sounds/listenin-leave.mp3";

export class CallMetadata {
  queue: Queue | undefined;
  desiredForTransfer: boolean = false;
  tracked: boolean = false;
  lastConnectedNumber: string;
  listeningToCall: Call | undefined;

  destinationLastConnectedTitle: string;
  destinationLastConnectedNumber: string;
  sourceLastConnectedTitle: string;
  sourceLastConnectedNumber: string;

  // This is the call id for the main call, if this is a consultation call
  attendedTransferMainLegId: string | undefined;
  // If we are in the progress of doing an attended transfer of this call,
  // this call id refers to the consultation call we've started
  attendedTransferConsultationLegId: string | undefined;

  readonly created: number;
  private isEndedState: boolean;
  private callLastConnectedTitle: string;
  private listenedByCallsList: Call[] = [];

  constructor(public call: Call) {
    this.created = call.source.timeCreated;
  }

  get isEnded() {
    return this.isEndedState;
  }

  set isEnded(value: boolean) {
    this.isEndedState = value;
    if (!value) {
      return;
    }
    const listenInNotification = store
      .getState()
      .notifications.items.find(
        item => item.uid === `listen-in-notification:${this.call.id}`
      );
    if (listenInNotification) {
      store.dispatch(notificationDismiss(listenInNotification.uid));
    }
  }

  get isAttendedTransferMainLeg(): boolean {
    return !!this.attendedTransferConsultationLegId;
  }

  get isAttendedTransferConsultationLeg(): boolean {
    return !!this.attendedTransferMainLegId;
  }

  get attendedTransferOtherLegId(): Call["id"] | null {
    return (
      this.attendedTransferMainLegId ||
      this.attendedTransferConsultationLegId ||
      null
    );
  }

  get listenedByCalls(): Call[] {
    return this.listenedByCallsList;
  }

  set listenedByCalls(calls: Call[]) {
    const loggedInUser = store.getState().auth.user as User;
    if (
      (this.call.source.type === CallPointType.user &&
        (this.call.source as UserCallPoint).userId === loggedInUser.id) ||
      (this.call.destination.type === CallPointType.user &&
        (this.call.destination as UserCallPoint).userId === loggedInUser.id)
    ) {
      // NOTE: new listened by calls
      calls
        .filter(call => {
          return !this.listenedByCallsList.find(
            wasListenedByCall => call.id === wasListenedByCall.id
          );
        })
        .forEach(newListenedByCall => {
          const supervisorCallPoint = newListenedByCall.destination as UserCallPoint;
          const userSide = getUserSide(this.call, loggedInUser);
          if (
            supervisorCallPoint.type !== CallPointType.user ||
            userSide === null
          ) {
            return;
          }
          const otherCallPoint = this.call.getEndpoint(OtherSide(userSide));
          const listenInNotificationId = `listen-in-notification:${
            this.call.id
          }`;
          store.dispatch(
            notificationShow({
              uid: listenInNotificationId,
              message: `${getCallPointTitle(
                newListenedByCall.destination
              )} is listening on your call with ${getCallPointTitle(
                otherCallPoint,
                getCallPointNumber(otherCallPoint) || undefined
              )}`,
              level: "info",
              dismissable: true
            })
          );
          const enterSound = new Audio(listenInEnterSound);
          enterSound.loop = false;
          enterSound.play();
        });
      // NOTE: ended listened by calls
      this.listenedByCallsList
        .filter(wasListenedByCall => {
          return !calls.find(call => wasListenedByCall.id === call.id);
        })
        .forEach(endedListenedByCall => {
          const supervisorCallPoint = endedListenedByCall.destination as UserCallPoint;
          const userSide = getUserSide(this.call, loggedInUser);
          if (
            supervisorCallPoint.type !== CallPointType.user ||
            userSide === null
          ) {
            return;
          }
          const otherCallPoint = this.call.getEndpoint(OtherSide(userSide));
          const listenInNotificationId = `listen-in-notification:${
            this.call.id
          }`;
          store.dispatch(
            notificationShow({
              uid: listenInNotificationId,
              message: `${getCallPointTitle(
                endedListenedByCall.destination
              )} has just ended listening in on your call with ${getCallPointTitle(
                otherCallPoint,
                getCallPointNumber(otherCallPoint) || undefined
              )}`,
              level: "info",
              autoDismiss: 5000,
              dismissable: true
            })
          );
          const leaveSound = new Audio(listenInLeaveSound);
          leaveSound.loop = false;
          leaveSound.play();
        });
    }
    this.listenedByCallsList = calls;
  }

  resetAttendedTransfer() {
    this.attendedTransferMainLegId = undefined;
    this.attendedTransferConsultationLegId = undefined;
  }

  track() {
    const user = store.getState().auth.user;
    if (!user) {
      return;
    }
    const userSide = getUserSide(this.call, user);
    if (userSide === null || !shouldShowCallForUser(this.call, user)) {
      return;
    }
    if (userSide === Side.destination) {
      trackEvent(TrackCategory.calls, TrackAction.callsIncoming);
    } else {
      trackEvent(TrackCategory.calls, TrackAction.callsOutgoing);
    }
    this.tracked = true;
  }

  set lastConnectedTitle(val: string) {
    this.callLastConnectedTitle = val;
  }

  get lastConnectedTitle() {
    if (this.listeningToCall) {
      const listenInToCallTitle = getCallPointTitle(
        this.listeningToCall.source
      );
      if (listenInToCallTitle) {
        return listenInToCallTitle;
      }
    }
    return this.callLastConnectedTitle || "Unknown";
  }
}
