import * as React from "react";

interface IDelayedProps {
  isVisible: boolean;
  delayTime: number;
  onUnmount?: () => void;
  onMount?: () => void;
  children?: React.ReactNode;
}

/**
 * Wrapper component to delay unmounting of components
 */
class Delayed extends React.Component<IDelayedProps> {
  state = {
    shouldRender: this.props.isVisible,
    willUnmount: false,
  };

  componentDidUpdate(prevProps: IDelayedProps) {
    if (prevProps.isVisible && !this.props.isVisible) {
      this.setState({ willUnmount: true });
      setTimeout(() => {
        this.setState({ shouldRender: false, willUnmount: false });
        if (this.props.onUnmount) {
          this.props.onUnmount();
        }
      }, this.props.delayTime);
    } else if (!prevProps.isVisible && this.props.isVisible) {
      if (this.props.onMount) {
        this.props.onMount();
      }
      this.setState({ shouldRender: true, willUnmount: false });
    }
  }

  /**
   * Add css class to child element to identify that it will hide soon
   */
  modifyChildrenClass = (child: React.ReactElement<any>) => {
    const cssClasses: string[] = [];
    if (child.props.className) {
      cssClasses.push(child.props.className);
    }
    if (this.state.willUnmount) {
      cssClasses.push("delayed--will-hide");
    }
    const props: any = {
      ...child.props,
      className: cssClasses.join(" "),
    };
    return React.cloneElement(child, props);
  };

  render() {
    if (!this.state.shouldRender) {
      return null;
    }

    return (
      <>
        {React.Children.map(this.props.children, (child) =>
          React.isValidElement(child) ? this.modifyChildrenClass(child) : child
        )}
      </>
    );
  }
}

export default Delayed;
