import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { ReceiveCalls } from "compass.js";
import { notificationShow, notificationDismiss } from "src/store/actions";
import { setUserStatus } from "src/store/actions/auth";
import { useEffect } from "react";
import { INotification } from "src/store/reducers/notifications";
import { usePrevious } from "src/utils/hooks";
import { createSelector } from "reselect";

const awayNotificationId = "away-status-active";
const appDataSelector = createSelector(
  [
    (state: IRootState) => state.notifications.items,
    (state: IRootState) => state.auth.user,
  ],
  (notificationsItems, user) => {
    const awayNotificationVisible = !!notificationsItems.find(
      (item) => item.uid === awayNotificationId
    );
    const loggedIn = !!user;
    const receiveCalls = user?.status.receiveCalls;

    return {
      awayNotificationVisible,
      loggedIn,
      receiveCalls,
    };
  }
);

const AwayStatusNotification: React.FC = () => {
  const { awayNotificationVisible, loggedIn, receiveCalls } =
    useSelector(appDataSelector);

  const dispatch = useDispatch();
  const loggedInPrev = usePrevious(loggedIn);
  useEffect(() => {
    const onSetUserStatus = (
      receiveCalls: ReceiveCalls,
      displayStatus?: string
    ) => dispatch<any>(setUserStatus(receiveCalls, displayStatus));
    const onNotificationShow = (
      notification:
        | INotification
        | Pick<
            INotification,
            "message" | "autoDismiss" | "dismissable" | "level"
          >
    ) => dispatch(notificationShow(notification));
    const onNotificationDismiss = (uid: string) =>
      dispatch(notificationDismiss(uid));
    if (!loggedInPrev && receiveCalls === ReceiveCalls.none) {
      onNotificationShow({
        uid: awayNotificationId,
        message: `Your status is currently set to Away. Do you want to switch it to Available?`,
        level: "info",
        button: {
          text: "Go Available",
          onClick: () => onSetUserStatus(ReceiveCalls.all),
        },
        dismissable: true,
      });
    } else if (receiveCalls !== ReceiveCalls.none && awayNotificationVisible) {
      onNotificationDismiss(awayNotificationId);
    }
  }, [awayNotificationVisible, dispatch, loggedInPrev, receiveCalls]);

  return null;
};

export default AwayStatusNotification;
