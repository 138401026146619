import * as React from "react";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import { getCallPointTitle } from "src/utils/call";
import { IRootState } from "src/store/reducers";
import { useSelector } from "react-redux";
import { sortBy, sortNumbersComparator } from "src/utils";
import QueueDetailsCall from "../QueueDetailsCall/QueueDetailsCall";

const QueueDetailsCallsList: React.FC<{
  queueId: string;
  className?: string;
}> = ({ queueId, className }) => {
  const queue = useSelector((state: IRootState) => state.queues.items[queueId]);

  const calls = queue
    ? sortBy(
        queue.getCalls(),
        (call) => call.source.timeCreated,
        sortNumbersComparator
      ).map((call) => ({
        id: call.id,
        title: getCallPointTitle(call.source),
        sourceCreated: call.source.timeCreated,
      }))
    : [];

  return (
    <List className={className}>
      {calls && calls.length ? (
        calls.map((call, idx) => (
          <QueueDetailsCall
            key={call.id}
            callId={call.id}
            queueId={queueId}
            number={idx + 1}
            title={call.title}
          />
        ))
      ) : (
        <ListEmptyMsg>No callers waiting in this queue.</ListEmptyMsg>
      )}
    </List>
  );
};

export default QueueDetailsCallsList;
