import * as React from "react";
import "./ListRowTitle.scss";
import { PropsWithChildren } from "react";

interface IListRowTitleProps extends PropsWithChildren {
  number?: number;
  className?: string;
}

export const ListRowTitle: React.FC<IListRowTitleProps> = (props) => {
  const cssClasses = ["list__row-title"];
  if (props.className) {
    cssClasses.push(props.className);
  }
  return (
    <div className={cssClasses.join(" ")}>
      {props.number !== undefined ? (
        <div className="list__row-title-number">{props.number}</div>
      ) : null}
      {props.children}
    </div>
  );
};
