import * as React from "react";
import { useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
} from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import {
  User,
  Call,
  CallPointState,
  OtherSide,
  ListenInCallPoint,
  CallPointType,
} from "compass.js";
import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { humanizeApproxDuration } from "src/utils/dateTime";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IContact } from "src/store/reducers/contacts";
import { handleError } from "src/utils/errorHandler";
import { getCallPointTitle, getUserSide } from "src/utils/call";
import { listenIn } from "src/store/actions/calls";
import { UserFeature, UserPermission } from "src/store/reducers/auth";
import { BridgeColor } from "src/utils/consts";

const appDataSelector = (
  state: IRootState,
  callId: Call["id"],
  contactId: IContact["id"]
) => {
  const callsIsLoading = state.calls.actionsInProgress > 0;
  const contact = state.contacts.compassItems[contactId];
  const contactUser = contact.user as User;
  const selfUser = state.auth.user as User;

  const callMetadata = state.calls.allCallsMetadata[callId];
  const canDoListenIn =
    state.auth.features.includes(UserFeature.listenin) &&
    state.auth.companyPermission === UserPermission.permWrite;
  return {
    callsIsLoading,
    contact,
    contactUser,
    selfUser,
    callMetadata,
    canDoListenIn,
  };
};

const ContactDetailsCallItem: React.FC<{
  callId: Call["id"];
  contactId: IContact["id"];
}> = ({ callId, contactId }) => {
  const { callsIsLoading, contactUser, selfUser, callMetadata, canDoListenIn } =
    useSelector((state: IRootState) =>
      appDataSelector(state, callId, contactId)
    );

  const dispatch = useDispatch();

  const call = contactUser
    .getCalls()
    .find((item) => item.id === callId) as Call;

  const contactUserSide = getUserSide(call, contactUser);
  const selfUserSide = getUserSide(call, selfUser);

  const callHasListenInCallpoint =
    call.source instanceof ListenInCallPoint ||
    call.destination instanceof ListenInCallPoint;

  let isCallActive = false;
  let otherCallPointState: CallPointState | undefined;
  let title = "Unknown";

  if (contactUserSide !== null) {
    const contactEndpoint = call.getEndpoint(contactUserSide);
    const otherEndpoint = call.getEndpoint(OtherSide(contactUserSide));
    title = getCallPointTitle(otherEndpoint, title);
    otherCallPointState = otherEndpoint.state;
    isCallActive =
      [CallPointState.answered, CallPointState.inactive].includes(
        otherCallPointState
      ) &&
      [CallPointState.answered, CallPointState.inactive].includes(
        contactEndpoint.state
      );
  }

  let listenInBtnTooltip: string | undefined;
  let canBeListenedTo: boolean = false;

  if (canDoListenIn) {
    const alreadyListenedTo: boolean = !!selfUser.getCalls().find((item) => {
      return (
        item.source.type === CallPointType.listenIn &&
        (item.source as ListenInCallPoint).listenedToCallId === call.id
      );
    });
    canBeListenedTo =
      !callsIsLoading &&
      selfUserSide === null &&
      !callHasListenInCallpoint &&
      isCallActive &&
      !alreadyListenedTo;
    if (!canBeListenedTo) {
      listenInBtnTooltip = "This call can’t be listened in on";
      if (alreadyListenedTo) {
        listenInBtnTooltip = `You are already listening to this call`;
      }
      if (selfUserSide !== null) {
        listenInBtnTooltip = `You are in this call`;
      } else if (callHasListenInCallpoint) {
        listenInBtnTooltip = "Listened call can’t be listened to";
      }
    }
  }

  let subTitle = "direct call";
  if (callHasListenInCallpoint) {
    subTitle = "listen in";
  } else if (callMetadata.queue) {
    subTitle = `via ${callMetadata.queue.name}`;
  }

  const onListenInClick = useCallback(() => {
    return dispatch<any>(listenIn(callId)).catch(handleError);
  }, [callId, dispatch]);

  return (
    <ListRow key={callId}>
      <ListRowTitle>
        {title}{" "}
        <span className="contact-detail__call-subtitle">{subTitle}</span>
      </ListRowTitle>
      <ListRowInfo>
        <ListRowInfoItem>
          {humanizeApproxDuration(+new Date() / 1000 - callMetadata.created)}
        </ListRowInfoItem>
        {canDoListenIn && (
          <ListRowInfoItem isButton={true}>
            <Button
              icononly={true}
              small={true}
              color={BridgeColor.gs300}
              disabled={!canBeListenedTo || callsIsLoading}
              tooltip={listenInBtnTooltip}
              onClick={onListenInClick}
            >
              <FontAwesomeIcon icon={faEar} />
            </Button>
          </ListRowInfoItem>
        )}
      </ListRowInfo>
    </ListRow>
  );
};

export default ContactDetailsCallItem;
