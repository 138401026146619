import { compassDebouncePipe } from "./../../utils/compassDataMiddleware";
import { handleError } from "./../../utils/errorHandler";
import { TrackAction, TrackCategory, trackEvent } from "./../../utils/track";
import { AnyAction } from "redux";
import { IRootState } from "src/store/reducers/index";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import * as actionTypes from "./actionTypes";
import { userStorage } from "src/utils/userStorage";
import { IContact } from "src/store/reducers/contacts";
import { compassUsersToContacts } from "src/utils/contact";
import { isElectron } from "src/utils";
import { createRuntimeError } from "src/utils/errorHandler";
import { compassDataMiddleware } from "src/utils/compassDataMiddleware";
import { syncOfficeContacts } from "src/utils/contacts.office";
import { Providers } from "@microsoft/mgt";
const PIN_STORAGE_KEY = "pinnedContacts";

export const clearAddressBookContacts = () => ({
  type: actionTypes.CLEAR_ADDRESS_BOOK_CONTACTS,
});
const trackContactsNumber = (n: string) => {
  trackEvent(TrackCategory.contacts, TrackAction.contactsNumber, undefined, n);
};

export const setupContacts = (
  dispatch: ThunkDispatch<IRootState, void, AnyAction>
) => {
  dispatch(loadPinnedContacts()).then(() => {
    let track = true;
    dispatch(addressBookSetLoaded(false));
    compassDataMiddleware.users$
      .pipe(compassDebouncePipe())
      .subscribe(async (users) => {
        const compassContacts = compassUsersToContacts(users);
        let addressBookContacts = {};
        dispatch(
          updateCompassContacts(
            compassContacts.items,
            compassContacts.numbersMap
          )
        );

        if (track) {
          trackContactsNumber(
            (
              Object.keys(compassContacts.items).length +
              Object.keys(addressBookContacts).length
            ).toString()
          );
          track = false;
        }
      });
  });
};

export const fetchMicrosoftContacts = () => {
  const provider = Providers.globalProvider;

  return async (dispatch: ThunkDispatch<IRootState, void, AnyAction>) => {
    try {
      if (provider) {
        let allContacts: any[] = [];
        let response = await provider.graph.client.api("/me/contacts").get();
        allContacts = allContacts.concat(response.value);
        // Loop through all data pages
        while (response["@odata.nextLink"]) {
          response = await provider.graph.client
            .api(response["@odata.nextLink"])
            .get();
          allContacts = allContacts.concat(response.value);
        }
        // Dispatch the contacts to the store
        //@ts-ignore
        dispatch(syncAddressBookFromOffice(allContacts));
      }
    } catch (error) {
      handleError(error);
    }
  };
};
export const syncAddressBookContacts = (
  force: boolean = false
): ThunkAction<
  Promise<{ [key: string]: IContact }>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    if (!isElectron()) {
      return {};
    }
    dispatch(addressBookLoadingStart());
    let addressBookContacts: {
      [key: string]: IContact;
    } = {};
    try {
      //addressBookContacts = await loadAddressBookContacts(force);
      if (!getState().auth.onboardingMode) {
        const addressBookNumbersMap: { [key: string]: IContact["id"] } = {};
        Object.values(addressBookContacts).forEach((contact) => {
          contact.phones.forEach((phone) => {
            addressBookNumbersMap[phone.value.replace(/\D/g, "")] = contact.id;
          });
        });
        dispatch(
          updateAddressBookContacts(addressBookContacts, addressBookNumbersMap)
        );
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(addressBookLoadingEnd());
    }
    return addressBookContacts;
  };
};

export const syncAddressBookFromOffice = (
  officeContacts: { [key: string]: IContact },
  force: boolean = false
): ThunkAction<
  Promise<{ [key: string]: IContact }>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    dispatch(addressBookLoadingStart());
    let addressBookContacts: {
      [key: string]: IContact;
    } = {};
    try {
      //Refactor
      //@ts-ignore
      addressBookContacts = await syncOfficeContacts(officeContacts);
      if (!getState().auth.onboardingMode) {
        const addressBookNumbersMap: { [key: string]: IContact["id"] } = {};
        Object.values(addressBookContacts).forEach((contact) => {
          contact.phones.forEach((phone) => {
            addressBookNumbersMap[phone.value.replace(/\D/g, "")] = contact.id;
          });
        });
        dispatch(
          updateAddressBookContacts(addressBookContacts, addressBookNumbersMap)
        );
      }
    } catch (error) {
      throw new Error(error);
    } finally {
      dispatch(addressBookLoadingEnd());
    }
    return addressBookContacts;
  };
};

export const updateCompassContacts = (
  items: {
    [key: string]: IContact;
  },
  numbersMap: {
    [key: string]: IContact["id"];
  }
): {
  type: string;
  payload: {
    items: {
      [key: string]: IContact;
    };
    numbersMap: { [key: string]: IContact["id"] };
  };
} => {
  return {
    type: actionTypes.CONTACTS_COMPASS_UPDATE,
    payload: {
      items,
      numbersMap,
    },
  };
};

export const updateAddressBookContacts = (
  items: {
    [key: string]: IContact;
  },
  numbersMap: {
    [key: string]: IContact["id"];
  }
): {
  type: string;
  payload: {
    items: {
      [key: string]: IContact;
    };
    numbersMap: { [key: string]: IContact["id"] };
  };
} => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_UPDATE,
    payload: {
      items,
      numbersMap,
    },
  };
};

export const addressBookSetLoaded = (
  loaded: boolean
): { type: string; payload: boolean } => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_SET_LOADED,
    payload: loaded,
  };
};

export const addressBookLoadingStart = (): { type: string } => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_LOADING_START,
  };
};

export const addressBookLoadingEnd = (): { type: string } => {
  return {
    type: actionTypes.CONTACTS_ADDRESS_BOOK_LOADING_END,
  };
};

export const updatePinnedContacts = (
  contacts: string[]
): { type: string; payload: string[] } => {
  return {
    type: actionTypes.CONTACTS_COMPASS_UPDATE_PINNED,
    payload: contacts,
  };
};

export const loadPinnedContacts = (): ThunkAction<
  Promise<any>,
  IRootState,
  void,
  AnyAction
> => {
  return async (dispatch, getState, extraParams) => {
    try {
      const pinnedContacts = (await userStorage.getItem(
        PIN_STORAGE_KEY
      )) as string[];
      dispatch(updatePinnedContacts(pinnedContacts || []));
      return pinnedContacts;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const pinContact = (
  contactId: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const pinnedContacts = (getState().contacts.pinned || []).slice();
    pinnedContacts.push(contactId);
    try {
      await userStorage.setItem(PIN_STORAGE_KEY, pinnedContacts);
      dispatch(updatePinnedContacts(pinnedContacts));
      return pinnedContacts;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};

export const unpinContact = (
  contactId: string
): ThunkAction<Promise<any>, IRootState, void, AnyAction> => {
  return async (dispatch, getState, extraParams) => {
    const pinnedContacts = (getState().contacts.pinned || []).filter(
      (item) => item !== contactId
    );
    try {
      await userStorage.setItem(PIN_STORAGE_KEY, pinnedContacts);
      dispatch(updatePinnedContacts(pinnedContacts));
      return pinnedContacts;
    } catch (error) {
      throw createRuntimeError(error);
    }
  };
};
