import * as actionTypes from "src/store/actions/actionTypes";

export interface ITickIntervalState {
  readonly intervalId: any;
  readonly registeredFunctions: { [key: string]: () => void };
  readonly paused: boolean;
}

const initialState: ITickIntervalState = {
  intervalId: null,
  registeredFunctions: {},
  paused: false
};

const reducer = (
  state: ITickIntervalState = initialState,
  action: any
): ITickIntervalState => {
  switch (action.type) {
    case actionTypes.TICK_INTERVAL_INIT:
      return {
        ...state,
        intervalId: action.payload.id
      };
    case actionTypes.TICK_INTERVAL_DESTROY:
      return {
        ...state,
        intervalId: null
      };
    case actionTypes.TICK_INTERVAL_REGISTER:
      return {
        ...state,
        registeredFunctions: {
          ...state.registeredFunctions,
          ...{ [action.payload.id]: action.payload.func }
        }
      };
    case actionTypes.TICK_INTERVAL_UNREGISTER: {
      const stateCopy = {
        ...state
      };
      delete stateCopy.registeredFunctions[action.payload.id];
      return stateCopy;
    }
    case actionTypes.TICK_INTERVAL_PAUSE:
      return {
        ...state,
        paused: true
      };
    case actionTypes.TICK_INTERVAL_UNPAUSE:
      return {
        ...state,
        paused: false
      };
    default:
      return state;
  }
};

export default reducer;
