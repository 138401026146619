import * as React from "react";
import Delayed from "src/hoc/Delayed/Delayed";
import "./Animated.scss";

export enum AnimationType {
  fadeUpDown = "fade-up-down",
  fadeInOut = "fade-in-out",
}

interface IAnimationProps {
  isVisible: boolean;
  animation: AnimationType;
  className?: string;
  onShow?: () => void;
  onHide?: () => void;
  children?: React.ReactNode;
}

const DELAY_TIME = 300;

export class Animated extends React.Component<IAnimationProps> {
  render() {
    const cssClasses = ["animated", `animated--${this.props.animation}`];
    if (this.props.className) {
      cssClasses.push(this.props.className);
    }
    return (
      <Delayed
        isVisible={this.props.isVisible}
        delayTime={DELAY_TIME}
        onUnmount={this.props.onHide}
        onMount={this.props.onShow}
      >
        <div className={cssClasses.join(" ")}>{this.props.children}</div>
      </Delayed>
    );
  }
}
