import * as actionTypes from "src/store/actions/actionTypes";

export interface INotification {
  message: string;
  level: "success" | "info" | "danger";
  uid: string;
  dismissable?: boolean;
  autoDismiss?: number;
  button?: {
    text: string;
    onClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
  };
}

export interface INotificationsState {
  readonly items: INotification[];
}

const initialState: INotificationsState = {
  items: []
};

const reducer = (
  state: INotificationsState = initialState,
  action: any
): INotificationsState => {
  switch (action.type) {
    case actionTypes.NOTIFICATIONS_SHOW: {
      // NOTE: to replace notification with the same ID
      const currentNotifications = state.items.filter(
        item => item.uid !== action.payload.uid
      );
      return {
        ...state,
        items: [...currentNotifications, action.payload]
      };
    }
    case actionTypes.NOTIFICATIONS_DISMISS_ALL:
      return {
        ...state,
        items: []
      };
    case actionTypes.NOTIFICATIONS_DISMISS:
      return {
        ...state,
        items: state.items.filter(item => {
          return item.uid !== action.payload;
        })
      };
    default:
      return state;
  }
};

export default reducer;
