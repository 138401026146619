import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ThunkDispatch } from "redux-thunk";
import Modal from "src/components/UI/Modal/Modal";
import OnboardingQueuesImg from "src/assets/imgs/onboarding-queues.png";
import OnboardingContactsImg from "src/assets/imgs/onboarding-contacts.png";
import Button from "src/components/UI/Button/Button";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./OnboardingModal.scss";
import { userStorage } from "src/utils/userStorage";
import { NavigationHomeList } from "src/store/reducers/navigation";
import { homeChangeList } from "src/store/actions/navigation";
import { updateUserPreferences } from "src/store/preferences";
import { BridgeColor } from "src/utils/consts";
import { beginOnboarding, closeOnboardingModal } from "src/store/actions/auth";
import { ONBOARDING_MODAL_SHOWN_KEY } from "src/utils/consts";
import { OnboardingType } from "src/store/reducers/auth";
import { IRootState } from "src/store/reducers";
import { AnyAction } from "@reduxjs/toolkit";
import { createSelector } from "reselect";

const flowSelectOptions = [
  {
    image: OnboardingContactsImg,
    title: "Manage contacts",
    value: NavigationHomeList.contacts,
  },
  {
    image: OnboardingQueuesImg,
    title: "Manage queues",
    value: NavigationHomeList.queues,
  },
];

const selectAuth = (state: IRootState) => state.auth;

const appDataSelector = createSelector([selectAuth], (auth) => ({
  showOnboardingModal: auth.showOnboardingModal,
}));

const OnboardingModal: React.FC = () => {
  const { showOnboardingModal } = useSelector(appDataSelector);
  const dispatch: ThunkDispatch<IRootState, void, AnyAction> = useDispatch();
  const [selectedFlow, setSelectedFlow] = useState<
    NavigationHomeList | undefined
  >(undefined);

  const selectFlow = useCallback((flow: NavigationHomeList) => {
    setSelectedFlow(flow);
  }, []);

  const onSubmit = useCallback(() => {
    if (!selectedFlow) {
      return;
    }
    dispatch(homeChangeList(selectedFlow));
    dispatch(updateUserPreferences({ defaultHomeList: selectedFlow }));
    dispatch(closeOnboardingModal());
    dispatch(beginOnboarding(OnboardingType.default));
    userStorage.setItem(ONBOARDING_MODAL_SHOWN_KEY, true);
  }, [dispatch, selectedFlow]);

  const getFlowSelect = () => (
    <div className="onboarding-modal__flow-select">
      {flowSelectOptions.map((item, idx) => {
        const cssClasses = ["onboarding-modal__flow-select-item"];
        const isSelected = item.value === selectedFlow;
        if (isSelected) {
          cssClasses.push("onboarding-modal__flow-select-item--active");
        }
        return (
          <div
            key={idx}
            className={cssClasses.join(" ")}
            onClick={() => selectFlow(item.value)}
          >
            <div className="onboarding-modal__flow-select-item-img">
              <img src={item.image} alt="" />
              <div className="onboarding-modal__flow-select-item-img-icon">
                <FontAwesomeIcon icon={faCheck} />
              </div>
            </div>
            <div className="onboarding-modal__flow-select-item-title">
              {item.title}
            </div>
          </div>
        );
      })}
    </div>
  );

  const getSubmitBtn = () => {
    let btnText = "Start";
    if (selectedFlow) {
      switch (selectedFlow) {
        case NavigationHomeList.queues:
          btnText = "Start with queues";
          break;
        case NavigationHomeList.contacts:
          btnText = "Start with contacts";
          break;
      }
    }
    return (
      <div className="onboarding-modal__submit-button">
        <Button
          color={selectedFlow ? BridgeColor.prim500 : BridgeColor.gs200}
          disabled={!selectedFlow}
          onClick={selectedFlow ? onSubmit : undefined}
        >
          {btnText}
        </Button>
      </div>
    );
  };

  return (
    <Modal isOpen={showOnboardingModal} className={"modal--onboarding"}>
      <div className="onboarding-modal">
        <div className="onboarding-modal__title">
          Welcome to Compass Bridge!
        </div>
        <div className="onboarding-modal__description">
          With Compass Bridge, you can manage calls and call flows while having
          a full overview of your contacts and queues.
        </div>
        <div className="onboarding-modal__subtitle">
          What do you do more often?
        </div>
        {getFlowSelect()}
        {getSubmitBtn()}
        <div className="onboarding-modal__hint">
          Don't worry, you can always change this in settings later.
        </div>
      </div>
    </Modal>
  );
};

export default OnboardingModal;
