import { useEffect, useRef, useState } from "react";

export type InfiniteComponentProps = {
  elementId: string;
  disableSkeleton?: boolean;
};

export const useInfiniteComponentItem = ({
  elementId,
  disableSkeleton,
}: InfiniteComponentProps) => {
  const [visible, setVisible] = useState(false);
  const $listRowContent = useRef<Element | null>(null);
  const intersectionObserver = useRef<IntersectionObserver | null>(null);
  const visibleDebounceTimeout = useRef<NodeJS.Timer | null>(null);

  useEffect(() => {
    if (visible) {
      return;
    }
    if (!("IntersectionObserver" in window) || disableSkeleton) {
      setVisible(true);
      return;
    }
    const destroyIntersectionObserver = () => {
      if (visibleDebounceTimeout.current) {
        clearTimeout(visibleDebounceTimeout.current);
        visibleDebounceTimeout.current = null;
      }
      if ($listRowContent.current) {
        intersectionObserver.current?.unobserve($listRowContent.current);
        intersectionObserver.current = null;
      }
    };

    $listRowContent.current = document.querySelector(`#${elementId}`);
    intersectionObserver.current = new IntersectionObserver(
      (entries) => {
        const isIntersecting = entries[entries.length - 1].isIntersecting;
        if (visibleDebounceTimeout.current) {
          clearTimeout(visibleDebounceTimeout.current);
          visibleDebounceTimeout.current = null;
        }
        visibleDebounceTimeout.current = setTimeout(() => {
          if (isIntersecting) {
            setVisible(true);
          }
        }, 50);
      },
      { threshold: [0] }
    );
    if ($listRowContent.current) {
      intersectionObserver.current.observe($listRowContent.current);
    }
    return () => {
      destroyIntersectionObserver();
    };
  }, [disableSkeleton, elementId, visible]);

  return { visible };
};
