import * as React from "react";
import "./ListEmptyMsg.scss";

interface IProps {
  children?: React.ReactNode;
}

export const ListEmptyMsg: React.FC<IProps> = (props) => {
  return <div className="list__empty-msg">{props.children}</div>;
};
