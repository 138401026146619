import * as React from "react";
import { useState, useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { Connection } from "compass.js";
import { humanizeApproxDuration, humanizeDuration } from "src/utils/dateTime";
import { getUserCallDuration } from "src/utils/call";

export enum CallDetailCounterType {
  userDuration = "userDuration",
  sourceDuration = "sourceDuration",
}
const CallDetailCounter: React.FC<{
  callId: string;
  userId?: string;
  type: CallDetailCounterType;
  precise?: boolean;
}> = ({ callId, userId, type, precise }) => {
  const connection = useSelector(
    (state: IRootState) => state.auth.connection as Connection
  );

  const call = connection.model.calls[callId];
  const user = userId ? connection.model.users[userId] : undefined;

  const getValue = useCallback((): string | undefined => {
    if (!call) {
      return undefined;
    }
    const humanizeDurationFunc = precise
      ? humanizeDuration
      : humanizeApproxDuration;
    switch (type) {
      case CallDetailCounterType.userDuration:
        if (!user) {
          return undefined;
        }
        return humanizeDurationFunc(getUserCallDuration(call, user));
      case CallDetailCounterType.sourceDuration:
        return humanizeDurationFunc(call.source.getDuration());
      default:
        return undefined;
    }
  }, [call, precise, type, user]);

  const [value, setValue] = useState<string | undefined>(getValue());

  // Update the value every second
  useEffect(() => {
    const tickIntervalId = setInterval(() => {
      const newValue = getValue();
      if (newValue !== value) {
        setValue(newValue);
      }
    }, 1000);

    return () => {
      clearInterval(tickIntervalId);
    };
  }, [getValue, value]);

  return <>{value || null}</>;
};

export default CallDetailCounter;
