import * as React from "react";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import "./QueueDetails.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { IRootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { loginQueue, logoutQueue } from "src/store/actions";
import { pinQueue, unpinQueue } from "src/store/actions/queues";
import { User } from "compass.js";
import Segment, { ISegmentProps } from "src/components/UI/Segment/Segment";
import { handleError } from "src/utils/errorHandler";
import { UserPermission } from "src/store/reducers/auth";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { WindowSizeType } from "src/store/reducers/window";
import { TrackCategory, TrackAction, trackEvent } from "src/utils/track";
import { getQueueActiveMembers } from "src/utils/queue";
import QueueDetailCounter, {
  QueueDetailCounterType,
} from "../QueueDetailCounter/QueueDetailCounter";
import QueueDetailsCallsList from "./QueueDetailsCallsList/QueueDetailsCallsList";
import QueueDetailsAgentsList from "./QueueDetailsAgentsList/QueueDetailsAgentsList";
import QueueDetailsManageAgents from "./QueueDetailsManageAgents/QueueDetailsManageAgents";
import Button from "../UI/Button/Button";
import SearchInput from "src/components/UI/SearchInput/SearchInput";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH";
import { BridgeColor } from "src/utils/consts";
import { createSelector } from "reselect";

const TRACK_CATEGORY = TrackCategory.queueDetails;

enum OpenedTab {
  agents = "agents",
  callers = "callers",
}
const getQueue = (state: IRootState, id: string) => state.queues.items[id];
const getAuthUser = (state: IRootState) => state.auth.user as User;
const getWindowSizeType = (state: IRootState) => state.window.sizeType;
const getPinnedQueues = (state: IRootState) => state.queues.pinned;
const getCompanyPermission = (state: IRootState) =>
  state.auth.companyPermission;
const getActionsInProgress = (state: IRootState) =>
  state.queues.actionsInProgress;
const getOnlineStatus = (state: IRootState) => state.window.online;
const getOnboardingMode = (state: IRootState) => state.auth.onboardingMode;

const appDataSelector = createSelector(
  [
    getQueue,
    getAuthUser,
    getWindowSizeType,
    getPinnedQueues,
    getCompanyPermission,
    getActionsInProgress,
    getOnlineStatus,
    getOnboardingMode,
  ],
  (
    queue,
    authUser,
    windowSizeType,
    pinnedQueues,
    companyPermission,
    actionsInProgress,
    online,
    onboardingMode
  ) => {
    const isPinned = pinnedQueues.includes(queue.id);
    const hasWritePermissions = companyPermission === UserPermission.permWrite;
    const callsNumber = queue ? queue.getCalls().length : 0;
    const queuesIsLoading = actionsInProgress > 0;
    const isLoggedInUserInQueue = queue
      ? queue.isUserInQueue(authUser.id)
      : false;

    return {
      queue,
      onboardingMode,
      queuesIsLoading,
      callsNumber,
      online,
      windowSizeType,
      isPinned,
      hasWritePermissions,
      isLoggedInUserInQueue,
    };
  }
);

const QueueDetails: React.FC<{
  id: string;
  hideHeader?: boolean;
  isInline?: boolean;
}> = ({ id, hideHeader, isInline }) => {
  const {
    queue,
    onboardingMode,
    queuesIsLoading,
    callsNumber,
    online,
    windowSizeType,
    isPinned,
    hasWritePermissions,
  } = useSelector((state: IRootState) => appDataSelector(state, id));

  const dispatch = useDispatch();
  const [openedTab, setOpenedTab] = React.useState<OpenedTab>(OpenedTab.agents);
  const [searchQuery, setSearchQuery] = React.useState<string>("");
  const [manageMode, setManageMode] = React.useState<boolean>(false);
  const searchInputRef = React.useRef<SearchInput | null>(null);

  const userAsUser = useSelector(
    (state: IRootState) => (state.auth.user as User).id
  );

  const isQueueAvailable = !!queue;
  const name = queue ? queue.name : "";

  const isLoggedInUserInQueue = queue ? queue.isUserInQueue(userAsUser) : false;

  const nonPausedMemebersNumber = queue
    ? getQueueActiveMembers(queue).filter((item) => !item.pausedSince).length
    : 0;

  const onLoginQueue = (queueId: string) => dispatch<any>(loginQueue(queueId));
  const onLogoutQueue = (queueId: string) =>
    dispatch<any>(logoutQueue(queueId));
  const onPinQueue = (queueId: string) => dispatch<any>(pinQueue(queueId));
  const onUnpinQueue = (queueId: string) => dispatch<any>(unpinQueue(queueId));
  const toggleSearchInput = () => {
    if (
      !isInline &&
      searchInputRef.current &&
      !searchInputRef.current.state.opened
    ) {
      searchInputRef.current.toggleInput();
    }
  };

  const enableManageMode = () => setManageMode(true);
  const disableManageMode = () => setManageMode(false);

  const onSearchInputChanged = (value: string) => setSearchQuery(value);

  const openTab = (openedTab: OpenedTab) => {
    if (onboardingMode) {
      return;
    }
    switch (openedTab) {
      case OpenedTab.agents:
        trackEvent(TRACK_CATEGORY, TrackAction.queueDetailsShowAgents);
        break;
      case OpenedTab.callers:
        trackEvent(TRACK_CATEGORY, TrackAction.queueDetailsShowCallers);
        break;
    }
    setOpenedTab(openedTab);
    setManageMode(false);
  };

  const getStats = () => (
    <div className="queue-details__stats">
      <div className="queue-details__stats-item">
        <div className="queue-details__stats-label">Average waiting</div>
        <div className="queue-details__stats-value">
          <QueueDetailCounter
            id={id}
            type={QueueDetailCounterType.avgWaiting}
          />
        </div>
      </div>
      <div className="queue-details__stats-item">
        <div className="queue-details__stats-label">Longest waiting</div>
        <div className="queue-details__stats-value">
          <QueueDetailCounter
            id={id}
            type={QueueDetailCounterType.longestWaiting}
          />
        </div>
      </div>
      <div className="queue-details__stats-item">
        <div className="queue-details__stats-label">Agents</div>
        <div className="queue-details__stats-value">
          <QueueDetailCounter id={id} type={QueueDetailCounterType.agents} />
        </div>
      </div>
      <div className="queue-details__stats-item">
        <div className="queue-details__stats-label">Callers</div>
        <div className="queue-details__stats-value">
          <QueueDetailCounter id={id} type={QueueDetailCounterType.callers} />
        </div>
      </div>
    </div>
  );

  const getTabTemplate = () => {
    switch (openedTab) {
      case OpenedTab.agents:
        return manageMode ? (
          <QueueDetailsManageAgents
            queueId={id}
            query={searchQuery}
            className="queue-details__manage-agents"
          />
        ) : (
          <QueueDetailsAgentsList
            queueId={id}
            className="queue-details__tab-list"
            onShowManageAgents={isInline ? enableManageMode : toggleSearchInput}
          />
        );
      case OpenedTab.callers:
        return (
          <QueueDetailsCallsList
            queueId={id}
            className="queue-details__tab-list"
          />
        );
    }
  };

  const login = () => onLoginQueue(id);
  const logout = () => onLogoutQueue(id);
  const togglePin = () => {
    trackEvent(
      TRACK_CATEGORY,
      isPinned ? TrackAction.queueDetailsUnpin : TrackAction.queueDetailsPin
    );
    (isPinned ? onUnpinQueue(id) : onPinQueue(id)).catch(handleError);
  };

  const $getManageQueueBtn = () => (
    <>
      {
        // @ts-ignore
        <ContextMenuTrigger
          id={`queue-manage-${id}`}
          holdToDisplay={1}
          disable={onboardingMode}
        >
          <Button
            small={true}
            icononly={true}
            fill={"clear"}
            color={BridgeColor.gs1000}
            disabled={queuesIsLoading || !online}
          >
            <FontAwesomeIcon icon={faEllipsisH} />
          </Button>
        </ContextMenuTrigger>
      }
      {
        // @ts-ignore
        <ContextMenu id={`queue-manage-${id}`} rtl={true}>
          {
            // @ts-ignore
            <MenuItem onClick={isLoggedInUserInQueue ? logout : login}>
              {`${isLoggedInUserInQueue ? "Leave" : "Join"} ${name}`}
            </MenuItem>
          }
          {
            // @ts-ignore
            <MenuItem onClick={togglePin}>
              {`${isPinned ? "Unpin" : "Pin"} ${name}`}
            </MenuItem>
          }
          {hasWritePermissions && isInline ? (
            // @ts-ignore
            <MenuItem onClick={enableManageMode}>Manage agents</MenuItem>
          ) : null}
        </ContextMenu>
      }
    </>
  );

  if (!isQueueAvailable) {
    return null;
  }

  const cssClasses = ["queue-details"];
  if (isInline) {
    cssClasses.push("queue-details--inline");
  }

  const segmentProps: ISegmentProps = {
    onChange: (value: OpenedTab) => {
      openTab(value);
    },
    value: openedTab,
    items: [
      {
        title: `Agents (${nonPausedMemebersNumber})`,
        value: OpenedTab.agents,
      },
      {
        title: `Callers (${callsNumber})`,
        value: OpenedTab.callers,
      },
    ],
  };

  return (
    <div className={cssClasses.join(" ")}>
      {!hideHeader && (
        <ContainerHeader
          title={name}
          enableBackBtn={true}
          mobileOnlyBackBtn={true}
          className={"container-header--no-border"}
          showTitleTooltip={true}
        >
          {openedTab === OpenedTab.agents && hasWritePermissions ? (
            <div className="container-header-left">
              <SearchInput
                ref={searchInputRef}
                changed={onSearchInputChanged}
                value={searchQuery}
                disableOutsideClick={true}
                closeOnClear={true}
                closedIcon={faSlidersH}
                tooltip={"Manage agents in queue"}
                onOpen={enableManageMode}
                onClose={disableManageMode}
              />
            </div>
          ) : null}
          <div className="container-header-buttons container-header-buttons--queue-details">
            {windowSizeType === WindowSizeType.desktop
              ? $getManageQueueBtn()
              : null}
          </div>
        </ContainerHeader>
      )}
      <div className="queue-details__segment-wrap">
        <Segment {...segmentProps} />
        {windowSizeType === WindowSizeType.mobile ? $getManageQueueBtn() : null}
      </div>
      <div className="queue-details__tab-template">
        {isInline && manageMode ? (
          <div className="queue-details__inline-search-input">
            <SearchInput
              changed={onSearchInputChanged}
              value={searchQuery}
              disableOutsideClick={true}
              closeOnClear={true}
              alwaysOpened={true}
              tooltip={"Manage agents in queue"}
              onOpen={enableManageMode}
              onClose={disableManageMode}
            />
          </div>
        ) : (
          getStats()
        )}
        {getTabTemplate()}
      </div>
    </div>
  );
};

export default QueueDetails;
