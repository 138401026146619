import { Queue } from "compass.js";
import * as actionTypes from "src/store/actions/actionTypes";
import { IRootState } from ".";

export interface IQueueTargetState {
  paused?: boolean;
  loggedIn?: boolean;
}

export interface IQueueState {
  readonly items: { [key: string]: Queue };
  readonly v2GlobalPause: boolean;
  readonly pinned: string[];
  readonly actionsInProgress: number;
  readonly queueTargetState: { [key: string]: IQueueTargetState };
}

const initialState: IQueueState = {
  items: {},
  v2GlobalPause: false,
  pinned: [],
  actionsInProgress: 0,
  queueTargetState: {}
};

const reducer = (
  state: IQueueState = initialState,
  action: any
): IQueueState => {
  switch (action.type) {
    case actionTypes.QUEUES_UPDATE:
      return {
        ...state,
        items: action.payload
      };
    case actionTypes.QUEUES_SET_GLOBAL_PAUSE:
      return {
        ...state,
        v2GlobalPause: action.payload
      };
    case actionTypes.QUEUES_UPDATE_PINNED:
      return {
        ...state,
        pinned: action.payload
      };
    case actionTypes.QUEUES_ACTION_STARTED:
      return {
        ...state,
        actionsInProgress: state.actionsInProgress + 1
      };
    case actionTypes.QUEUES_ACTION_COMPLETED:
      return {
        ...state,
        actionsInProgress: state.actionsInProgress - 1
      };
    case actionTypes.QUEUES_SET_QUEUE_TARGET_STATE:
      return {
        ...state,
        queueTargetState: { [action.payload.queueId]: action.payload.state }
      };
    case actionTypes.QUEUES_CLEAR_QUEUE_TARGET_STATE: {
      const queueTargetState = { ...state.queueTargetState };
      delete queueTargetState[action.payload.queueId];
      return {
        ...state,
        queueTargetState
      };
    }
    default:
      return state;
  }
};

export const selectAllQueues = ({ queues: { items } }: IRootState) => items;

export default reducer;
