import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhoneOffice } from "@fortawesome/pro-regular-svg-icons/faPhoneOffice";
import { faHandPointUp } from "@fortawesome/pro-regular-svg-icons/faHandPointUp";
import { faHourglassHalf } from "@fortawesome/pro-regular-svg-icons/faHourglassHalf";
import "./PremiumFeaturesModal.scss";

class PremiumFeaturesModal extends React.Component {
  public render() {
    return (
      <div className="premium-features-modal">
        <div className="premium-features-modal__description">
          You are using the free version of Compass Bridge. The premium version
          offers access to these extra features:
        </div>
        <div className="premium-features-modal__features">
          <div className="premium-features-modal__feature">
            <div className="premium-features-modal__feature-icon">
              <FontAwesomeIcon icon={faPhoneOffice} />
            </div>
            <div className="premium-features-modal__feature-content">
              <div className="premium-features-modal__feature-title">
                Call control
              </div>
              <div className="premium-features-modal__feature-subtitle">
                Pick up, hang up, put on hold or transfer calls directly from
                the application without having to touch the desk phone.
              </div>
            </div>
          </div>
          <div className="premium-features-modal__feature">
            <div className="premium-features-modal__feature-icon">
              <FontAwesomeIcon icon={faHandPointUp} />
            </div>
            <div className="premium-features-modal__feature-content">
              <div className="premium-features-modal__feature-title">
                Cherry-picking
              </div>
              <div className="premium-features-modal__feature-subtitle">
                Answer a specific call that is waiting in a queue.
              </div>
            </div>
          </div>
          <div className="premium-features-modal__feature">
            <div className="premium-features-modal__feature-icon">
              <FontAwesomeIcon icon={faHourglassHalf} />
            </div>
            <div className="premium-features-modal__feature-content">
              <div className="premium-features-modal__feature-title">
                Wrap-up time
              </div>
              <div className="premium-features-modal__feature-subtitle">
                Puts you automatically on pause in all queues for a short period
                after each queue call.
              </div>
            </div>
          </div>
        </div>
        <div className="premium-features-modal__description">
          To get the full access to the application, please contact your
          administrator or reseller in order to upgrade your account.
        </div>
      </div>
    );
  }
}

export default PremiumFeaturesModal;
