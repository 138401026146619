import * as actionTypes from "./actionTypes";
import { store } from "..";
import { NavbarDropDownId } from "../reducers/window";

export const windowUpdateInit = () => {
  let timeout: any;
  window.addEventListener("resize", () => {
    // NOTE: added interval behaviour to prevent
    // very frequent update function call when user
    // resize browser window
    if (timeout) {
      clearInterval(timeout);
    }
    timeout = setTimeout(() => {
      store.dispatch(windowSizeUpdate());
      timeout = undefined;
    }, 200);
  });
  window.addEventListener("online", () => {
    store.dispatch(windowOnlineUpdate());
  });
  window.addEventListener("offline", () => {
    store.dispatch(windowOnlineUpdate());
  });
};

export const windowSizeUpdate = (): { type: string } => {
  return {
    type: actionTypes.WINDOW_SIZE_UPDATE
  };
};

export const windowOnlineUpdate = (): { type: string } => {
  return {
    type: actionTypes.WINDOW_ONLINE_UPDATE
  };
};

export const windowShowNavbarDropdown = (
  navbarId: NavbarDropDownId
): { type: string; payload: NavbarDropDownId } => {
  return {
    type: actionTypes.WINDOW_SHOW_NAVBAR_DROPDOWN,
    payload: navbarId
  };
};

export const windowHideNavbarDropdown = (): { type: string } => {
  return {
    type: actionTypes.WINDOW_HIDE_NAVBAR_DROPDOWN
  };
};
