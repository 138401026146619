import * as React from "react";
import { faLongArrowLeft } from "@fortawesome/pro-regular-svg-icons/faLongArrowLeft";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "src/components/UI/Button/Button";
import "./ContainerHeader.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { TrackCategory, TrackAction } from "src/utils/track";
import { Tooltip } from "../Tooltip/Tooltip";
import { BridgeColor } from "src/utils/consts";

const ContainerHeader: React.FC<{
  title: string;
  titleIcon?: {
    icon: IconDefinition;
    color: string;
  };
  className?: string;
  enableBackBtn?: boolean;
  mobileOnlyBackBtn?: boolean;
  backBtnClicked?: () => void;
  backBtnIcon?: IconDefinition;
  backBtnTrack?: [TrackCategory, TrackAction];
  showTitleTooltip?: boolean;
  children?: React.ReactNode;
}> = ({
  title,
  titleIcon,
  className,
  enableBackBtn,
  mobileOnlyBackBtn,
  backBtnClicked,
  backBtnIcon,
  backBtnTrack,
  showTitleTooltip,
  children,
}) => {
  let backBtn: React.ReactNode = null;

  const goBack = () => {
    if (backBtnClicked) {
      backBtnClicked();
    }
  };

  if (enableBackBtn) {
    const classes = ["container-header__back-btn"];
    if (mobileOnlyBackBtn) {
      classes.push("container-header__back-btn--mobile-only");
    }
    backBtn = (
      <div className={classes.join(" ")}>
        <Button
          onClick={goBack}
          small={true}
          icononly={true}
          fill={"clear"}
          color={BridgeColor.gs800}
          track={backBtnTrack}
        >
          <FontAwesomeIcon icon={backBtnIcon ? backBtnIcon : faLongArrowLeft} />
        </Button>
      </div>
    );
  }
  const cssClasses = ["container-header"];
  if (className) {
    cssClasses.push(className);
  }

  const $title = <div className="container-header__title-label">{title}</div>;

  return (
    <div className={cssClasses.join(" ")}>
      <div className="container-header__title">
        {backBtn}
        {titleIcon ? (
          <div
            className={`container-header__title-icon br-color--${titleIcon.color}`}
          >
            <FontAwesomeIcon icon={titleIcon.icon} />
          </div>
        ) : null}
        <Tooltip enabled={showTitleTooltip} annotation={true} content={title}>
          {$title}
        </Tooltip>
      </div>
      {<div className="container-header__content">{children}</div>}
    </div>
  );
};

export default ContainerHeader;
