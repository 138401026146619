class UserStorage {
  private initialized: boolean = false;
  private jid: string | null;
  private readonly keyPrefix: string = "userStorage::";

  init(jid: string) {
    this.jid = jid;
    this.initialized = true;
  }

  reset() {
    this.jid = null;
    this.initialized = false;
  }

  clear(): Promise<any> {
    if (!this.initialized) {
      throw this.notInitializedError();
    }
    return new Promise<void>((resolve) => {
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        if (key && key.indexOf(`${this.keyPrefix}${this.jid}::`) === 0) {
          localStorage.removeItem(key);
        }
      }
      resolve();
    });
  }

  setItem(key: string, item: any): Promise<any> {
    if (!this.initialized) {
      throw this.notInitializedError();
    }
    return new Promise<void>((resolve) => {
      localStorage.setItem(this.getKey(key), JSON.stringify(item));
      resolve();
    });
  }

  getItem<T = any>(key: string): Promise<T | null> {
    if (!this.initialized) {
      throw this.notInitializedError();
    }
    return new Promise((resolve) => {
      const item = localStorage.getItem(this.getKey(key));
      try {
        if (item !== null) {
          return resolve(JSON.parse(item));
        }
      } catch (error) {
        console.error(`Storage "${key}" value is not valid json`);
      }
      return resolve(null);
    });
  }

  removeItem(key: string): Promise<void> {
    if (!this.initialized) {
      throw this.notInitializedError();
    }
    return new Promise((resolve) => {
      localStorage.removeItem(this.getKey(key));
      resolve();
    });
  }

  private getKey(key: string): string {
    return `${this.keyPrefix}${this.jid}::${key}`;
  }

  private notInitializedError(): Error {
    return new Error(
      "Storage not initialized. You should call `userStorage#init` function first."
    );
  }
}

export const userStorage = new UserStorage();
