import { DEFAULT_COMPASS_DATA_SAMPLE_TIME } from "./consts";
import { Call, Queue } from "compass.js";
import { User } from "compass.js";
import { Connection } from "compass.js";
import { ReplaySubject, Subscription } from "rxjs";
import clone from "clone";
import { throttleTime } from "rxjs/operators";
import { async } from "rxjs/internal/scheduler/async";

class CompassDataMiddleware {
  userCalls$: ReplaySubject<Call[]> = new ReplaySubject(1);

  user$: ReplaySubject<User> = new ReplaySubject(1);
  users$: ReplaySubject<{ [key: string]: User }> = new ReplaySubject(1);
  queues$: ReplaySubject<{ [key: string]: Queue }> = new ReplaySubject(1);
  calls$: ReplaySubject<{ [key: string]: Call }> = new ReplaySubject(1);

  private connection: Connection | null;
  private jid: string | null;
  private userCallsSubscription: Subscription | null;
  private usersSubscription: Subscription | null;
  private queuesSubscription: Subscription | null;
  private callsSubscription: Subscription | null;

  setSource(connection: Connection, jid: string) {
    this.connection = connection;
    this.jid = jid;
    this.setupObservables();
  }

  reset() {
    this.connection = null;
    this.jid = null;

    this.clearConnectionSubscriptions();

    this.userCalls$.next([]);
    this.userCalls$.complete();
    this.userCalls$ = new ReplaySubject(1);

    this.users$.next({});
    this.users$.complete();
    this.users$ = new ReplaySubject(1);

    this.queues$.next({});
    this.queues$.complete();
    this.queues$ = new ReplaySubject(1);

    this.calls$.next({});
    this.calls$.complete();
    this.calls$ = new ReplaySubject(1);

    this.user$.complete();
    this.user$ = new ReplaySubject(1);
  }

  private clearConnectionSubscriptions() {
    if (this.callsSubscription) {
      this.callsSubscription.unsubscribe();
      this.callsSubscription = null;
    }

    if (this.userCallsSubscription) {
      this.userCallsSubscription.unsubscribe();
      this.userCallsSubscription = null;
    }
    if (this.usersSubscription) {
      this.usersSubscription.unsubscribe();
      this.usersSubscription = null;
    }
    if (this.queuesSubscription) {
      this.queuesSubscription.unsubscribe();
      this.queuesSubscription = null;
    }
  }

  private setupObservables() {
    if (!this.connection || !this.user) {
      return;
    }

    this.clearConnectionSubscriptions();

    this.users$.next(this.cloneObject(this.connection.model.users));
    this.user$.next(
      this.cloneObject(this.connection.model.users[this.user.id])
    );
    this.usersSubscription = this.connection.model.usersObservable.subscribe(
      () => {
        if (!this.connection || !this.user) {
          return;
        }
        this.users$.next(this.cloneObject(this.connection.model.users));
        this.user$.next(
          this.cloneObject(this.connection.model.users[this.user.id])
        );
      }
    );

    this.queues$.next(this.cloneObject(this.connection.model.queues));
    this.queuesSubscription = this.connection.model.queuesObservable.subscribe(
      () => {
        if (!this.connection) {
          return;
        }
        this.queues$.next(this.cloneObject(this.connection.model.queues));
      }
    );

    this.userCalls$.next(this.cloneObject(this.user.getCalls()));
    this.userCallsSubscription =
      this.connection.model.callsObservable.subscribe(() => {
        if (!this.user) {
          return;
        }
        this.userCalls$.next(this.cloneObject(this.user.getCalls()));
      });

    this.calls$.next(this.cloneObject(this.connection.model.calls));
    this.callsSubscription = this.connection.model.callsObservable.subscribe(
      () =>
        this.connection &&
        this.calls$.next(this.cloneObject(this.connection.model.calls))
    );
  }

  private get user(): User | null {
    if (!this.connection || !this.jid) {
      // TODO: better error
      console.error("Compass data middleware not initialized");
      return null;
    }
    return this.connection.model.getUserForJid(this.jid);
  }

  private cloneObject(obj: any) {
    return clone(obj, undefined, 2);
  }
}

export const compassDataMiddleware = new CompassDataMiddleware();

export const compassDebouncePipe = <T>() =>
  throttleTime<T>(DEFAULT_COMPASS_DATA_SAMPLE_TIME, async, {
    leading: false,
    trailing: true,
  });
