import * as React from "react";
import "./TransferDestinationItemSkeleton.scss";

export const TransferDestinationItemSkeleton: React.FC<{
  id?: string;
}> = props => {
  return (
    <div className="transfer-destination-item-skeleton" id={props.id}>
      <div className="transfer-destination-item-skeleton__name" />
      <div className="transfer-destination-item-skeleton__buttons" />
    </div>
  );
};
