import * as React from "react";
import { useEffect, useState, useCallback, useRef } from "react";
import "./LoginPhoneModal.scss";
import { useDispatch, useSelector } from "react-redux";
import {
  closeLoginPhoneModal,
  loginUserOnPhone,
  logoutUserFromPhone,
} from "src/store/actions/auth";
import { IRootState } from "src/store/reducers";
import {
  stringContains,
  compassObjUrlToID,
  sortBy,
  sortIgnoreCaseComparator,
  usePrevious,
} from "src/utils";
import { wrapApiError, handleError } from "src/utils/errorHandler";
import Button from "../UI/Button/Button";
import SearchInput from "../UI/SearchInput/SearchInput";
import { Loader } from "../UI/Loader/Loader";
import Modal from "../UI/Modal/Modal";
import { BridgeColor } from "src/utils/consts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHistory } from "@fortawesome/pro-regular-svg-icons/faHistory";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import stable from "stable";
import { ICompassPhone } from "src/store/reducers/auth";
import { User } from "compass.js";
import { createSelector } from "reselect";

export enum LoginPhoneModalType {
  switch = "switch",
  login = "login",
}

enum LoginPhoneModalVisibleContent {
  start = "start",
  phoneSelect = "phoneSelect",
}

interface ILoginPhoneModalState {
  visibleContent: LoginPhoneModalVisibleContent | null;
  searchQuery: string;
  companyPhones: Array<{
    id: number;
    name: string;
    userId?: number;
    userName?: string;
  }>;
  selectedPhone?: {
    id: number;
    name: string;
    online: boolean;
    userId?: number;
    userName?: string;
  };
  isSubmitting: boolean;
  isLoading: boolean;
}
const selectAuth = (state: IRootState) => state.auth;
const selectContacts = (state: IRootState) => state.contacts;
const appDataSelector = createSelector(
  [selectAuth, selectContacts],
  (auth, contacts) => ({
    phone: auth.phone,
    connection: auth.connection,
    company: auth.company,
    userId: auth.user && auth.user.id,
    type: auth.loginPhoneModalType,
    showLoginPhoneModalState: auth.showLoginPhoneModal,
    compassContacts: contacts.compassItems,
    recentPhones: auth.recentPhones,
    onboardingMode: auth.onboardingMode,
  })
);

const LoginPhoneModal: React.FC = () => {
  const {
    phone,
    connection,
    company,
    userId,
    type,
    showLoginPhoneModalState,
    compassContacts,
    recentPhones,
    onboardingMode,
  } = useSelector(appDataSelector);

  const dispatch = useDispatch();

  const [visibleContent, setVisibleContent] =
    useState<LoginPhoneModalVisibleContent | null>(null);
  const [companyPhones, setCompanyPhones] = useState<
    ILoginPhoneModalState["companyPhones"]
  >([]);
  const hasMounted = useRef(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [selectedPhone, setSelectedPhone] =
    useState<ILoginPhoneModalState["selectedPhone"]>();

  const onCloseLoginPhoneModal = () => dispatch(closeLoginPhoneModal());

  const onLoginUserOnPhone = (userId: User["id"], phoneId: number) =>
    dispatch<any>(loginUserOnPhone(userId, phoneId));
  const onLogoutUserFromPhone = (userId: User["id"]) =>
    dispatch<any>(logoutUserFromPhone(userId));

  const closeModal = () => {
    onCloseLoginPhoneModal();
    setTimeout(() => {
      setSearchQuery("");
      setVisibleContent(null);
    }, 500);
  };

  const getContent = () => {
    if (isLoading) {
      return (
        <div className="login-phone-modal__loading">
          <Loader color="primary" />
        </div>
      );
    }
    if (!visibleContent) {
      return null;
    }
    if (!companyPhones.length) {
      return getNoPhonesContent();
    }
    switch (visibleContent) {
      case LoginPhoneModalVisibleContent.start:
        return getStartContent();
      case LoginPhoneModalVisibleContent.phoneSelect:
        return getPhoneListContent();
    }
  };

  const loadPhones = useCallback(async () => {
    if (!connection || !company || onboardingMode) {
      return;
    }
    setIsLoading(true);
    const rest = connection.rest;
    let companyPhones: Array<{
      id: number;
      name: string;
      userId?: number;
      userName?: string;
    }> = [];
    try {
      const phones = await wrapApiError<ICompassPhone[]>(
        rest.get(`${rest.getUrlForObject("company", company.entityId)}/phones`)
      );

      companyPhones = sortBy(
        phones.map((phone: ICompassPhone) => {
          const phoneId = compassObjUrlToID(phone.self);
          const loggedInUser = phone.loggedInUser
            ? compassContacts[compassObjUrlToID(phone.loggedInUser)]
            : undefined;
          if (!loggedInUser) {
            return {
              id: phoneId,
              name: phone.name,
            };
          }
          return {
            id: phoneId,
            name: phone.name,
            userName: loggedInUser.name,
            userId: parseInt(loggedInUser.id, 10),
          };
        }),
        (item) => item.name,
        sortIgnoreCaseComparator
      );
      companyPhones = stable(companyPhones, (a, b) => {
        if (recentPhones.includes(a.id) && !recentPhones.includes(b.id)) {
          return -1;
        } else if (
          !recentPhones.includes(a.id) &&
          recentPhones.includes(b.id)
        ) {
          return 1;
        }
        if (recentPhones.indexOf(a.id) < recentPhones.indexOf(b.id)) {
          return -1;
        } else if (recentPhones.indexOf(a.id) > recentPhones.indexOf(b.id)) {
          return 1;
        }
        return 0;
      });
      const loggedInPhoneIdx = companyPhones.findIndex(
        (item) => item.userId === Number(userId)
      );
      if (loggedInPhoneIdx > 0) {
        const loggedInPhone = companyPhones[loggedInPhoneIdx];
        companyPhones.splice(loggedInPhoneIdx, 1);
        companyPhones.unshift(loggedInPhone);
      }
    } catch (error) {
      handleError(error);
    } finally {
      setCompanyPhones(companyPhones);
      setIsLoading(false);
    }
  }, [
    company,
    compassContacts,
    connection,
    onboardingMode,
    recentPhones,
    userId,
  ]);

  useEffect(() => {
    if (!hasMounted.current) {
      loadPhones();
      hasMounted.current = true;
    }
  }, [loadPhones]);

  const showPhoneContent = () => {
    setVisibleContent(LoginPhoneModalVisibleContent.phoneSelect);
    setSearchQuery("");
  };

  const showStartContent = () => {
    setVisibleContent(LoginPhoneModalVisibleContent.start);
    setSearchQuery("");
  };

  const onSearchInputChange = (searchQuery: string) => {
    setSearchQuery(searchQuery);
  };

  const onLoginUserOnPhoneHandler = async () => {
    const selectedPhoneId = selectedPhone && selectedPhone.id;
    if (!userId || !selectedPhoneId) {
      return;
    }
    setIsSubmitting(true);

    try {
      await onLoginUserOnPhone(userId, selectedPhoneId);
      closeModal();
      loadPhones();
    } catch (error) {
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onLogoutOfPhone = async () => {
    const userPhone = phone;

    if (!userPhone || !userId) {
      return;
    }
    setIsSubmitting(true);

    try {
      const wasLoggedToPhoneId = userPhone.id;
      await onLogoutUserFromPhone(userId);
      const updatedCompanyPhones = companyPhones.slice();
      for (const companyPhone of updatedCompanyPhones) {
        if (companyPhone.id === wasLoggedToPhoneId) {
          companyPhone.userId = undefined;
          companyPhone.userName = undefined;
          break;
        }
      }
      setCompanyPhones(updatedCompanyPhones);
    } catch (error) {
      setIsSubmitting(false);
      handleError(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const filteredCompanyPhones = React.useMemo(() => {
    const onSelectPhone = (
      selectedPhone: ILoginPhoneModalState["selectedPhone"]
    ) => {
      if (isSubmitting) {
        return;
      }
      setSelectedPhone(selectedPhone);
    };
    return companyPhones
      .filter((item) => stringContains(item.name, searchQuery))
      .map((companyPhone) => {
        const cssClasses = ["login-phone-modal__select-phone-item"];
        if (selectedPhone && selectedPhone.id === companyPhone.id) {
          cssClasses.push("login-phone-modal__select-phone-item--selected");
        }
        return (
          <div
            key={companyPhone.id}
            className={cssClasses.join(" ")}
            //Refactor
            //@ts-ignore
            onClick={() => onSelectPhone(companyPhone)}
          >
            <div className="login-phone-modal__select-phone-item-name">
              {companyPhone.name}
              {(!phone || phone.id !== companyPhone.id) &&
              recentPhones.includes(companyPhone.id) ? (
                <Tooltip content="Your recent phone">
                  <span className="login-phone-modal__select-phone-item-recent-icon">
                    <FontAwesomeIcon icon={faHistory} />
                  </span>
                </Tooltip>
              ) : null}
            </div>
            {companyPhone.userName && companyPhone.userId ? (
              <div className="login-phone-modal__select-phone-item-user">
                {phone && phone.id === companyPhone.id
                  ? "Your current phone"
                  : companyPhone.userName}
              </div>
            ) : null}
          </div>
        );
      });
  }, [
    companyPhones,
    isSubmitting,
    phone,
    recentPhones,
    searchQuery,
    selectedPhone,
  ]);

  const getPhoneListContent = () => {
    return (
      <div className="login-phone-modal__select-phone">
        <SearchInput
          alwaysOpened={true}
          autoFocus={true}
          dark={true}
          expand="full"
          changed={onSearchInputChange}
        />
        <div className="login-phone-modal__select-phone-items">
          {filteredCompanyPhones}
        </div>
        <div className="login-phone-modal__select-phone-buttons">
          <div className="login-phone-modal__select-phone-button-wrap">
            <Button
              color={BridgeColor.prim500}
              disabled={
                !selectedPhone ||
                !!selectedPhone.userId ||
                !!(selectedPhone && phone && selectedPhone.id === phone.id) ||
                isSubmitting
              }
              onClick={onLoginUserOnPhoneHandler}
            >
              Select this phone
            </Button>
          </div>
          {phone ? (
            <div className="login-phone-modal__select-phone-button-wrap">
              <Button
                color={BridgeColor.gs800}
                disabled={isSubmitting}
                onClick={onLogoutOfPhone}
              >
                Log out of my phone
              </Button>
            </div>
          ) : null}
          <div className="login-phone-modal__select-phone-button-wrap">
            <Button
              color={BridgeColor.gs200}
              fill="clear"
              disabled={isSubmitting}
              onClick={
                type === LoginPhoneModalType.switch
                  ? closeModal
                  : showStartContent
              }
            >
              Cancel
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getNoPhonesContent = () => {
    return (
      <div className="login-phone-modal__user-phone">
        {getHeaderTxt()}
        <div className="login-phone-modal__user-phone-info">
          You currently don’t have any registered phones in Compass. Contact
          administrator or sign in to Compass and add a phone.
        </div>
        <div className="login-phone-modal__user-phone-buttons">
          <div className="login-phone-modal__user-phone-button-wrap">
            <Button color={BridgeColor.prim500} onClick={loadPhones}>
              Try again
            </Button>
          </div>
        </div>
      </div>
    );
  };

  const getStartContent = () => {
    return (
      <div className="login-phone-modal__user-phone">
        {phone ? null : getHeaderTxt()}
        <div className="login-phone-modal__user-phone-info">
          Please make sure that you have call waiting enabled for your phone to
          be able to make attended transfers. You can enable it in Phone
          settings in Compass.
        </div>
        <div className="login-phone-modal__user-phone-buttons">
          {phone ? (
            <>
              <div className="login-phone-modal__user-phone-button-wrap">
                <Button color={BridgeColor.prim500} onClick={closeModal}>
                  Continue with {phone.name}
                </Button>
              </div>
              <div className="login-phone-modal__user-phone-button-wrap">
                <Button
                  color={BridgeColor.gs200}
                  fill="clear"
                  onClick={showPhoneContent}
                >
                  Switch phone
                </Button>
              </div>
            </>
          ) : (
            <div className="login-phone-modal__user-phone-button-wrap">
              <Button color={BridgeColor.prim500} onClick={showPhoneContent}>
                Select a phone
              </Button>
            </div>
          )}
        </div>
      </div>
    );
  };

  const getHeaderTxt = () => {
    return <div>You need to log in to a phone to be able to use Bridge</div>;
  };

  const prevType = usePrevious(type);
  useEffect(() => {
    if (type && (!prevType || prevType !== type)) {
      setVisibleContent(
        type === LoginPhoneModalType.switch
          ? LoginPhoneModalVisibleContent.phoneSelect
          : LoginPhoneModalVisibleContent.start
      );
    }
  }, [prevType, type]);

  const prevPhone = usePrevious(phone);
  useEffect(() => {
    if (isLoading) {
      return;
    }
    const phoneId = phone ? phone.id : null;
    const prevPhoneId = prevPhone ? prevPhone.id : null;
    if (phoneId !== prevPhoneId) {
      loadPhones();
    }
  }, [phone, isLoading, prevPhone, loadPhones]);

  return (
    <Modal
      isOpen={showLoginPhoneModalState}
      title={"Log in to a phone"}
      showCloseBtn={true}
      onRequestClose={onCloseLoginPhoneModal}
    >
      {getContent()}
    </Modal>
  );
};

export default LoginPhoneModal;
