import * as React from "react";
import "./SettingsContactsForm.scss";
import { useDispatch } from "react-redux";
import { useIsSignedIn } from "@microsoft/mgt-react";
import { useEffect, useState } from "react";

import {
  clearAddressBookContacts,
  fetchMicrosoftContacts,
} from "src/store/actions/contacts";
import { handleError } from "src/utils/errorHandler";

import MsSignIn from "src/assets/imgs/ms-sign-in.svg";
import MsSignOut from "src/assets/imgs/ms-sign-out.svg";

import { Providers } from "@microsoft/mgt";
import Modal from "../UI/Modal/Modal";
import { BridgeColor } from "src/utils/consts";

const SettingsViewForm: React.FC = () => {
  const dispatch = useDispatch();
  const [isSignedIn] = useIsSignedIn();
  const [userEmail, setUserEmail] = useState<string>("");
  const [modalOpen, setModalOpen] = useState<boolean>(false);

  useEffect(() => {
    if (isSignedIn) {
      const provider = Providers.globalProvider;
      if (provider) {
        provider.graph.client
          .api("/me")
          .get()
          .then((response) => {
            setUserEmail(response.mail);
            dispatch<any>(fetchMicrosoftContacts());
          })
          .catch((error) => {
            handleError(error);
          });
      }
    }
  }, [dispatch, isSignedIn]);

  const handleMSLogin = async () => {
    try {
      if (Providers.globalProvider.login) {
        await Providers.globalProvider.login();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleMSLogout = async () => {
    setModalOpen(false);
    try {
      if (Providers.globalProvider.logout) {
        await Providers.globalProvider?.logout();
      }
      dispatch(clearAddressBookContacts());
    } catch (error) {
      handleError(error);
    }
  };
  const handleModalOpen = () => {
    setModalOpen(!modalOpen);
  };
  return (
    <div className="settings-view-form">
      <div className="br-form-group">
        <div className="br-form-label">Microsoft contacts</div>
        <div className="br-form-info">
          Sign in to your Microsoft account to authorize Microsoft contacts
          access.
        </div>
        {isSignedIn && userEmail && (
          <div className="br-form-info">Used account: {userEmail}</div>
        )}
        <div>
          {isSignedIn ? (
            <button className="button-ms" onClick={handleModalOpen}>
              <img src={MsSignOut} alt="msSignOut" />
            </button>
          ) : (
            <button className="button-ms" onClick={handleMSLogin}>
              <img src={MsSignIn} alt="msSignIn" />
            </button>
          )}
        </div>
        <Modal
          isOpen={modalOpen}
          onRequestClose={handleModalOpen}
          showCloseBtn={false}
          buttonsPlacement="left"
          title={"Sign out from Microsoft"}
          className="modal--msSignOut"
          buttons={[
            {
              text: "Sign out",
              color: BridgeColor.red700,
              clicked: handleMSLogout,
            },
            {
              text: "Cancel",
              color: BridgeColor.gs800,
              clicked: handleModalOpen,
            },
          ]}
        >
          <p>
            Your Microsoft contacts will be removed from
            <br /> Bridge after signing out of your Microsoft account.
          </p>
          <br />
          <p>Are you sure?</p>
        </Modal>
      </div>
    </div>
  );
};

export default SettingsViewForm;
