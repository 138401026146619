import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faClock } from "@fortawesome/pro-regular-svg-icons/faClock";
import Button from "src/components/UI/Button/Button";
import { IRootState } from "src/store/reducers";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import "./QueuesList.scss";
import { setAllQueuePausedState } from "src/store/actions";
import {
  homeChangeList,
  homeHideDetails,
  homeShowDetails,
} from "src/store/actions/navigation";
import { Queue, ReceiveCalls } from "compass.js";
import { sortByProperty, sortIgnoreCaseComparator } from "src/utils";
import stable from "stable";
import QueueItem from "src/components/QueueItem/QueueItem";
import FlipMove from "react-flip-move";
import QueueDetails from "src/components/QueueDetails/QueueDetails";
import { WindowSizeType } from "src/store/reducers/window";
import { ViewModeType } from "src/store/preferences";
import { handleError } from "src/utils/errorHandler";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { TrackCategory, TrackAction } from "src/utils/track";
import { wrapOnboarding } from "src/utils/onboarding";
import {
  NORMAL_LIST_ITEM_HEIGHT,
  COMPACT_LIST_ITEM_HEIGHT,
  INFINITE_LIST_BREAKPOINT,
  BridgeColor,
} from "src/utils/consts";
import Infinite from "react-infinite";
import {
  IHomePageParams,
  NavigationHomeList,
} from "src/store/reducers/navigation";
import { useForceUpdate } from "src/utils/hooks";
import { updateGlobalPause } from "src/store/actions/queues";
import { createSelector } from "reselect";

const TRACK_CATEGORY = TrackCategory.queueList;

const getQueues = (state: IRootState) => state.queues.items;
const getPinnedQueues = (state: IRootState) => state.queues.pinned;
const getUser = (state: IRootState) => state.auth.user;
const getApiVersion = (state: IRootState) => state.auth.apiVersion;
const getNavigationParams = (state: IRootState) =>
  state.navigation.params as IHomePageParams;
const getPreferences = (state: IRootState) => state.preferences.user;
const getWindowSizeType = (state: IRootState) => state.window.sizeType;
const getOnlineStatus = (state: IRootState) => state.window.online;
const getWindowBounds = (state: IRootState) => state.window.bounds;
const getV2GlobalPause = (state: IRootState) => state.queues.v2GlobalPause;
const getActionsInProgress = (state: IRootState) =>
  state.queues.actionsInProgress;
const getOnboardingMode = (state: IRootState) => state.auth.onboardingMode;

const appDataSelector = createSelector(
  [
    getQueues,
    getPinnedQueues,
    getUser,
    getApiVersion,
    getNavigationParams,
    getPreferences,
    getWindowSizeType,
    getOnlineStatus,
    getWindowBounds,
    getV2GlobalPause,
    getActionsInProgress,
    getOnboardingMode,
  ],
  (
    queues,
    pinnedQueues,
    user,
    apiVersion,
    navigationParams,
    preferences,
    windowSizeType,
    online,
    windowBounds,
    v2GlobalPause,
    actionsInProgress,
    onboardingMode
  ) => {
    let sortedQueues = sortByProperty<Queue, string>(
      Object.values(queues),
      "name",
      sortIgnoreCaseComparator
    );
    sortedQueues = stable(sortedQueues, (a: Queue, b: Queue) => {
      const aPinned = !!pinnedQueues.find((item) => item === a.id);
      const bPinned = !!pinnedQueues.find((item) => item === b.id);
      return aPinned === bPinned ? 0 : aPinned ? -1 : 1;
    });

    const useInfiniteList =
      Object.keys(queues).length > INFINITE_LIST_BREAKPOINT;
    const globalPause =
      apiVersion && apiVersion >= 3 && user
        ? user.status.receiveCalls === ReceiveCalls.onlyDirect
        : v2GlobalPause;

    return {
      queues: sortedQueues,
      globalPause,
      openedQueue:
        navigationParams.detailsOpened && navigationParams.detailsId
          ? navigationParams.detailsId.toString()
          : undefined,
      userId: user?.id ?? "",
      queuesIsLoading: actionsInProgress > 0,
      defaultHomeList: preferences.defaultHomeList,
      viewMode: preferences.viewMode,
      windowSizeType,
      online,
      onboardingMode,
      useInfiniteList,
      apiVersion,
      windowHeight: useInfiniteList ? windowBounds.height : undefined,
    };
  }
);

const QueuesList: React.FC = () => {
  const {
    queues,
    globalPause,
    openedQueue,
    userId,
    queuesIsLoading,
    defaultHomeList,
    viewMode,
    windowSizeType,
    online,
    onboardingMode,
    useInfiniteList,
    apiVersion,
  } = useSelector((state: IRootState) => appDataSelector(state));

  const dispatch = useDispatch();
  const [lastScrollPos, setLastScrollPos] = useState<number | null>(null);
  const listRef = useRef<HTMLDivElement | null>(null);
  const listWrapperRef = useRef<HTMLDivElement | null>(null);
  const activeItemWrapperRef = useRef<HTMLDivElement | null>(null);
  const forceUpdate = useForceUpdate();

  useEffect(() => {
    scrollDetailsUpForMobile();
  });

  const getItemHeight = useCallback(() => {
    return viewMode === ViewModeType.comfortable
      ? NORMAL_LIST_ITEM_HEIGHT
      : COMPACT_LIST_ITEM_HEIGHT;
  }, [viewMode]);
  const closeDetails = useCallback(() => {
    dispatch(homeHideDetails());
    setTimeout(() => {
      const $list = listRef.current as HTMLDivElement;
      $list.scrollTo($list.scrollLeft, $list.scrollTop - 1);
      $list.scrollTo($list.scrollLeft, $list.scrollTop + 1);
    }, 100);
  }, [dispatch]);
  const toggleDetails = useCallback(
    (queue: Queue) => {
      if (!!openedQueue) {
        closeDetails();
      } else {
        dispatch(homeShowDetails(queue.id));
      }
    },
    [closeDetails, dispatch, openedQueue]
  );
  const onSetAllQueuePausedState = (targetPausedState: boolean) =>
    dispatch<any>(setAllQueuePausedState(targetPausedState));

  const onUpdateGlobalPause = (val: boolean) =>
    dispatch(updateGlobalPause(val));

  const onQueueItemClick = useCallback(
    (queue: Queue) => {
      if (onboardingMode) {
        return;
      }
      windowSizeType === WindowSizeType.mobile
        ? toggleDetails(queue)
        : dispatch(homeShowDetails(queue.id));
    },
    [dispatch, onboardingMode, toggleDetails, windowSizeType]
  );

  const toggleGlobalPause = () => {
    if (apiVersion && apiVersion === 2) {
      const loggedInQueues = queues.filter((queue) => {
        return queue.isUserInQueue(userId);
      });
      if (!loggedInQueues.length) {
        return onUpdateGlobalPause(!globalPause);
      }
    }
    return onSetAllQueuePausedState(!globalPause).catch(handleError);
  };

  const openContacts = useCallback(() => {
    dispatch(homeChangeList(NavigationHomeList.contacts));
  }, [dispatch]);
  const scrollDetailsUpForMobile = useCallback(() => {
    if (
      lastScrollPos &&
      (windowSizeType !== WindowSizeType.mobile || !openedQueue)
    ) {
      setLastScrollPos(null);
    }

    if (
      !openedQueue ||
      windowSizeType !== WindowSizeType.mobile ||
      !listWrapperRef.current ||
      !listRef.current
    ) {
      return;
    }
    const activeItemIdx = queues.findIndex((item) => item.id === openedQueue);
    if (activeItemIdx < 0) {
      return;
    }
    const scrollPosition =
      activeItemIdx * getItemHeight() + 15; /* list padding */
    if (lastScrollPos === scrollPosition) {
      return;
    }
    if (activeItemWrapperRef.current) {
      activeItemWrapperRef.current.scrollIntoView({
        behavior:
          lastScrollPos && lastScrollPos !== scrollPosition ? "auto" : "smooth",
        block: "start",
      });
      setLastScrollPos(scrollPosition);
    } else if (useInfiniteList) {
      const $infiniteList = listWrapperRef.current.querySelector(
        ".queues-list-infinite"
      );
      if ($infiniteList) {
        $infiniteList.scrollTo(0, scrollPosition);
        setLastScrollPos(scrollPosition);
      }
    }
  }, [
    getItemHeight,
    lastScrollPos,
    openedQueue,
    queues,
    useInfiniteList,
    windowSizeType,
  ]);
  const getQueuesListItems = useCallback(
    (queues: Queue[]): React.ReactNode => {
      const $items = queues.map((queue, idx) => {
        const isItemActive = openedQueue === queue.id;
        const wrapperCssClasses = ["queues-list__item-wrapper"];
        if (isItemActive) {
          wrapperCssClasses.push("queues-list__item-wrapper--active");
        }
        const $queueItem = (
          <div key={queue.id} ref={isItemActive ? activeItemWrapperRef : null}>
            <QueueItem
              id={queue.id}
              onClick={() => onQueueItemClick(queue)}
              onDetailsToggle={() => toggleDetails(queue)}
              isActive={isItemActive}
              disableSkeleton={isItemActive}
            />
          </div>
        );
        return (
          <div key={queue.id} className={wrapperCssClasses.join(" ")}>
            {wrapOnboarding(
              $queueItem,
              OnboardingStepId.sectionQueues,
              { placement: "bottom", arrow: false },
              idx === queues.length - 1
            )}
            {isItemActive && windowSizeType === WindowSizeType.mobile ? (
              <div className="queues-list__details-wrap br-screen-small">
                <QueueDetails id={queue.id} hideHeader={true} isInline={true} />
              </div>
            ) : null}
          </div>
        );
      });
      if (!useInfiniteList) {
        return (
          <FlipMove
            enterAnimation="none"
            leaveAnimation="none"
            disableAllAnimations={!!openedQueue}
          >
            {$items}
          </FlipMove>
        );
      }
      if (!listRef.current) {
        return null;
      }
      return (
        <Infinite
          containerHeight={listRef.current.clientHeight}
          elementHeight={getItemHeight()}
          preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
          className="queues-list-infinite"
        >
          {$items}
        </Infinite>
      );
    },
    [
      getItemHeight,
      onQueueItemClick,
      openedQueue,
      toggleDetails,
      useInfiniteList,
      windowSizeType,
    ]
  );

  useEffect(() => {
    if (useInfiniteList) {
      forceUpdate();
    }
  }, [forceUpdate, useInfiniteList]);

  return (
    <>
      <ContainerHeader
        title={"Queues"}
        enableBackBtn={
          defaultHomeList !== NavigationHomeList.queues && !onboardingMode
        }
        backBtnClicked={openContacts}
        className={
          !!openedQueue ? "container-header--no-mobile-border" : undefined
        }
        backBtnTrack={[TRACK_CATEGORY, TrackAction.queueListGoBack]}
      >
        <div className="container-header-left">
          <Button
            small={true}
            icononly={true}
            onClick={toggleGlobalPause}
            disabled={queuesIsLoading || !online}
            color={globalPause ? BridgeColor.red500 : BridgeColor.gs300}
            tooltip={
              globalPause
                ? "Resume receiving calls in all queues"
                : "Pause yourself in all queues"
            }
            track={[
              TRACK_CATEGORY,
              globalPause
                ? TrackAction.queueListDisableGlobalPause
                : TrackAction.queueListEnableGlobalPause,
            ]}
          >
            <FontAwesomeIcon icon={faClock} />
          </Button>
        </div>
        <div className="container-header-buttons">
          <div className="container-header-buttons__queues-wrap">
            {defaultHomeList === NavigationHomeList.queues || onboardingMode ? (
              <Button
                small={true}
                icononly={true}
                onClick={openContacts}
                color={BridgeColor.gs300}
                tooltip={"Contacts"}
                track={[TRACK_CATEGORY, TrackAction.queueListOpenContacts]}
              >
                <FontAwesomeIcon icon={faUser} />
              </Button>
            ) : null}
          </div>
        </div>
      </ContainerHeader>
      <div
        className={`queues-list-wrap ${
          openedQueue ? "queues-list-wrap--opened-details" : ""
        } ${useInfiniteList ? "queues-list-wrap--infinite" : ""}`}
        ref={listWrapperRef}
      >
        <div className="queues-list" ref={listRef}>
          <div className="queues-list__inner">
            <List>
              {queues.length ? (
                getQueuesListItems(queues)
              ) : (
                <ListEmptyMsg>You currently have no queues.</ListEmptyMsg>
              )}
            </List>
          </div>
        </div>
      </div>
    </>
  );
};

export default QueuesList;
