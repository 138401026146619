import React, { useState, useCallback } from "react";
import { useSelector } from "react-redux";
import { List, ListEmptyMsg, ListRow } from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp, faChevronDown } from "@fortawesome/pro-solid-svg-icons";
import ContactItem from "src/components/ContactItem/ContactItem";
import { getUserStatusInfo, UserStatus } from "src/utils/user";
import { getSortedQueueMembers } from "src/utils/queue";
import "./QueueDetailsAgentsList.scss";
import { UserPermission } from "src/store/reducers/auth";
import { ReceiveCalls } from "compass.js";
import { createSelector } from "reselect";

interface IQueueDetailsAgentsListProps {
  queueId: string;
  className?: string;
  onShowManageAgents?: () => void;
}

const getQueue = (state: IRootState, queueId: string) =>
  state.queues.items[queueId];
const getUser = (state: IRootState) => state.auth.user;
const getCompanyPermission = (state: IRootState) =>
  state.auth.companyPermission;
const getApiVersion = (state: IRootState) => state.auth.apiVersion;

const getQueueAgentsData = createSelector(
  [getQueue, getUser, getCompanyPermission, getApiVersion],
  (queue, user, companyPermission, apiVersion) => {
    let queueHasInactiveAgents = false;
    const agents = getSortedQueueMembers(queue).map((item) => {
      const user = item.getUser();
      const userStatus = getUserStatusInfo(user).userStatus;
      let isActive =
        userStatus === UserStatus.available || userStatus === UserStatus.inCall;
      let isWrappingUp = false;
      if (apiVersion && apiVersion >= 3) {
        isWrappingUp =
          user.status.receiveCalls === ReceiveCalls.onlyDirect &&
          !!user.status.wrapupState;
        isActive =
          (userStatus === UserStatus.available ||
            (userStatus === UserStatus.inCall &&
              user.status.receiveCalls === ReceiveCalls.all) ||
            isWrappingUp) &&
          userStatus !== UserStatus.loggedOut;
      }
      if (!isActive && !queueHasInactiveAgents) {
        queueHasInactiveAgents = true;
      }
      return {
        id: item.getUser().id,
        isPaused:
          apiVersion && apiVersion >= 3
            ? user.status.receiveCalls === ReceiveCalls.onlyDirect &&
              !isWrappingUp
            : !!item.pausedSince,
        isActive,
      };
    });
    return {
      agents,
      queueHasInactiveAgents,
      hasWritePermissions: companyPermission === UserPermission.permWrite,
    };
  }
);

const QueueDetailsAgentsList: React.FC<IQueueDetailsAgentsListProps> = ({
  queueId,
  className,
  onShowManageAgents,
}) => {
  const { agents, queueHasInactiveAgents, hasWritePermissions } = useSelector(
    (state: IRootState) => getQueueAgentsData(state, queueId)
  );
  const [showInactiveAgents, setShowInactiveAgents] = useState(false);

  const toggleInactiveAgents = useCallback(() => {
    setShowInactiveAgents((prevState) => !prevState);
  }, []);

  const onManageAgentsClick = (event: React.MouseEvent) => {
    event.preventDefault();
    if (onShowManageAgents) {
      onShowManageAgents();
    }
  };

  const filteredAgents = showInactiveAgents
    ? agents
    : agents.filter((agent) => agent.isActive);
  const showLoggedOutToggle = !hasWritePermissions && queueHasInactiveAgents;

  return (
    <List className={className}>
      {filteredAgents.length || showLoggedOutToggle ? (
        <>
          {filteredAgents.map((agent) => (
            <ContactItem id={agent.id} key={agent.id} type="agent" />
          ))}
          {showLoggedOutToggle && (
            <ListRow key="logged-out-agents-toggle">
              <div
                className="queue-details-agents-list__toggle-inactive-agents-btn"
                onClick={toggleInactiveAgents}
              >
                <i>
                  {showInactiveAgents
                    ? "Show only active agents"
                    : `Show all agents (${agents.length})`}
                </i>
                <FontAwesomeIcon
                  icon={showInactiveAgents ? faChevronUp : faChevronDown}
                />
              </div>
            </ListRow>
          )}
        </>
      ) : (
        <ListEmptyMsg>
          {hasWritePermissions ? (
            <>
              No active agents in this queue. You can{" "}
              <button onClick={onManageAgentsClick}>
                manage agents in queue.
              </button>
            </>
          ) : (
            "No agents in this queue."
          )}
        </ListEmptyMsg>
      )}
    </List>
  );
};

export default QueueDetailsAgentsList;
