import * as React from "react";
import "./NavbarWrapUpBtn.scss";
import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { ReceiveCalls, User } from "compass.js";
import { setUserStatus } from "src/store/actions/auth";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { BridgeColor } from "src/utils/consts";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { notificationShow } from "src/store/actions";
import { usePrevious } from "src/utils";
import { INotification } from "src/store/reducers/notifications";
import { createSelector } from "reselect";

const selectAuth = (state: IRootState) => state.auth;
const selectQueues = (state: IRootState) => state.queues;
const appDataSelector = createSelector(
  [selectAuth, selectQueues],
  (auth, queues) => {
    const userStatus = (auth.user as User).status;

    const wrapUpEndTime = userStatus.wrapupState
      ? userStatus.wrapupState.endTime
      : undefined;

    const isWrapUpEnabled = !!userStatus.wrapupState;
    const queuesIsLoading = queues.actionsInProgress > 0;

    return {
      userStatus,
      wrapUpEndTime,
      isWrapUpEnabled,
      queuesIsLoading,
    };
  }
);

const NavbarWrapUpBtn: React.FC = () => {
  const { wrapUpEndTime, isWrapUpEnabled, queuesIsLoading } =
    useSelector(appDataSelector);

  const dispatch = useDispatch();
  const wrapUpManuallyDisabledFlag = useRef(false);
  const isWrapUpEnabledPrev = usePrevious(isWrapUpEnabled);
  const wrapUpEndTimePrev = usePrevious(wrapUpEndTime);
  const onSetUserStatus = (
    receiveCalls: ReceiveCalls,
    displayStatus?: string
  ) => dispatch<any>(setUserStatus(receiveCalls, displayStatus));
  const onNotificationShow = useCallback(
    (
      notification:
        | INotification
        | Pick<
            INotification,
            "message" | "autoDismiss" | "dismissable" | "level"
          >
    ) => dispatch(notificationShow(notification)),
    [dispatch]
  );

  const showInfoNotification = useCallback(
    (uid: string, message: string) => {
      onNotificationShow({
        uid,
        level: "info",
        autoDismiss: 3000,
        dismissable: true,
        message,
      });
    },
    [onNotificationShow]
  );

  const showWrapUpInitiatedNotification = useCallback(() => {
    showInfoNotification("wrap-up-enabled", "Wrap-up time has started.");
  }, [showInfoNotification]);

  const { wrapUpTime } = React.useMemo(() => {
    let wrapUpTime: number | null = null;
    if (wrapUpEndTime) {
      wrapUpTime = wrapUpEndTime - Math.round(+new Date() / 1000);
    }
    return { wrapUpTime };
  }, [wrapUpEndTime]);

  useEffect(() => {
    if (isWrapUpEnabled) {
      showWrapUpInitiatedNotification();
    }
  }, [isWrapUpEnabled, showWrapUpInitiatedNotification]);

  useEffect(() => {
    if (
      (isWrapUpEnabled === isWrapUpEnabledPrev ||
        wrapUpEndTimePrev === undefined) &&
      (wrapUpEndTime === wrapUpEndTimePrev || wrapUpEndTimePrev === undefined)
    ) {
      return;
    }

    if (isWrapUpEnabled && wrapUpEndTime !== wrapUpEndTimePrev) {
      showWrapUpInitiatedNotification();
    } else {
      if (wrapUpManuallyDisabledFlag.current) {
        wrapUpManuallyDisabledFlag.current = false;
        return;
      }
      showInfoNotification("wrap-up-ended", "Wrap-up time has ended.");
    }
  }, [
    isWrapUpEnabled,
    wrapUpEndTime,
    showInfoNotification,
    showWrapUpInitiatedNotification,
    wrapUpEndTimePrev,
    isWrapUpEnabledPrev,
  ]);

  const endWrapUp = () => {
    wrapUpManuallyDisabledFlag.current = true;
    onSetUserStatus(ReceiveCalls.all)
      .then(() => {
        showInfoNotification(
          "wrap-up-disabled",
          "You broke out of Wrap-up time."
        );
      })
      .catch(() => {
        wrapUpManuallyDisabledFlag.current = false;
      });
  };

  if (!isWrapUpEnabled) {
    return null;
  }

  return (
    <div className="navbar-wrap-up-btn">
      <Button
        disabled={queuesIsLoading}
        color={BridgeColor.gs800}
        onClick={endWrapUp}
        nohover={true}
        tooltip={"Break out of Wrap-up time"}
      >
        {wrapUpTime ? (
          <div
            // NOTE: to re-run animation if wrap up end time only changed
            key={wrapUpEndTime ? wrapUpEndTime.toString() : undefined}
            className="navbar-wrap-up-btn__progress"
            style={{
              animationDuration: `${wrapUpTime}s`,
            }}
          />
        ) : null}
        <div className="navbar-wrap-up-btn__content">
          {/* NOTE: CSS is taking care to show only one icon at the time */}
          <span className="navbar-wrap-up-btn__icon navbar-wrap-up-btn__icon--clock">
            <FontAwesomeIcon icon={faClock} />
          </span>
          <span className="navbar-wrap-up-btn__icon navbar-wrap-up-btn__icon--close">
            <FontAwesomeIcon icon={faTimes} />
          </span>
          Wrap-up
        </div>
      </Button>
    </div>
  );
};

export default NavbarWrapUpBtn;
