import { LoginPhoneModalType } from "./../../components/LoginPhoneModal/LoginPhoneModal";
import { OnboardingController } from "../../utils/OnboardingController";
import { OnboardingStep } from "../../utils/OnboardingStep";
import { User, Connection, Language } from "compass.js";
import * as actionTypes from "src/store/actions/actionTypes";
import { IRootState } from ".";

export interface ICompassResource {
  self: string;
  theType: string;
  resourceId: number;
  owner: number;
  name: string;
}

export interface ICompassUserStatus {
  online: boolean;
  phone?: string;
}

export interface ICompassCompany {
  contact: string;
  entityId: number;
  musicOnHold: number;
  name: string;
  self: string;
  shortname: string;
  sipDomain: string;
  theLanguage: string;
  theType: string;
  xmppDomain: string;
}

export interface IPhoneData {
  id: number;
  name: string;
  capabilities: PhoneCapability[];
  status?: ICompassPhoneStatus;
}

export interface IUserCachedInfo {
  name: string;
  jid: string;
  username: string;
  language: Language;
  contact: string;
}

export enum UserFeature {
  callcontrol = "callcontrol",
  listenin = "listenin",
  pickupqueuecall = "pickupqueuecall",
  wrapup = "wrapup",
}

export enum UserPermission {
  permWrite = "permWrite",
  permRead = "permRead",
  permNone = "permNone",
}

export enum PhoneCapability {
  answer = "answer",
  hold = "hold",
  unhold = "unhold",
}

export enum OnboardingType {
  default = "default",
  guest = "guest",
}

export interface ICompassPhone extends ICompassResource {
  model: string;
  loggedInUser: string;
  macAddress: number[];
}

export interface ICompassPhoneStatus {
  encrypted: boolean;
  expires: number;
  online: boolean;
  privateIP: string;
  publicIP: string;
  user: string;
  userAgent: string;
}

export interface IAuthState {
  readonly user?: User;
  readonly userCachedInfo?: IUserCachedInfo;
  readonly connection?: Connection;
  readonly apiVersion?: number;
  readonly isAuthenticated: boolean;
  readonly company?: ICompassCompany;
  readonly companyPermission?: UserPermission;
  readonly loginInProgress: boolean;
  readonly initializationInProgress: boolean;
  readonly features: UserFeature[];
  readonly showAskFeedbackModal: boolean;
  readonly showFeedbackModal: boolean;
  readonly phone?: IPhoneData;
  readonly recentPhones: number[];
  readonly userStatus?: ICompassUserStatus;

  readonly showLoginPhoneModal: boolean;
  readonly loginPhoneModalType?: LoginPhoneModalType;

  readonly showOnboardingModal: boolean;

  readonly onboardingMode: boolean;
  readonly onboardingType?: OnboardingType;
  readonly onboardingController?: OnboardingController;
  readonly onboardingStep?: OnboardingStep;

  // NOTE: backup for onboarding
  readonly backup: {
    connection?: Connection;
    user?: User;
    features?: UserFeature[];
    phone?: IPhoneData;
  };
}

const initialState: IAuthState = {
  isAuthenticated: false,
  loginInProgress: false,
  showOnboardingModal: false,
  initializationInProgress: false,
  companyPermission: undefined,
  features: [],
  onboardingMode: false,
  backup: {},
  showAskFeedbackModal: false,
  showFeedbackModal: false,
  showLoginPhoneModal: false,
  recentPhones: [],
};

const reducer = (state: IAuthState = initialState, action: any): IAuthState => {
  switch (action.type) {
    case actionTypes.AUTH_UPDATE_USER_FEATURES:
      return {
        ...state,
        features: action.payload,
      };
    case actionTypes.AUTH_UPDATE_CONNECTION:
      return {
        ...state,
        connection: action.payload,
      };
    case actionTypes.AUTH_UPDATE_USER_INFO: {
      const user: User = action.payload;
      return {
        ...state,
        user,
        userCachedInfo: {
          name: user.name,
          jid: user.jid,
          username: user.username,
          language: user.language,
          contact: user.contact,
        },
      };
    }
    case actionTypes.AUTH_SET_API_VERSION:
      return {
        ...state,
        apiVersion: action.payload,
      };
    case actionTypes.AUTH_UPDATE_USER_COMPANY:
      return {
        ...state,
        company: action.payload,
      };
    case actionTypes.AUTH_UPDATE_USER_COMPANY_PERMISSION:
      return {
        ...state,
        companyPermission: action.payload,
      };
    case actionTypes.AUTH_LOGIN_STARTED:
      return {
        ...state,
        loginInProgress: true,
      };
    case actionTypes.AUTH_LOGIN_FINISHED:
      return {
        ...state,
        loginInProgress: false,
        isAuthenticated: action.payload,
      };
    case actionTypes.AUTH_INITIALIZATION_STARTED:
      return {
        ...state,
        initializationInProgress: true,
      };
    case actionTypes.AUTH_INITIALIZATION_FINISHED:
      return {
        ...state,
        initializationInProgress: false,
        isAuthenticated: action.payload,
      };
    case actionTypes.AUTH_CLEAR_USER_DATA:
      return {
        ...state,
        user: undefined,
        userCachedInfo: undefined,
        connection: undefined,
        phone: undefined,
        userStatus: undefined,
        features: [],
        companyPermission: undefined,
        isAuthenticated: false,
        onboardingMode: false,
        onboardingType: undefined,
        onboardingController: undefined,
        onboardingStep: undefined,
        backup: {},
      };
    case actionTypes.AUTH_START_ONBOARDING: {
      const onboardingController: OnboardingController =
        action.payload.controller;
      return {
        ...state,
        onboardingMode: true,
        onboardingType: action.payload.type,
        onboardingController,
        onboardingStep: onboardingController.step,
        connection: onboardingController.getConnection(),
        // NOTE: full access during onboarding
        phone: {
          id: 1,
          name: "My Phone",
          capabilities: onboardingController.getRestPhoneCaps(),
          status: {
            encrypted: true,
            expires: 0,
            online: true,
            privateIP: "",
            publicIP: "",
            user: "",
            userAgent: "",
          },
        },
        features: onboardingController.getRestUserFeatures(),
        backup: {
          user: state.user,
          connection: state.connection,
          phone: state.phone,
          features: state.features,
        },
      };
    }
    case actionTypes.AUTH_STOP_ONBOARDING:
      return {
        ...state,
        onboardingMode: false,
        onboardingType: undefined,
        onboardingController: undefined,
        onboardingStep: undefined,
        user: state.backup.user,
        connection: state.backup.connection,
        phone: state.backup.phone,
        features: state.backup.features || [],
        backup: {},
      };
    case actionTypes.AUTH_ONBOARDING_SET_STEP:
      return {
        ...state,
        onboardingStep: action.payload,
      };
    case actionTypes.AUTH_SET_PHONE_DATA:
      return {
        ...state,
        phone: action.payload,
      };
    case actionTypes.AUTH_SHOW_LOGIN_PHONE_MODAL:
      return {
        ...state,
        showLoginPhoneModal: true,
        loginPhoneModalType: action.payload,
      };
    case actionTypes.AUTH_CLOSE_LOGIN_PHONE_MODAL:
      return {
        ...state,
        showLoginPhoneModal: false,
        loginPhoneModalType: undefined,
      };
    case actionTypes.AUTH_SET_USER_STATUS:
      return {
        ...state,
        userStatus: action.payload,
      };
    case actionTypes.AUTH_UPDATE_USER_CACHED_INFO:
      return {
        ...state,
        userCachedInfo: action.payload,
      };
    case actionTypes.AUTH_SHOW_ONBOARDING_MODAL:
      return {
        ...state,
        showOnboardingModal: true,
      };
    case actionTypes.AUTH_CLOSE_ONBOARDING_MODAL:
      return {
        ...state,
        showOnboardingModal: false,
      };
    case actionTypes.AUTH_SET_RECENT_PHONES:
      return {
        ...state,
        recentPhones: action.payload,
      };
    default:
      return state;
  }
};

export const selectUser = ({ auth: { user } }: IRootState) => user;

export default reducer;
