import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./ContactDetails.scss";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import {
  List,
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
  ListHeading,
} from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { Connection } from "compass.js";
import {
  getUserStatusInfo,
  UserStatus,
  getUserStatusDisplay,
} from "src/utils/user";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faEnvelope } from "@fortawesome/pro-solid-svg-icons/faEnvelope";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { dialNumber } from "src/store/actions";
import { ContactType } from "src/store/reducers/contacts";
import { handleError } from "src/utils/errorHandler";
import { TrackAction, TrackCategory } from "src/utils/track";
import CallDetailCounter, {
  CallDetailCounterType,
} from "../CallDetailCounter/CallDetailCounter";
import Segment, { ISegmentProps } from "../UI/Segment/Segment";
import ContactDetailsCalls from "./ContactDetailsCalls/ContactDetailsCalls";
import { BridgeColor } from "src/utils/consts";
import { createSelector } from "reselect";

const TRACK_CATEGORY = TrackCategory.contactDetails;

enum OpenedTab {
  details = "details",
  calls = "calls",
}

interface IContactDetailsProps {
  id: string;
  hideHeader?: boolean;
  inline?: boolean;
}
const selectContact = (state: IRootState, id: string) =>
  state.contacts.compassItems[id] || state.contacts.addressBookItems[id];
const selectCompany = (state: IRootState) => state.auth.company;
const selectConnection = (state: IRootState) =>
  state.auth.connection as Connection;
const selectOnboardingMode = (state: IRootState) => state.auth.onboardingMode;
const selectCallsIsLoading = (state: IRootState) =>
  state.calls.actionsInProgress > 0;
const selectOnline = (state: IRootState) => state.window.online;
const appDataSelector = createSelector(
  [
    selectContact,
    selectCompany,
    selectConnection,
    selectOnboardingMode,
    selectCallsIsLoading,
    selectOnline,
  ],
  (contact, company, connection, onboardingMode, callsIsLoading, online) => {
    let userStatusInfo = null;
    let userStatusDisplay = null;

    if (contact && contact.type === ContactType.compass) {
      const user = connection.model.users[contact.id];
      if (user) {
        userStatusInfo = getUserStatusInfo(user);
        userStatusDisplay = getUserStatusDisplay(user.id, { extended: true });
      }
    }

    return {
      phones: contact ? contact.phones : [],
      emails: contact ? contact.emails : [],
      notes: contact ? contact.notes : "",
      isContactAvailable: !!contact,
      name: contact ? contact.name : "",
      callsIsLoading,
      online,
      status: userStatusInfo ? userStatusInfo.userStatus : null,
      statusDisplay: userStatusDisplay,
      statusCallId: userStatusInfo?.call?.id ?? null,
      contactType: contact?.type,
      onboardingMode,
      company,
      contact,
    };
  }
);
const ContactDetails: React.FC<IContactDetailsProps> = ({
  id,
  hideHeader,
  inline,
}) => {
  const {
    phones,
    emails,
    notes,
    isContactAvailable,
    name,
    callsIsLoading,
    online,
    status,
    statusDisplay,
    statusCallId,
    contactType,
    onboardingMode,
    company,
    contact,
  } = useSelector((state: IRootState) => appDataSelector(state, id));

  const dispatch = useDispatch();

  const [openedTab, setOpenedTab] = useState<OpenedTab>(OpenedTab.details);

  const companyName =
    contactType === ContactType.compass
      ? company
        ? company.name
        : ""
      : contact.company;
  const companyContact = company ? company.contact : "";

  const openTab = useCallback(
    (tab: OpenedTab) => {
      if (!onboardingMode) {
        setOpenedTab(tab);
      }
    },
    [onboardingMode]
  );

  const dialPhoneNumber = useCallback(
    (destination: string) => {
      dispatch<any>(dialNumber(destination)).catch(handleError);
    },
    [dispatch]
  );

  const composeEmail = useCallback((email: string) => {
    window.location.href = `mailto:${email}`;
  }, []);

  const renderTabContent = () => {
    switch (openedTab) {
      case OpenedTab.details:
        return (
          <List className={"contact-details__list"}>
            {renderCompanyBlock()}
            {renderPhoneBlock()}
            {renderEmailBlock()}
            {renderNotesBlock()}
          </List>
        );
      case OpenedTab.calls:
        return <ContactDetailsCalls contactId={id} />;
    }
  };
  const renderNotesBlock = (): React.ReactNode => {
    if (!notes?.length) {
      return null;
    }
    return (
      <>
        <div className="list__heading">Notes</div>

        <div className="contact-detail--notes">{notes}</div>
      </>
    );
  };

  const renderCompanyBlock = () => {
    if (!companyName && contactType === ContactType.compass) {
      return null;
    }
    if (!companyName && contactType === ContactType.addressBook) {
      return null;
    }

    return (
      <>
        <ListHeading>Company</ListHeading>
        <ListRow>
          <ListRowTitle>{companyName}</ListRowTitle>
          <ListRowInfo>
            <ListRowInfoItem>{companyContact || ""}</ListRowInfoItem>
          </ListRowInfo>
        </ListRow>
      </>
    );
  };

  const renderPhoneBlock = () => {
    if (!phones.length) {
      return null;
    }
    return (
      <>
        <ListHeading>Phone</ListHeading>
        {phones.map((phone, idx) => (
          <ListRow key={idx}>
            <ListRowTitle>{phone.label}</ListRowTitle>
            <ListRowInfo>
              <ListRowInfoItem>{phone.value}</ListRowInfoItem>
              <ListRowInfoItem isButton={true}>
                <Button
                  icononly={true}
                  small={true}
                  color={BridgeColor.gs300}
                  disabled={callsIsLoading || !online}
                  onClick={() => dialPhoneNumber(phone.value)}
                  track={[TRACK_CATEGORY, TrackAction.contactDetailsDial]}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              </ListRowInfoItem>
            </ListRowInfo>
          </ListRow>
        ))}
      </>
    );
  };

  const renderEmailBlock = () => {
    if (!emails.length) {
      return null;
    }
    return (
      <>
        <ListHeading>Email</ListHeading>
        {emails.map((item, idx) => (
          <ListRow key={idx}>
            <ListRowTitle>{item.label}</ListRowTitle>
            <ListRowInfo>
              <ListRowInfoItem>{item.value}</ListRowInfoItem>
              <ListRowInfoItem isButton={true}>
                <Button
                  icononly={true}
                  small={true}
                  tooltip={"Email"}
                  color={BridgeColor.gs300}
                  onClick={() => composeEmail(item.value)}
                  track={[TRACK_CATEGORY, TrackAction.contactDetailsEmail]}
                >
                  <FontAwesomeIcon icon={faEnvelope} />
                </Button>
              </ListRowInfoItem>
            </ListRowInfo>
          </ListRow>
        ))}
      </>
    );
  };

  if (!isContactAvailable) {
    return null;
  }

  const cssClasses = ["contact-details"];
  if (inline) {
    cssClasses.push("contact-details--inline");
  }

  const segmentProps: ISegmentProps = {
    onChange: openTab,
    value: openedTab,
    items: [
      { title: "Details", value: OpenedTab.details },
      { title: "Calls", value: OpenedTab.calls },
    ],
  };

  return (
    <div className={cssClasses.join(" ")}>
      {!hideHeader && (
        <ContainerHeader
          title={name || companyName || ""}
          enableBackBtn={true}
          mobileOnlyBackBtn={true}
          showTitleTooltip={true}
          className={"container-header--no-border"}
          titleIcon={{
            icon: faCircle,
            color: `${status ? `status-${status}` : "grey-1"}`,
          }}
        >
          {status && statusDisplay && (
            <div className="container-header-buttons">
              <div className="contact-details__status">
                {statusDisplay}
                {status === UserStatus.inCall && statusCallId && (
                  <span>
                    &nbsp;(
                    <CallDetailCounter
                      callId={statusCallId}
                      userId={id}
                      type={CallDetailCounterType.userDuration}
                    />
                    )
                  </span>
                )}
              </div>
            </div>
          )}
        </ContainerHeader>
      )}
      <div className="contact-details__segment-wrap">
        <Segment {...segmentProps} />
      </div>
      <div className="contact-details__tab-template">{renderTabContent()}</div>
    </div>
  );
};

export default ContactDetails;
