import { CallMetadata } from "../../utils/CallMetadata";
import * as actionTypes from "src/store/actions/actionTypes";
import { Call } from "compass.js";
import { containsCallById } from "src/utils/call";

export interface ICallsState {
  readonly items: Call[];
  readonly endedItems: Call[];
  readonly callsMetadata: { [key: string]: CallMetadata };
  readonly allItems: Call[];
  readonly allCallsMetadata: { [key: string]: CallMetadata };
  // NOTE: map queue call ids to queue ids
  // to identify queue for answered calls
  readonly lastQueueMap: { [key: string]: string };
  readonly actionsInProgress: number;
  readonly desiredTransferCallId?: string;
  readonly desiredAttendedTransferDestination?: string;
  readonly answeredDiallerCallsInProgress: string[];
  readonly highlightedCallIds: string[];
}

const initialState: ICallsState = {
  items: [],
  callsMetadata: {},
  allItems: [],
  allCallsMetadata: {},
  endedItems: [],
  answeredDiallerCallsInProgress: [],
  lastQueueMap: {},
  actionsInProgress: 0,
  desiredTransferCallId: undefined,
  highlightedCallIds: []
};

const reducer = (
  state: ICallsState = initialState,
  action: any
): ICallsState => {
  switch (action.type) {
    case actionTypes.CALLS_UPDATE: {
      const calls = action.payload.calls as Call[];
      const callsMetadata = action.payload.callsMetadata as {
        [key: string]: CallMetadata;
      };
      const callIds = calls.map(call => call.id);
      const endedCallIds = state.endedItems.map(call => call.id);

      // NOTE: remove attended transfer
      // if at least one side isn't available
      Object.values(callsMetadata).forEach(callMetadata => {
        const attendedTransferOtherLegId =
          callMetadata.attendedTransferOtherLegId;
        if (!attendedTransferOtherLegId) {
          return;
        }
        if (
          !callIds.includes(callMetadata.call.id) ||
          endedCallIds.includes(callMetadata.call.id) ||
          !callIds.includes(attendedTransferOtherLegId) ||
          endedCallIds.includes(attendedTransferOtherLegId)
        ) {
          callMetadata.resetAttendedTransfer();
          if (callsMetadata[attendedTransferOtherLegId]) {
            callsMetadata[attendedTransferOtherLegId].resetAttendedTransfer();
          }
        }
      });
      let desiredTransferCallId: string | undefined;
      // NOTE: remove desiredTransferCallId if call doesn't exist anymore
      if (
        state.desiredTransferCallId &&
        containsCallById(calls, state.desiredTransferCallId) &&
        !containsCallById(state.endedItems, state.desiredTransferCallId)
      ) {
        desiredTransferCallId = state.desiredTransferCallId;
      }
      return {
        ...state,
        items: calls,
        callsMetadata,
        desiredTransferCallId
      };
    }
    case actionTypes.CALLS_UPDATE_ALL: {
      return {
        ...state,
        allItems: action.payload.calls,
        allCallsMetadata: action.payload.callsMetadata
      };
    }
    case actionTypes.CALLS_ADD_ENDED:
      return {
        ...state,
        endedItems: [...state.endedItems, ...action.payload]
      };
    case actionTypes.CALLS_REMOVE_ENDED: {
      const callsMetadata = { ...state.callsMetadata };
      action.payload.forEach((item: Call) => {
        delete callsMetadata[item.id];
      });
      return {
        ...state,
        endedItems: state.endedItems.filter(
          item => !containsCallById(action.payload, item.id)
        ),
        items: state.items.filter(
          item => !containsCallById(action.payload, item.id)
        ),
        callsMetadata
      };
    }
    case actionTypes.CALLS_ACTION_STARTED:
      return {
        ...state,
        actionsInProgress: state.actionsInProgress + 1
      };
    case actionTypes.CALLS_ACTION_COMPLETED:
      return {
        ...state,
        actionsInProgress: state.actionsInProgress - 1
      };
    case actionTypes.CALLS_START_TRANSFERRING: {
      const callsMetadata = { ...state.callsMetadata };
      if (callsMetadata[action.payload]) {
        callsMetadata[action.payload].desiredForTransfer = true;
      }
      return {
        ...state,
        callsMetadata,
        desiredTransferCallId: action.payload
      };
    }
    case actionTypes.CALLS_REGISTER_ATTENDED_TRANSFER: {
      const { mainCallId, consultationCallId } = action.payload;
      const callsMetadata = { ...state.callsMetadata };
      clearDesiredTransfer(callsMetadata);
      if (callsMetadata[mainCallId]) {
        callsMetadata[
          mainCallId
        ].attendedTransferConsultationLegId = consultationCallId;
      }
      if (callsMetadata[consultationCallId]) {
        callsMetadata[
          consultationCallId
        ].attendedTransferMainLegId = mainCallId;
      }
      return {
        ...state,
        callsMetadata
      };
    }
    case actionTypes.CALLS_DISMISS_TRANSFERRING: {
      const callsMetadata = { ...state.callsMetadata };
      clearDesiredTransfer(callsMetadata);
      return {
        ...state,
        callsMetadata,
        desiredTransferCallId: undefined
      };
    }
    case actionTypes.CALLS_ANSWER_DIALLER_CALL_START:
      return {
        ...state,
        answeredDiallerCallsInProgress: [
          action.payload,
          ...state.answeredDiallerCallsInProgress
        ]
      };
    case actionTypes.CALLS_ANSWER_DIALLER_CALL_FINISH:
      return {
        ...state,
        answeredDiallerCallsInProgress: state.answeredDiallerCallsInProgress.filter(
          item => item !== action.payload
        )
      };
    case actionTypes.CALLS_ADD_HIGHLIGHTED_CALL:
      return {
        ...state,
        highlightedCallIds: [action.payload, ...state.highlightedCallIds]
      };
    case actionTypes.CALLS_REMOVE_HIGHLIGHTED_CALL:
      return {
        ...state,
        highlightedCallIds: state.highlightedCallIds.filter(
          item => item !== action.payload
        )
      };
    case actionTypes.CALLS_REGISTER_ATTENDED_TRANSFER_DESTINATION:
      return {
        ...state,
        desiredAttendedTransferDestination: action.payload.destination
      };
    case actionTypes.CALLS_CANCEL_ATTENDED_TRANSFER_DESTINATION:
      return {
        ...state,
        desiredAttendedTransferDestination: undefined
      };
    default:
      return state;
  }
};

const clearDesiredTransfer = (callMetadata: {
  [key: string]: CallMetadata;
}) => {
  Object.values(callMetadata).forEach(
    item => (item.desiredForTransfer = false)
  );
};

export default reducer;
