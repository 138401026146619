import * as React from "react";
import "./SettingsForwardsForm.scss";
import { useSelector } from "react-redux";
import {
  selectCompassPreferences,
  CallForwardOption,
  CallForwardType,
  updateUserIdentity,
  ApplyToQueues,
  selectUserPreferences,
  updateUserPreferences,
} from "src/store/preferences";
import Radio from "../UI/Radio/Radio";
import { Loader } from "../UI/Loader/Loader";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons/faChevronRight";
import RingTimeModal from "../RingTimeModal/RingTimeModal";
import ForwardModal from "../ForwardModal/ForwardModal";
import InfoMessage from "../UI/InfoMessage/InfoMessage";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import {
  selectAllContactsNumbersMap,
  selectAllContacts,
} from "src/store/reducers/contacts";
import { selectNavigationParams } from "src/store/reducers/navigation";
import { useReduxDispatch } from "src/utils/hooks";
import { handleError, IError } from "src/utils/errorHandler";
import { selectAllQueues } from "src/store/reducers/queues";
import { selectUser } from "src/store/reducers/auth";
import { loginQueue } from "src/store/actions";
import { VOICEMAIL_NUMBER } from "src/utils/consts";

enum ForwardAsValue {
  callerPhoneNumber = "callerPhoneNumber",
  myNumber = "myNumber",
}

const getCallForwardOption = (value: string) => {
  if (!value) {
    return CallForwardOption.notSet;
  } else if (value === VOICEMAIL_NUMBER) {
    return CallForwardOption.voicemail;
  }
  return CallForwardOption.customNumber;
};

const SettingsForwardsForm: React.FC = () => {
  const dispatch = useReduxDispatch();
  const navigationParams = useSelector(selectNavigationParams);
  const { identity, isLoading, error } = useSelector(selectCompassPreferences);
  const userPreferences = useSelector(selectUserPreferences);
  const allContacts = useSelector(selectAllContacts);
  const allContactsNumbersMap = useSelector(selectAllContactsNumbersMap);
  const [ringTimeModalOpened, setRingTimeModalOpened] =
    React.useState<boolean>(false);
  const queues = useSelector(selectAllQueues);
  const user = useSelector(selectUser);
  const [currentCustomNumberForward, setCurrentCustomNumberForward] =
    React.useState<CallForwardType | undefined>();
  const onRingTimeModalClose = React.useCallback(() => {
    setRingTimeModalOpened(false);
  }, [setRingTimeModalOpened]);
  const onForwardModalClose = React.useCallback(() => {
    setCurrentCustomNumberForward(undefined);
  }, [setCurrentCustomNumberForward]);
  const onForwardChange = async (
    type: CallForwardType,
    option: CallForwardOption
  ) => {
    if (option === CallForwardOption.customNumber) {
      setCurrentCustomNumberForward(type);
      return;
    }
    const value =
      option === CallForwardOption.voicemail ? VOICEMAIL_NUMBER : "";
    const response = await dispatch(updateUserIdentity({ [type]: value }));
    if (response.type === updateUserIdentity.rejected.type) {
      handleError(response.payload as IError);
    }
  };
  const onForwardAsChange = async (forwardAs: ForwardAsValue) => {
    const response = await dispatch(
      updateUserIdentity({
        cliTransFwd: forwardAs === ForwardAsValue.callerPhoneNumber,
      })
    );
    if (response.type === updateUserIdentity.rejected.type) {
      handleError(response.payload as IError);
    }
  };
  const onApplyToQueuesChange = async (applyToQueues: ApplyToQueues) => {
    await dispatch(updateUserPreferences({ applyToQueues }));
    const queuesReLoginPromises = Object.values(queues)
      .filter((queue) => user && queue.isUserInQueue(user.id))
      .map((queue) => dispatch(loginQueue(queue.id)));
    try {
      await Promise.all(queuesReLoginPromises);
    } catch (error) {
      handleError(error);
    }
  };
  const onRingTimeClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    setRingTimeModalOpened(true);
  };
  const getRadioOptions = (type: CallForwardType) => {
    return [
      {
        name: "Don't forward",
        value: CallForwardOption.notSet,
      },
      {
        name: "Contact or number",
        value: CallForwardOption.customNumber,
        label:
          identity &&
          getCallForwardOption(identity[type]) ===
            CallForwardOption.customNumber
            ? allContactsNumbersMap[identity[type]]
              ? allContacts[allContactsNumbersMap[identity[type]]].name
              : identity[type]
            : "",
        labelIcon: faChevronRight,
      },
      {
        name: "Voicemail",
        value: CallForwardOption.voicemail,
      },
    ];
  };
  const isAlwaysApplied =
    identity && !!identity[CallForwardType.always] ? true : false;
  const isAllDontForward =
    identity &&
    !identity[CallForwardType.always] &&
    !identity[CallForwardType.busy] &&
    !identity[CallForwardType.noAnswer] &&
    !identity[CallForwardType.unavailable];
  return (
    <div className="settings-forwards-form">
      {isLoading ? (
        <div className="br-content-loader">
          <Loader />
        </div>
      ) : error || !identity ? (
        <div>{/* TODO: check error message with @tena */}Error</div>
      ) : (
        <>
          <RingTimeModal
            isOpen={ringTimeModalOpened}
            onRequestClose={onRingTimeModalClose}
          />
          <ForwardModal
            isOpen={!!currentCustomNumberForward}
            onRequestClose={onForwardModalClose}
            forwardType={currentCustomNumberForward}
          />
          <div
            className={`br-form-group ${
              navigationParams && navigationParams.highlightAlwaysForwarding
                ? "settings-forwards-form__group-highlighted"
                : ""
            }`}
          >
            <div className="br-form-label">Always</div>
            <div className="br-form-info">
              Always forward all my incoming calls to...
            </div>
            <div className="br-form-control">
              <Radio
                value={getCallForwardOption(identity[CallForwardType.always])}
                options={getRadioOptions(CallForwardType.always)}
                onSelect={onForwardChange.bind(null, CallForwardType.always)}
              />
            </div>
            {isAlwaysApplied ? (
              <InfoMessage level="warning" icon={faExclamationTriangle}>
                "Always" applies always so other settings are ignored.
              </InfoMessage>
            ) : null}
          </div>
          <div
            className={`br-form-group ${
              isAlwaysApplied ? "br-form-group--disabled" : ""
            }`}
          >
            <div className="br-form-label">Busy line</div>
            <div className="br-form-info">
              When my line is busy, forward my incoming calls to...
            </div>
            <div className="br-form-control">
              <Radio
                disabled={isAlwaysApplied}
                value={getCallForwardOption(identity[CallForwardType.busy])}
                options={getRadioOptions(CallForwardType.busy)}
                onSelect={onForwardChange.bind(null, CallForwardType.busy)}
              />
            </div>
          </div>
          <div
            className={`br-form-group ${
              isAlwaysApplied ? "br-form-group--disabled" : ""
            }`}
          >
            <div className="br-form-label">No answer</div>
            <div className="br-form-info">
              If I don't pick up the phone during{" "}
              {
                <button onClick={onRingTimeClick}>
                  {identity.ringtime} seconds ringing time
                </button>
              }{" "}
              forward
              <br /> my calls to...
            </div>
            <div className="br-form-control">
              <Radio
                disabled={isAlwaysApplied}
                value={getCallForwardOption(identity[CallForwardType.noAnswer])}
                options={getRadioOptions(CallForwardType.noAnswer)}
                onSelect={onForwardChange.bind(null, CallForwardType.noAnswer)}
              />
            </div>
          </div>
          <div
            className={`br-form-group ${
              isAlwaysApplied ? "br-form-group--disabled" : ""
            }`}
          >
            <div className="br-form-label">Unavailable line</div>
            <div className="br-form-info">
              If my line is unavailable because I am not logged in on a phone,
              <br />
              forward my calls to...
            </div>
            <div className="br-form-control">
              <Radio
                disabled={isAlwaysApplied}
                value={getCallForwardOption(
                  identity[CallForwardType.unavailable]
                )}
                options={getRadioOptions(CallForwardType.unavailable)}
                onSelect={onForwardChange.bind(
                  null,
                  CallForwardType.unavailable
                )}
              />
            </div>
          </div>
          <div className="br-form-divider" />
          <div
            className={`br-form-group ${
              isAllDontForward ? "br-form-group--disabled" : ""
            }`}
          >
            <div className="br-form-label">Forward as</div>
            <div className="br-form-info">
              When call is forwarded, show the incoming call as...
            </div>
            <div className="br-form-control">
              <Radio
                disabled={!!isAllDontForward}
                value={
                  identity.cliTransFwd
                    ? ForwardAsValue.callerPhoneNumber
                    : ForwardAsValue.myNumber
                }
                options={[
                  {
                    name: "From my number",
                    value: ForwardAsValue.myNumber,
                  },
                  {
                    name: "From caller's number",
                    value: ForwardAsValue.callerPhoneNumber,
                  },
                ]}
                onSelect={onForwardAsChange}
              />
            </div>
          </div>
          <div className="br-form-group">
            <div className="br-form-label">Apply to queues</div>
            <div className="br-form-info">
              Apply the personal forward settings to...
            </div>
            <div className="br-form-control">
              <Radio
                value={userPreferences.applyToQueues}
                options={[
                  {
                    name: "Only direct calls",
                    value: ApplyToQueues.onlyDirectCalls,
                  },
                  {
                    name: "All calls (also queue calls)",
                    value: ApplyToQueues.allCalls,
                  },
                ]}
                onSelect={onApplyToQueuesChange}
              />
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default SettingsForwardsForm;
