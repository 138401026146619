import { UserFeature } from "./../store/reducers/auth";
import { getCallDescriptionForUser, getCallQueue } from "src/utils/call";
import {
  User,
  Call,
  CallPointState,
  OtherSide,
  ReceiveCalls
} from "compass.js";
import { shouldShowCallForUser } from "src/utils/call";
import { getUserSide } from "./call";
import { store } from "src/store";
import { ContactType } from "src/store/reducers/contacts";

export enum UserStatus {
  available = "available",
  loggedOut = "logged-out",
  away = "away",
  inCall = "in-call",
  busy = "busy"
}

export interface UserStatusInfo {
  userStatus: UserStatus;
  call?: Call;
}

const getUserIncomingRingingCall = (
  user: User,
  considerRingingQueueCalls: boolean
) => {
  return user.getCalls().find(item => {
    if (!shouldShowCallForUser(item, user)) {
      return false;
    }
    if (!considerRingingQueueCalls && getCallQueue(item)) {
      return false;
    }
    const userSide = getUserSide(item, user);
    if (userSide === null) {
      return false;
    }
    return item.getEndpoint(userSide).state === CallPointState.ringing;
  });
};

const getUserActiveCall = (user: User) => {
  return user.getCalls().find(item => {
    if (!shouldShowCallForUser(item, user)) {
      return false;
    }
    const userSide = getUserSide(item, user);
    if (userSide === null) {
      return false;
    }
    const otherState = item.getEndpoint(OtherSide(userSide)).state;
    if (otherState === CallPointState.connecting) {
      // NOTE: #204 - don't show connecting calls
      return false;
    }
    const userState = item.getEndpoint(userSide).state;
    switch (userState) {
      case CallPointState.connecting:
      case CallPointState.ringing:
        // NOTE: Set in-call only when we're talking (answered/on-hold)
        return false;
    }
    return true;
  });
};

const isUserOnline = (user: User): boolean => {
  // NOTE: https://gitlab.iperitydev.com/compass/compass/-/issues/3872
  // user.loggedIn is set and fixed when starting up Bridge, it won't be updated
  // when the user switches phones. Tracked in #705.
  return user.loggedIn;
};

export const getUserStatusDisplay = (
  userId: User["id"],
  params?: { considerRingingQueueCalls?: boolean; extended?: boolean }
): string | null => {
  const apiVersion = store.getState().auth.apiVersion;
  const finalParams = {
    considerRingingQueueCalls: false,
    extended: false,
    ...(params || {})
  };
  const state = store.getState();
  const contact =
    state.contacts.compassItems[userId] ||
    state.contacts.addressBookItems[userId];
  if (contact.type !== ContactType.compass || !contact.user) {
    return null;
  }
  const user = contact.user;
  const incomingRingingCall = getUserIncomingRingingCall(
    user,
    finalParams.considerRingingQueueCalls
  );
  const activeCall = getUserActiveCall(user);
  let display: string = "";
  let displayExtended: string | null = null;
  if (
    apiVersion &&
    apiVersion >= 3 &&
    user.status.receiveCalls === ReceiveCalls.none
  ) {
    display = "Blocking all calls";
  } else if (activeCall) {
    display = "In Call";
    if (finalParams.extended) {
      displayExtended = getCallDescriptionForUser(activeCall, user);
    }
  } else if (
    apiVersion &&
    apiVersion >= 3 &&
    user.status.receiveCalls === ReceiveCalls.onlyDirect
  ) {
    display = !!user.status.wrapupState ? "Wrap-up" : "Blocking queue calls";
  } else if (incomingRingingCall) {
    display = getCallDescriptionForUser(incomingRingingCall, user);
  } else if (isUserOnline(user)) {
    display = "Online";
  }
  return finalParams.extended ? displayExtended || display : display;
};

export const getUserStatusInfo = (
  user: User,
  params?: { considerRingingQueueCalls?: boolean }
): UserStatusInfo => {
  const apiVersion = store.getState().auth.apiVersion;
  let userStatus: UserStatus = UserStatus.loggedOut;
  const finalParams = {
    considerRingingQueueCalls: false,
    ...(params || {})
  };
  const incomingRingingCall = getUserIncomingRingingCall(
    user,
    finalParams.considerRingingQueueCalls
  );
  const activeCall = getUserActiveCall(user);

  let call: Call | undefined;
  if (
    apiVersion &&
    apiVersion >= 3 &&
    user.status.receiveCalls === ReceiveCalls.none
  ) {
    userStatus = UserStatus.away;
  } else if (activeCall) {
    userStatus = UserStatus.inCall;
    call = activeCall;
  } else if (
    apiVersion &&
    apiVersion >= 3 &&
    user.status.receiveCalls === ReceiveCalls.onlyDirect
  ) {
    userStatus = UserStatus.busy;
  } else if (incomingRingingCall) {
    userStatus = UserStatus.available;
    call = incomingRingingCall;
  } else if (isUserOnline(user)) {
    userStatus = UserStatus.available;
  }
  return { userStatus, call };
};

export const canAuthenticatedUserUseFeature = (feature: UserFeature) => {
  const state = store.getState();
  return state.auth.isAuthenticated && state.auth.features.includes(feature);
};
