import { store } from "..";
import { combineReducers } from "redux";
import contactsReducer from "./contacts";
import historyReducer from "./history";
import queuesReducer from "./queues";
import callsReducer from "./calls";
import authReducer from "./auth";
import notificationsReducer from "./notifications";
import navigationReducer from "./navigation";
import intervalReducer from "./tickInterval";
import preferencesReducer from "./../preferences";
import windowReducer from "./window";
import { useDispatch } from "react-redux";
import { ThunkDispatch } from "@reduxjs/toolkit";

export const rootReducer = combineReducers({
  contacts: contactsReducer,
  history: historyReducer,
  queues: queuesReducer,
  calls: callsReducer,
  auth: authReducer,
  notifications: notificationsReducer,
  navigation: navigationReducer,
  tickInterval: intervalReducer,
  preferences: preferencesReducer,
  window: windowReducer,
});

export type IRootState = ReturnType<typeof rootReducer>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<ThunkDispatch<any, any, any>>();
