import { IRootState } from "src/store/reducers/index";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { NavigationHomeList } from "./reducers/navigation";
import { userStorage } from "src/utils/userStorage";
import { wrapApiError } from "src/utils/errorHandler";

export enum ViewModeType {
  comfortable = "comfortable",
  compact = "compact"
}

export enum ApplyToQueues {
  onlyDirectCalls = "onlyDirectCalls",
  allCalls = "allCalls"
}

export interface CompassUserIdentity {
  cfbs: string;
  cfim: string;
  cfna: string;
  cfor: string;
  cli: string;
  cliTransFwd: boolean;
  description: string;
  extension: string;
  idName: string;
  identityId: number;
  order: number;
  outboundAnonymousCli: boolean;
  recording: null;
  ringtime: number;
  self: string;
  voicemail: null;
  voicemailDetection: boolean;
}

export interface CompassDPSwitch {
  currentSetting: number;
  name: string;
  owner: number;
  resourceId: number;
  self: string;
  settings: Array<{ number: number; name: string }>;
  shortcode: number;
  theType: string;
}

export enum CallForwardOption {
  notSet = "notSet",
  customNumber = "customNumber",
  voicemail = "voicemail"
}

export enum CallForwardType {
  always = "cfim",
  busy = "cfbs",
  noAnswer = "cfna",
  unavailable = "cfor"
}

export interface IUserPreferences {
  readonly defaultHomeList: NavigationHomeList;
  readonly viewMode: ViewModeType;
  readonly applyToQueues: ApplyToQueues;
}

export interface IPreferencesState {
  user: IUserPreferences;
  identity: CompassUserIdentity | null;
  dpSwitches: CompassDPSwitch[];
  compassPreferencesIsLoading?: boolean;
  compassPreferencesUpdateInProgress?: boolean;
  compassPreferencesError?: any;
  lastUpdateState?: {
    action: string;
    error?: any;
    finished: boolean;
  };
}

const USER_PREFERENCES_STORAGE_KEY = "userPreferences";

const initialUserPreferences: IUserPreferences = {
  defaultHomeList: NavigationHomeList.queues,
  viewMode: ViewModeType.comfortable,
  applyToQueues: ApplyToQueues.onlyDirectCalls
};

const initialState: IPreferencesState = {
  /* NOTE: user preferences should always keeps initial
     preferences if user not logged in
     or didn't change default preferences yet,
     to prevent weird use cases around the app */
  user: initialUserPreferences,
  compassPreferencesIsLoading: false,
  compassPreferencesError: undefined,
  identity: null,
  dpSwitches: []
};

export const loadUserPreferences = createAsyncThunk<
  IUserPreferences | null,
  void,
  { state: IRootState }
>("preferences/loadUserPreferences", async () => {
  return await userStorage.getItem<IUserPreferences>(
    USER_PREFERENCES_STORAGE_KEY
  );
});

export const updateUserPreferences = createAsyncThunk<
  IUserPreferences,
  Partial<IUserPreferences>,
  { state: IRootState }
>("preferences/updateUserPreferences", async (preferences, { getState }) => {
  const updatedPreferences: IUserPreferences = {
    ...getState().preferences.user,
    ...preferences
  };
  await userStorage.setItem(USER_PREFERENCES_STORAGE_KEY, updatedPreferences);
  return updatedPreferences;
});

export const loadCompassPreferences = createAsyncThunk<
  {
    identity: CompassUserIdentity | null;
    dpSwitches: CompassDPSwitch[];
  },
  void,
  { state: IRootState }
>(
  "preferences/loadCompassPreferences",
  async (_, { getState, rejectWithValue }) => {
    const connection = getState().auth.connection;
    const user = getState().auth.user;
    const company = getState().auth.company;
    if (!connection || !user || !company) {
      return rejectWithValue("Not logged in");
    }
    const out: {
      identity: CompassUserIdentity | null;
      dpSwitches: CompassDPSwitch[];
    } = {
      identity: null,
      dpSwitches: []
    };
    try {
      const identities = await connection.rest.get(
        `user/${user.id}/identities`
      );
      if (Array.isArray(identities) && identities.length) {
        out.identity = identities[0];
      }
      const dpSwitches = await connection.rest.get(
        `company/${company.entityId}/dpSwitches`
      );
      if (Array.isArray(dpSwitches) && dpSwitches.length) {
        out.dpSwitches = dpSwitches;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
    return out;
  }
);

export const updateUserIdentity = createAsyncThunk<
  CompassUserIdentity | void,
  Partial<CompassUserIdentity>,
  { state: IRootState }
>(
  "preferences/updateIdentity",
  async (identityPreferencesToPatch, { getState, rejectWithValue }) => {
    const connection = getState().auth.connection;
    const identityPreferences = getState().preferences.identity;
    if (!connection || !identityPreferences) {
      return;
    }
    try {
      return await wrapApiError(
        connection.rest.patch(
          `identity/${identityPreferences.identityId}`,
          identityPreferencesToPatch
        )
      );
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateDPSwitchSetting = createAsyncThunk<
  { resourceId: number; currentSetting: number } | void,
  { resourceId: number; currentSetting: number },
  { state: IRootState }
>(
  "preferences/updateDPSwitchSetting",
  async ({ resourceId, currentSetting }, { getState, rejectWithValue }) => {
    const connection = getState().auth.connection;
    if (!connection) {
      return rejectWithValue("Not logged in");
    }
    try {
      await wrapApiError(
        connection.rest.patch(`dpSwitch/${resourceId}`, {
          currentSetting
        })
      );
    } catch (error) {
      return rejectWithValue(error);
    }
    return { resourceId, currentSetting };
  }
);

export const preferencesSlice = createSlice({
  name: "preferences",
  initialState,
  reducers: {
    reset(state) {
      Object.assign(state, initialState);
    }
  },
  extraReducers: builder => {
    builder.addCase(
      loadUserPreferences.fulfilled,
      (state, { payload: userPreferences }) => {
        if (!userPreferences) {
          return;
        }
        state.user = { ...initialUserPreferences, ...userPreferences };
      }
    );

    builder.addCase(loadCompassPreferences.pending, state => {
      state.compassPreferencesError = undefined;
      state.compassPreferencesIsLoading = true;
    });
    builder.addCase(loadCompassPreferences.fulfilled, (state, action) => {
      state.identity = action.payload.identity;
      state.dpSwitches = action.payload.dpSwitches;
      state.compassPreferencesIsLoading = false;
    });
    builder.addCase(loadCompassPreferences.rejected, (state, action) => {
      state.compassPreferencesError = action.error;
      state.compassPreferencesIsLoading = false;
    });

    builder.addCase(updateUserPreferences.pending, state => {
      state.lastUpdateState = {
        action: updateUserPreferences.name,
        finished: false
      };
    });
    builder.addCase(updateUserPreferences.fulfilled, (state, action) => {
      if (
        state.lastUpdateState &&
        state.lastUpdateState.action === updateUserPreferences.name
      ) {
        state.lastUpdateState = {
          ...state.lastUpdateState,
          finished: true
        };
      }
      state.user = action.payload;
    });
    builder.addCase(updateUserPreferences.rejected, (state, payload) => {
      if (
        state.lastUpdateState &&
        state.lastUpdateState.action === updateUserPreferences.name
      ) {
        state.lastUpdateState = {
          ...state.lastUpdateState,
          finished: true,
          error: payload.error
        };
      }
    });

    builder.addCase(updateUserIdentity.pending, state => {
      state.lastUpdateState = {
        action: updateUserIdentity.name,
        finished: false
      };
    });
    builder.addCase(
      updateUserIdentity.fulfilled,
      (state, { payload: updatedIdentity }) => {
        if (
          state.lastUpdateState &&
          state.lastUpdateState.action === updateUserIdentity.name
        ) {
          state.lastUpdateState = {
            ...state.lastUpdateState,
            finished: true
          };
        }
        if (!updatedIdentity) {
          return;
        }
        state.identity = updatedIdentity;
      }
    );
    builder.addCase(updateUserIdentity.rejected, (state, payload) => {
      if (
        state.lastUpdateState &&
        state.lastUpdateState.action === updateUserIdentity.name
      ) {
        state.lastUpdateState = {
          ...state.lastUpdateState,
          finished: true,
          error: payload.error
        };
      }
    });

    builder.addCase(updateDPSwitchSetting.pending, state => {
      state.lastUpdateState = {
        action: updateDPSwitchSetting.name,
        finished: false
      };
    });
    builder.addCase(updateDPSwitchSetting.fulfilled, (state, { payload }) => {
      if (
        state.lastUpdateState &&
        state.lastUpdateState.action === updateDPSwitchSetting.name
      ) {
        state.lastUpdateState = {
          ...state.lastUpdateState,
          finished: true
        };
      }
      if (!payload) {
        return;
      }
      const dpSwitchIdx = state.dpSwitches.findIndex(
        ({ resourceId }) => resourceId === payload.resourceId
      );
      if (dpSwitchIdx === -1) {
        return;
      }
      state.dpSwitches[dpSwitchIdx].currentSetting = payload.currentSetting;
    });
    builder.addCase(updateDPSwitchSetting.rejected, (state, payload) => {
      if (
        state.lastUpdateState &&
        state.lastUpdateState.action === updateDPSwitchSetting.name
      ) {
        state.lastUpdateState = {
          ...state.lastUpdateState,
          finished: true,
          error: payload.error
        };
      }
    });
  }
});

export const { reset: resetPreferences } = preferencesSlice.actions;
export const selectUserPreferences = ({ preferences: { user } }: IRootState) =>
  user;
export const selectCompassPreferences = ({
  preferences: {
    identity,
    dpSwitches,
    compassPreferencesIsLoading: isLoading,
    compassPreferencesError: error
  }
}: IRootState) => ({
  identity,
  dpSwitches,
  isLoading,
  error
});
export const selectLastUpdateState = ({
  preferences: { lastUpdateState }
}: IRootState) => lastUpdateState;

export default preferencesSlice.reducer;
