import * as React from "react";
import "./ListRowInfoItem.scss";
import { PropsWithChildren } from "react";
interface IProps extends PropsWithChildren {
  isButton?: boolean;
  className?: string;
}

export const ListRowInfoItem: React.FC<IProps> = (props) => {
  const cssClasses = ["list__row-info-item"];
  if (props.isButton) {
    cssClasses.push("list__row-info-item--button");
  }
  if (props.className) {
    cssClasses.push(props.className);
  }

  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};
