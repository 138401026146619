import * as React from "react";
import "./SettingsSwitchesForm.scss";
import { useSelector } from "react-redux";
import {
  selectCompassPreferences,
  updateDPSwitchSetting
} from "src/store/preferences";
import { Loader } from "../UI/Loader/Loader";
import { useReduxDispatch } from "src/utils/hooks";
import { handleError, IError } from "src/utils/errorHandler";

const SettingsSwitchesForm: React.FC = () => {
  const dispatch = useReduxDispatch();
  const { dpSwitches, isLoading, error } = useSelector(
    selectCompassPreferences
  );
  const onSettingChange = async (
    resourceId: number,
    e: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const response = await dispatch(
      updateDPSwitchSetting({
        resourceId,
        currentSetting: parseInt(e.target.value, 10)
      })
    );
    if (response.type === updateDPSwitchSetting.rejected.type) {
      handleError(response.payload as IError);
    }
  };
  return (
    <div className="settings-forwards-form">
      {isLoading ? (
        <div className="br-content-loader">
          <Loader />
        </div>
      ) : error ? (
        <div>{/* TODO: check error message with @tena */}Error</div>
      ) : (
        dpSwitches.map(({ name, settings, currentSetting, resourceId }) => (
          <div key={resourceId} className="br-form-group">
            <div className="br-form-label">{name}</div>
            <div className="br-form-control">
              <select
                value={currentSetting}
                onChange={onSettingChange.bind(null, resourceId)}
              >
                {settings.map(({ name: optionName, number: optionNumber }) => (
                  <option key={optionNumber} value={optionNumber}>
                    {optionNumber}. {optionName}
                  </option>
                ))}
              </select>
            </div>
          </div>
        ))
      )}
    </div>
  );
};

export default SettingsSwitchesForm;
