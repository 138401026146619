import * as React from "react";
import "./ListHeading.scss";
import { PropsWithChildren } from "react";

export const ListHeading: React.FC<
  PropsWithChildren<{ className?: string }>
> = (props) => {
  const cssClasses = ["list__heading"];
  if (props.className) {
    cssClasses.push(props.className);
  }

  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};
