import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBackspace } from "@fortawesome/pro-solid-svg-icons/faBackspace";
import "./DialerInput.scss";

import { useEffect, useRef } from "react";
import { usePrevious } from "src/utils";

const DialerInput: React.FC<{
  value: string;
  changed: (value: string) => void;
  keypress?: (key: string) => void;
  submit: () => void;
  placeholder?: string;
  dark?: boolean;
  disable?: boolean;
  disablePaste?: boolean;
}> = ({
  value,
  changed,
  keypress,
  submit,
  placeholder,
  dark,
  disable,
  disablePaste,
}) => {
  const inputRef = useRef<HTMLDivElement | null>(null);

  const onInputKeypress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (disable) {
      e.preventDefault();
      e.stopPropagation();
      return;
    }
    // NOTE: allow digit, #, + and * characters only
    // and don't * and # in the beginning of number
    // (issue: https://gitlab.iperitydev.com/compass/bridge/-/issues/205)
    if (/^[\d+]$/.test(e.key) || (value && /^[*#]$/.test(e.key))) {
      if (keypress) {
        keypress(e.key);
      }
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    if (e.key === "Enter" && value) {
      submit();
    }
  };

  const onInputChange = (e: React.ChangeEvent<HTMLDivElement>) => {
    // NOTE: added or since textContent can be null
    changed(e.target.textContent || "");
  };

  const focusInput = () => {
    if (!inputRef.current) {
      return;
    }
    inputRef.current.focus();

    // NOTE: set cursor to the end of text,
    // browser sets it to the front by default
    // on editable elements
    const range = document.createRange();
    const sel = window.getSelection();
    range.selectNodeContents(inputRef.current);
    range.collapse(false);
    if (sel) {
      sel.removeAllRanges();
      sel.addRange(range);
    }
  };

  const onInputBackspace = () => {
    if (value) {
      changed(value.slice(0, value.length - 1));
    }
  };

  const onInputPaste = (e: React.ClipboardEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (disablePaste) {
      return;
    }
    const pastedText = e.clipboardData.getData("text/plain");
    const clearNumberParts = pastedText.match(/\d+|\+/g);
    if (!clearNumberParts) {
      return;
    }
    let clearNumber = clearNumberParts.join("");

    // https://gitlab.iperitydev.com/compass/bridge/-/merge_requests/543#note_74367
    // when the number is pasted in the dial pad, not adding any country
    // code unless the number is 10-digit 06-number. Does that make sense
    if (
      !value &&
      clearNumber.length === 10 &&
      clearNumber.indexOf("06") === 0
    ) {
      clearNumber = `+31${clearNumber.slice(1)}`;
    }
    changed(value + clearNumber);
  };

  useEffect(() => {
    // NOTE: focus input by default
    focusInput();
  }, []);

  const valuePrev = usePrevious(value);
  useEffect(() => {
    // NOTE: in case user changed value from outside
    // (e.g. dialpad), focus input on change
    if (value !== valuePrev) {
      focusInput();
    }
  }, [value, valuePrev]);

  const cssClasses = ["dialer-input"];
  if (dark) {
    cssClasses.push("dialer-input--dark");
  }
  if (!value) {
    cssClasses.push("dialer-input--empty");
  }

  return (
    <div className={cssClasses.join(" ")}>
      <div className="dialer-input__editable">
        <div
          className="dialer-input__editable-inner"
          contentEditable={true}
          data-placeholder={placeholder}
          ref={inputRef}
          onInput={onInputChange}
          onPaste={onInputPaste}
          onKeyPress={onInputKeypress}
          suppressContentEditableWarning={true}
        >
          {value}
        </div>
      </div>
      {value ? (
        <button className="dialer-input__clear-btn" onClick={onInputBackspace}>
          <FontAwesomeIcon icon={faBackspace} />
        </button>
      ) : null}
    </div>
  );
};

export default DialerInput;
