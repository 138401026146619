import * as React from "react";
import Button, { IButtonProps } from "src/components/UI/Button/Button";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { faCompress } from "@fortawesome/pro-regular-svg-icons/faCompress";
import { faExpandArrowsAlt } from "@fortawesome/pro-regular-svg-icons/faExpandArrowsAlt";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Animated, AnimationType } from "src/hoc/Animated/Animated";
import "./Modal.scss";
import { useEffect, useState } from "react";
import { usePrevious } from "src/utils/hooks";
interface IModalButton {
  text: string;
  color: IButtonProps["color"];
  clicked: () => void;
  fill?: IButtonProps["fill"];
  disabled?: boolean;
}
const Modal: React.FC<{
  isOpen: boolean;
  title?: string;
  buttons?: IModalButton[];
  buttonsPlacement?: "right" | "left";
  onRequestClose?: () => void;
  theme?: "default" | "white";
  showCloseBtn?: boolean;
  showMinimizeBtn?: boolean;
  disableOverlayClose?: boolean;
  className?: string;
  disableAnimation?: boolean;
  children?: React.ReactNode;
}> = ({
  isOpen,
  title,
  buttons,
  buttonsPlacement,
  onRequestClose,
  theme,
  showCloseBtn,
  showMinimizeBtn,
  disableOverlayClose,
  className,
  disableAnimation,
  children,
}) => {
  const [isHidden, setIsHidden] = useState(!isOpen);
  const [isMinimized, setIsMinimized] = useState(false);

  const isOpenPrev = usePrevious(isOpen);
  const cssClasses = ["modal"];
  const isMinimizedModal = isMinimized && isOpen;
  if (theme) {
    cssClasses.push(`modal--${theme}`);
  }
  if (className) {
    cssClasses.push(className);
  }
  if (isMinimizedModal) {
    cssClasses.push(`modal--minimized`);
  }

  const keyupHandler = (e: KeyboardEvent) => {
    if (
      disableOverlayClose ||
      !onRequestClose ||
      !isOpen ||
      e.key !== "Escape"
    ) {
      return;
    }
    onRequestClose();
  };

  const minimize = () => {
    setIsMinimized(true);
  };

  const expand = () => {
    setIsMinimized(false);
  };

  const getOverlayCssClasses = () => {
    const overlayCssClasses = ["modal__overlay"];
    if (isMinimized && isOpen) {
      overlayCssClasses.push(`modal__overlay--minimized`);
    }
    if (disableAnimation) {
      overlayCssClasses.push(
        isOpen ? "modal__overlay--shown" : "modal__overlay--hidden"
      );
    } else {
      if (isOpen) {
        overlayCssClasses.push(
          isHidden ? "modal__overlay--will-show" : "modal__overlay--shown"
        );
      } else {
        overlayCssClasses.push(
          isHidden ? "modal__overlay--hidden" : "modal__overlay--will-hide"
        );
      }
    }
    return overlayCssClasses.join(" ");
  };

  const onContentShow = () => {
    setIsHidden(false);
  };

  const onContentHide = () => {
    setIsHidden(true);
  };

  const onOverlayClick = (e: React.MouseEvent) => {
    if (
      disableOverlayClose ||
      !(e.target as HTMLElement).classList.contains("modal__animated-wrap")
    ) {
      return;
    }
    if (onRequestClose) {
      onRequestClose();
    }
  };

  useEffect(() => {
    if (isOpenPrev === isOpen) {
      return;
    }
    const $body = document.querySelector("body");
    if (!$body) {
      return;
    }
    let openedModals = $body.dataset.openedModals
      ? parseInt($body.dataset.openedModals, 10)
      : 0;
    openedModals = isOpen ? openedModals + 1 : openedModals - 1;
    $body.dataset.openedModals = String(openedModals);
    $body.dataset.hasOpenedModals = String(openedModals > 0);
  }, [isOpen, isOpenPrev]);

  useEffect(() => {
    document.addEventListener("keyup", keyupHandler);
    return () => {
      document.removeEventListener("keyup", keyupHandler);
    };
  });

  const $content =
    isOpen || !isHidden ? (
      <div className={cssClasses.join(" ")}>
        {showMinimizeBtn ? (
          <div className="modal__close-btn" onClick={minimize}>
            <FontAwesomeIcon icon={faCompress} />
          </div>
        ) : null}
        {showCloseBtn ? (
          <div
            className="modal__close-btn"
            onClick={onRequestClose ? onRequestClose.bind(null) : null}
          >
            <FontAwesomeIcon icon={faTimes} />
          </div>
        ) : null}
        {title ? <div className="modal__title">{title}</div> : null}
        {<div className="modal__content">{children}</div>}
        {buttons && buttons.length ? (
          <div
            className={`modal__buttons ${
              buttonsPlacement
                ? `modal__buttons--placement-${buttonsPlacement}`
                : ""
            }`}
          >
            {buttons.map((button, idx) => {
              return (
                <Button
                  key={idx}
                  color={button.color}
                  fill={button.fill}
                  disabled={button.disabled}
                  onClick={button.clicked}
                >
                  {button.text}
                </Button>
              );
            })}
          </div>
        ) : null}
      </div>
    ) : null;

  return (
    <>
      <div className={getOverlayCssClasses()} onClick={onOverlayClick}>
        <div className="modal__overlay-background" />
        {disableAnimation ? (
          $content
        ) : (
          <Animated
            isVisible={isOpen}
            animation={AnimationType.fadeUpDown}
            onShow={onContentShow}
            onHide={onContentHide}
            className="modal__animated-wrap"
          >
            {$content}
          </Animated>
        )}
      </div>
      {isMinimized ? (
        <div className="modal-minimized" onClick={expand}>
          <div className="modal-minimized__title">{title}</div>
          <div className="modal-minimized__expand-btn">
            <FontAwesomeIcon icon={faExpandArrowsAlt} />
          </div>
        </div>
      ) : null}
    </>
  );
};

export default Modal;
