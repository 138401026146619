import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronUp } from "@fortawesome/pro-solid-svg-icons/faChevronUp";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { faThumbtack as faThumbtackRegular } from "@fortawesome/pro-regular-svg-icons/faThumbtack";
import Button from "src/components/UI/Button/Button";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
} from "src/components/UI/List/";

import {
  loginQueue,
  logoutQueue,
  pinQueue,
  unpinQueue,
} from "src/store/actions/queues";
import "./QueueItem.scss";
import { handleError } from "src/utils/errorHandler";
import { Tooltip } from "../UI/Tooltip/Tooltip";
import { TrackAction, TrackCategory, trackEvent } from "src/utils/track";

import { User } from "compass.js";
import QueueDetailCounter, {
  QueueDetailCounterType,
} from "src/components/QueueDetailCounter/QueueDetailCounter";
import { BridgeColor } from "src/utils/consts";
import { useInfiniteComponentItem } from "src/utils/useInfiniteComponentItem";
import { ContextMenu, MenuItem, ContextMenuTrigger } from "react-contextmenu";
import { createSelector } from "reselect";

const TRACK_CATEGORY = TrackCategory.queueItem;
const selectQueue = (state: IRootState, id: string) => state.queues.items[id];
const selectOnline = (state: IRootState) => state.window.online;
const selectAuthUser = (state: IRootState) => state.auth.user as User;
const selectPinnedQueues = (state: IRootState) => state.queues.pinned;
const selectQueuesIsLoading = (state: IRootState) =>
  state.queues.actionsInProgress;
const appDataSelector = createSelector(
  [
    selectQueue,
    selectOnline,
    selectAuthUser,
    selectPinnedQueues,
    selectQueuesIsLoading,
  ],
  (queue, online, authUser, pinnedQueues, queuesIsLoading) => {
    if (!queue)
      return {
        online,
        isLoggedInUserInQueue: false,
        name: "",
        isPinned: false,
        queuesIsLoading: queuesIsLoading > 0,
      };

    const isLoggedInUserInQueue = queue.isUserInQueue(authUser.id);
    const isPinned = !!pinnedQueues.find((queueId) => queueId === queue.id);

    return {
      online,
      isLoggedInUserInQueue,
      name: queue.name,
      isPinned,
      queuesIsLoading: queuesIsLoading > 0,
    };
  }
);
const QueueItem: React.FC<{
  id: string;
  isActive?: boolean;
  onClick?: () => void;
  onDetailsToggle?: () => void;
  disableSkeleton?: boolean;
}> = ({ id, isActive, onClick, onDetailsToggle, disableSkeleton }) => {
  const { online, isLoggedInUserInQueue, name, isPinned, queuesIsLoading } =
    useSelector((state: IRootState) => appDataSelector(state, id));

  const dispatch = useDispatch();
  const elementId = `queue-row--${id}`;
  const onLoginQueue = (queueId: string) => dispatch<any>(loginQueue(queueId));
  const onLogoutQueue = (queueId: string) =>
    dispatch<any>(logoutQueue(queueId));
  const onPinQueue = (queueId: string) => dispatch<any>(pinQueue(queueId));
  const onUnpinQueue = (queueId: string) => dispatch<any>(unpinQueue(queueId));
  const { visible } = useInfiniteComponentItem({ elementId, disableSkeleton });

  const $getRowContent = () => {
    if (!visible) {
      return (
        <div className="queue-item__invisible">
          <div className="queue-item__invisible-name" />
          <div className="queue-item__invisible-buttons" />
        </div>
      );
    }
    return (
      <>
        <ListRowTitle>
          <Tooltip
            annotation={true}
            enabled={true}
            content={name}
            delay={[1000, 0]}
          >
            <span className="list__row-title-text">{name}</span>
          </Tooltip>
        </ListRowTitle>
        {
          <ListRowInfo>
            {
              <ListRowInfoItem className="queue-item__right-info">
                <Tooltip content={"Longest waiting time"}>
                  <span className="queue-item__duration">
                    <QueueDetailCounter
                      id={id}
                      type={QueueDetailCounterType.longestWaiting}
                    />
                  </span>
                </Tooltip>
              </ListRowInfoItem>
            }
            {
              <ListRowInfoItem className="queue-item__right-info">
                <Tooltip content={"People waiting"}>
                  <span>
                    {
                      <QueueDetailCounter
                        id={id}
                        type={QueueDetailCounterType.callers}
                      />
                    }
                  </span>
                </Tooltip>
              </ListRowInfoItem>
            }
            {
              <ListRowInfoItem isButton={true}>
                <Tooltip
                  placement="top"
                  content={
                    isPinned
                      ? "Unpin queue from the top"
                      : "Pin queue to the top"
                  }
                >
                  <Button
                    color={BridgeColor.gs800}
                    icononly={true}
                    fill={"clear"}
                    small={true}
                    onClick={togglePin}
                    disabled={queuesIsLoading}
                    track={[TRACK_CATEGORY, TrackAction.queueItemPinToggle]}
                  >
                    <FontAwesomeIcon
                      icon={isPinned ? faThumbtack : faThumbtackRegular}
                    />
                  </Button>
                </Tooltip>
              </ListRowInfoItem>
            }
            {
              <ListRowInfoItem isButton={true} className="br-screen-small">
                <Button
                  color={BridgeColor.gs800}
                  icononly={true}
                  fill={"clear"}
                  small={true}
                  onClick={toggleBtnClicked}
                >
                  <FontAwesomeIcon
                    icon={isActive ? faChevronUp : faChevronDown}
                  />
                </Button>
              </ListRowInfoItem>
            }
          </ListRowInfo>
        }
      </>
    );
  };

  const toggleBtnClicked = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();
    if (onDetailsToggle) {
      onDetailsToggle();
    }
  };

  const $getContextMenu = (): React.ReactNode => {
    return (
      // TODO: Check with Taras
      // @ts-ignore
      <ContextMenu id={`queue-${id}`}>
        {
          // TODO: Check with Taras
          // @ts-ignore
          <MenuItem
            disabled={!online || queuesIsLoading}
            onClick={isLoggedInUserInQueue ? contextLogout : contextLogin}
          >
            {isLoggedInUserInQueue ? "Leave queue" : "Join queue"}
          </MenuItem>
        }
        {
          // TODO: Check with Taras
          // @ts-ignore
          <MenuItem onClick={contextPinToggle}>
            {isPinned ? "Unpin" : "Pin"}
          </MenuItem>
        }
      </ContextMenu>
    );
  };

  const contextPinToggle = () => {
    trackEvent(TRACK_CATEGORY, TrackAction.queueItemContextPinToggle);
    togglePin();
  };

  const contextLogin = () => {
    trackEvent(TRACK_CATEGORY, TrackAction.queueItemContextLogin);
    onLoginQueue(id);
  };

  const contextLogout = () => {
    trackEvent(TRACK_CATEGORY, TrackAction.queueItemContextLogout);
    onLogoutQueue(id);
  };

  const togglePin = (e?: React.MouseEvent<HTMLButtonElement>) => {
    if (e) {
      e.stopPropagation();
    }
    (isPinned ? onUnpinQueue(id) : onPinQueue(id)).catch(handleError);
  };

  return (
    <>
      {
        // TODO: Check with Taras
        // @ts-ignore
        <ContextMenuTrigger id={elementId}>
          <ListRow
            active={isActive}
            onClick={onClick}
            disabled={!isLoggedInUserInQueue}
            id={`queue-row--${id}`}
          >
            {$getRowContent()}
          </ListRow>
        </ContextMenuTrigger>
      }
      {visible ? $getContextMenu() : null}
    </>
  );
};

export default QueueItem;
