import { IContact } from "src/store/reducers/contacts";
import { parsePhoneNumber } from "src/utils";
import { UserFeature, PhoneCapability } from "./../store/reducers/auth";
import {
  CallPoint,
  CallPointType,
  UserCallPoint,
  ExternalCallPoint,
  DialplanCallPoint,
  QueueCallPoint,
  Call,
  Queue,
  User,
  Side,
  CallPointState,
  OtherSide,
  ListenInCallPoint
} from "compass.js";
import { store } from "src/store";
import { canAuthenticatedUserUseFeature } from "./user";

export const getCallDescriptionForUser = (call: Call, user: User): string => {
  const userSide = getUserSide(call, user);
  if (userSide === null) {
    return "";
  }
  const callQueue = getCallQueue(call);
  const userCallPoint = call.getEndpoint(userSide);
  const otherCallPoint = call.getEndpoint(OtherSide(userSide));
  const callNumber = getCallPointNumber(otherCallPoint);
  const callUserName: null | string = getCallPointTitle(otherCallPoint);
  const descriptionPrefix =
    userCallPoint.state === CallPointState.ringing ? "Ringing" : "In Call";
  if (callQueue) {
    return `${descriptionPrefix} via ${callQueue.name}`;
  }
  const otherSideLabel = callUserName || callNumber;
  if (!otherSideLabel) {
    return descriptionPrefix;
  }
  const otherSideLabelPrefix =
    userCallPoint.state === CallPointState.ringing ? "from" : "with";
  return `${descriptionPrefix} ${otherSideLabelPrefix} ${otherSideLabel}`;
};

export const getCallPointTitle = (
  callPoint: CallPoint,
  defaultTitle: string = "Unknown"
): string => {
  let title: string | undefined;
  switch (callPoint.type) {
    case CallPointType.user:
      title = (callPoint as UserCallPoint).getUser().name;
      break;
    case CallPointType.external:
      const externalCallPoint = callPoint as ExternalCallPoint;
      const basicTitle = externalCallPoint.name || defaultTitle;
      const cleanCallPointNumber = parsePhoneNumber(
        externalCallPoint.number
      ).replace(/\D/g, "");

      // TODO: don't use store here? Possible speed problem
      const compassNumbersMap = store.getState().contacts
        .compassItemsNumbersMap;
      const addressBookNumbersMap = store.getState().contacts
        .addressBookItemsNumbersMap;
      const contactId: IContact["id"] =
        compassNumbersMap[cleanCallPointNumber] ||
        addressBookNumbersMap[cleanCallPointNumber];

      if (!contactId) {
        return basicTitle;
      }
      const contact =
        store.getState().contacts.compassItems[contactId] ||
        store.getState().contacts.addressBookItems[contactId];
      if (!contact) {
        return basicTitle;
      }
      return contact.name;
    case CallPointType.dialplan:
      title = (callPoint as DialplanCallPoint).description;
      break;
    case CallPointType.queue:
      title = (callPoint as QueueCallPoint).queueName;
      break;
    case CallPointType.listenIn:
      const listenToCallId = (callPoint as ListenInCallPoint).listenedToCallId;
      const listenToCall = callPoint.domain.calls[listenToCallId];
      if (listenToCall) {
        // NOTE: get number of caller
        title = getCallPointTitle(listenToCall.source, defaultTitle);
      }
      break;
  }
  return title || defaultTitle;
};

export const getCallPointNumber = (callPoint: CallPoint): string => {
  let callPointNumber: string = "";
  switch (callPoint.type) {
    case CallPointType.user: {
      // TODO: what if no extensions?
      const user = (callPoint as UserCallPoint).getUser();
      if (user.extensions.length > 0) {
        callPointNumber = (callPoint as UserCallPoint).getUser().extensions[0];
      }
      break;
    }
    case CallPointType.external:
      callPointNumber = (callPoint as ExternalCallPoint).number;
      break;
    case CallPointType.listenIn:
      const listenToCallId = (callPoint as ListenInCallPoint).listenedToCallId;
      const listenToCall = callPoint.domain.calls[listenToCallId];
      if (listenToCall) {
        // NOTE: get number of caller
        callPointNumber = getCallPointNumber(listenToCall.source);
      }
      break;
    case CallPointType.queue:
    case CallPointType.dialplan:
    case CallPointType.unknown:
      // TODO: get number?
      break;
  }
  return callPointNumber;
};

/**
 * Find queue for a call, if the call is related to a queue.
 *
 * This works for a call to a queue, or a call from the queue
 * (when the user is a queue agent).
 *
 * @param call
 */
export const getCallQueue = (call: Call): Queue | undefined => {
  if (call.destination instanceof QueueCallPoint) {
    // call is to a queue
    return call.destination.getQueue();
  } else if (call.source instanceof QueueCallPoint) {
    // after transfers, the queue is now on the source side.
    return call.source.getQueue();
  } else if (call.parentCall) {
    // call is originated by the queue? in that case,
    // parent.destination points to the queue.
    return getCallQueue(call.parentCall);
  }
  const callMetadata = store.getState().calls.allCallsMetadata[call.id];
  if (callMetadata) {
    return callMetadata.queue;
  }
  return undefined;
};

export const shouldAutoAnswerCall = (call: Call) => {
  // NOTE: check if call is incoming dialler call
  // and if user have ability to answer it
  const loggedInPhone = store.getState().auth.phone;
  if (
    canAuthenticatedUserUseFeature(UserFeature.callcontrol) &&
    loggedInPhone &&
    loggedInPhone.capabilities.includes(PhoneCapability.answer)
  ) {
    return (
      (call.source.type === CallPointType.dialplan &&
        (call.source as DialplanCallPoint).description === "dialler" &&
        call.destination.state === CallPointState.ringing) ||
      (call.source instanceof ListenInCallPoint &&
        call.destination instanceof UserCallPoint &&
        call.destination.state === CallPointState.ringing)
    );
  }
  return false;
};

export const shouldShowCallForUser = (call: Call, user: User) => {
  // NOTE: #204 - don't show connecting calls
  if (isUserSideConnecting(call, user)) {
    return false;
  }
  // NOTE: If we can auto-answer it, don't show the dialler call
  if (shouldAutoAnswerCall(call)) {
    return false;
  }

  const userSide = getUserSide(call, user);
  if (userSide === null) {
    return false;
  }
  const otherCallPoint = call.getEndpoint(OtherSide(userSide));

  // NOTE: #205, don't show star-code-calls, dialler calls & queue log-in/out calls
  if (otherCallPoint.type === CallPointType.dialplan) {
    const dialplanOtherCallPoint = otherCallPoint as DialplanCallPoint;
    if (
      dialplanOtherCallPoint.exten &&
      dialplanOtherCallPoint.exten.indexOf("*") === 0
    ) {
      return false;
    } else if (dialplanOtherCallPoint.description === "dialler") {
      return false;
    }
  }

  // NOTE:
  // In case a user calls a queue, we will have two calls:
  // - parent call: user => queue
  // - child call: user => agent
  // We only show the parent call. See #559.
  if (
    call.parentCall &&
    call.parentCall.source.type === CallPointType.user &&
    (call.parentCall.source as UserCallPoint).userId === user.id &&
    call.parentCall.destination.type === CallPointType.queue
  ) {
    return false;
  }
  return true;
};

export const isUserSideConnecting = (call: Call, user: User) => {
  const selfSide = getUserSide(call, user);
  if (selfSide === null) {
    return false;
  }
  return call.getEndpoint(selfSide).state === CallPointState.connecting;
};

export const getUserSide = (call: Call, user: User): Side | null => {
  if (
    call.source.type === CallPointType.user &&
    (call.source as UserCallPoint).userId === user.id
  ) {
    return Side.source;
  } else if (
    call.destination.type === CallPointType.user &&
    (call.destination as UserCallPoint).userId === user.id
  ) {
    return Side.destination;
  }
  return null;
};

export const getUserCallDuration = (call: Call, user: User): number => {
  const side = getUserSide(call, user);
  if (side === null) {
    console.warn(
      `WARNING: call#getUserCallDuration call "${
        call.id
      }" didn't have a side with user "${user.id}", returns 0`
    );
    return 0;
  }
  const endpoint = call.getEndpoint(side);
  // NOTE: Don't check callpoint state; workaround for Compass issue #3358
  return endpoint.getAnsweredDuration() || endpoint.getDuration();
};

export const containsCallById = (set: Call[], id: string) => {
  return set.map(item => item.id).includes(id);
};

export const callPointMatchesNumber = (
  callPoint: CallPoint,
  callNumber: string | number
) => {
  return (
    (callPoint.type === CallPointType.external &&
      (callPoint as ExternalCallPoint).number === String(callNumber)) ||
    (callPoint.type === CallPointType.user &&
      (callPoint as UserCallPoint)
        .getUser()
        .extensions.includes(String(callNumber)))
  );
};
