import * as React from "react";
import "./Settings.scss";
import Navbar from "src/components/Navbar/Navbar";
import Button from "src/components/UI/Button/Button";
import SettingsViewForm from "src/components/SettingsViewForm/SettingsViewForm";
import SettingsContactsForm from "src/components/SettingsContactsForm/SettingsContactsForm";
import SettingsForwardsForm from "src/components/SettingsForwardsForm/SettingsForwardsForm";
import SettingsSwitchesForm from "src/components/SettingsSwitchesForm/SettingsSwitchesForm";
import { useDispatch, useSelector } from "react-redux";
import { navigationSet } from "src/store/actions/navigation";
import {
  NavigationPage,
  NavigationHomeList,
  selectNavigationParams,
} from "src/store/reducers/navigation";
import {
  selectLastUpdateState,
  selectCompassPreferences,
} from "src/store/preferences";
import { BridgeColor } from "src/utils/consts";

enum SettingsSubPage {
  view = "view",
  personalForwards = "personalForwards",
  callSwitches = "callSwitches",
  contacts = "contacts",
}

const subPages = [
  {
    name: "View settings",
    page: SettingsSubPage.view,
  },
  {
    name: "Personal forwards",
    page: SettingsSubPage.personalForwards,
  },
  {
    name: "Contacts",
    page: SettingsSubPage.contacts,
  },
  // https://gitlab.iperitydev.com/compass/bridge/-/issues/825
  /*
  {
    name: "Call switches",
    page: SettingsSubPage.callSwitches
  }*/
];

const Settings: React.FC = () => {
  const dispatch = useDispatch();
  const navigationParams = useSelector(selectNavigationParams);
  const [activeSubPage, setActiveSubPage] = React.useState<SettingsSubPage>(
    navigationParams && navigationParams.highlightAlwaysForwarding
      ? SettingsSubPage.personalForwards
      : SettingsSubPage.view
  );
  const lastUpdateState = useSelector(selectLastUpdateState);
  const { dpSwitches, identity } = useSelector(selectCompassPreferences);
  const [showSavedLabel, setShowSavedLabel] = React.useState<boolean>(false);
  React.useEffect(() => {
    if (!lastUpdateState || !lastUpdateState.finished) {
      return;
    }
    setShowSavedLabel(true);
    const showSavedTimeout = setTimeout(() => {
      setShowSavedLabel(false);
    }, 2000);
    return () => {
      if (showSavedTimeout) {
        clearTimeout(showSavedTimeout);
      }
    };
  }, [lastUpdateState]);
  const onMenuItemClick = (page: SettingsSubPage) => {
    setActiveSubPage(page);
  };
  let $form: React.ReactNode;
  switch (activeSubPage) {
    case SettingsSubPage.view:
      $form = <SettingsViewForm />;
      break;
    case SettingsSubPage.personalForwards:
      $form = <SettingsForwardsForm />;
      break;
    case SettingsSubPage.callSwitches:
      $form = <SettingsSwitchesForm />;
      break;
    case SettingsSubPage.contacts:
      $form = <SettingsContactsForm />;
      break;
  }
  const onDoneClick = () => {
    dispatch(
      navigationSet(NavigationPage.home, {
        list: NavigationHomeList.contacts,
        dialerActive: false,
        detailsOpened: false,
      })
    );
  };
  // NOTE: hide unavailable settings subpages
  const invisibleSubPages: SettingsSubPage[] = [];
  if (!dpSwitches || !dpSwitches.length) {
    invisibleSubPages.push(SettingsSubPage.callSwitches);
  }
  if (!identity) {
    invisibleSubPages.push(SettingsSubPage.personalForwards);
  }
  let visibleSubPages = [...subPages];
  if (invisibleSubPages.length) {
    visibleSubPages = visibleSubPages.filter(
      ({ page }) => !invisibleSubPages.includes(page)
    );
  }
  return (
    <>
      <Navbar />
      <div className="settings">
        <div className="settings__navbar">
          <div className="settings__navbar-title">
            Bridge :: <span>Settings</span>
          </div>
          <div className="settings__navbar-buttons">
            {showSavedLabel ? (
              <div className="settings__navbar-saved-msg">Changes saved</div>
            ) : null}
          </div>
        </div>
        <div className="settings__content">
          <div className="settings__menu">
            {visibleSubPages.map(({ name, page }) => (
              <div
                key={page}
                onClick={onMenuItemClick.bind(null, page)}
                className={`settings__menu-item ${
                  page === activeSubPage ? "settings__menu-item--active" : ""
                }`}
              >
                {name}
              </div>
            ))}
          </div>
          <div className="settings__sub-page">
            {$form}
            <div className="settings__navbar-button-wrap">
              <Button
                color={BridgeColor.prim500}
                onClick={onDoneClick}
                className="button-done"
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Settings;
