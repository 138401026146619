import { ThunkDispatch } from "redux-thunk";
import { IRootState } from "src/store/reducers";
import { Action } from "redux";
import { useDispatch } from "react-redux";
import { useCallback, useEffect, useRef, useState } from "react";

export type ReduxDispatch = ThunkDispatch<IRootState, any, Action>;
export function useReduxDispatch(): ReduxDispatch {
  return useDispatch<ReduxDispatch>();
}

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

const createNewObject = (): Record<string, never> => ({});

export const useForceUpdate = () => {
  const [, setValue] = useState<Record<string, never>>(createNewObject);
  return useCallback((): void => {
    setValue(createNewObject());
  }, []);
};
