import * as React from "react";
import "./Notification.scss";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons/faExclamationTriangle";
import { faExclamationCircle } from "@fortawesome/pro-solid-svg-icons/faExclamationCircle";
import { faCheck } from "@fortawesome/pro-solid-svg-icons/faCheck";
import { useEffect, useRef } from "react";

const Notification: React.FC<{
  message: string;
  level: "success" | "info" | "danger";
  uid: string;
  dismissable?: boolean;
  autoDismiss?: number;
  button?: {
    text: string;
    onClick: (e?: React.MouseEvent<HTMLDivElement>) => void;
  };
  onDismiss: () => void;
}> = ({ onDismiss, message, level, uid, dismissable, autoDismiss, button }) => {
  let dismissTimeoutRef = useRef<NodeJS.Timer | null>(null);
  let dismissTimeout = dismissTimeoutRef.current;

  if (autoDismiss && !dismissTimeout) {
    dismissTimeout = setTimeout(() => {
      dismissTimeout = null;
      onDismiss();
    }, autoDismiss);
  }
  let icon: IconDefinition | null = null;
  switch (level) {
    case "danger":
      icon = faExclamationCircle;
      break;
    case "info":
      icon = faExclamationTriangle;
      break;
    case "success":
      icon = faCheck;
      break;
  }

  useEffect(() => {
    return () => {
      if (dismissTimeout) {
        clearTimeout(dismissTimeout);
      }
    };
  });

  return (
    <div className={`notification notification--${level}`}>
      <div className="notification__content">
        {icon ? (
          <div className="notification__content-icon">
            <FontAwesomeIcon icon={icon} />
          </div>
        ) : null}
        <div className="notification__content-message">{message}</div>
        {button ? (
          <div className="notification__content-btn" onClick={button.onClick}>
            {button.text}
          </div>
        ) : null}
      </div>
      {dismissable ? (
        <div className="notification__close">
          <button className="notification__close-btn" onClick={onDismiss}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
      ) : null}
    </div>
  );
};

export default Notification;
