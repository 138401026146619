const SECOND = 1;
const MINUTE = SECOND * 60;
const HOUR = MINUTE * 60;

/**
 * Convert duration in seconds to human readable string
 * @param duration Duration in seconds
 */
export const humanizeDuration = (duration: number) => {
  const hoursRest: number = duration % HOUR;
  const minutesRest: number = hoursRest % MINUTE;
  const hours = String(Math.floor(duration / HOUR) || "");
  const minutes = String(Math.floor(hoursRest / MINUTE));
  const seconds = String(Math.floor(minutesRest / SECOND));
  return `${hours.length ? hours + ":" : ""}${
    minutes.length === 1 ? "0" + minutes : minutes
  }:${seconds.length === 1 ? "0" + seconds : seconds}`;
};

/**
 * Convert duration in seconds to approx. human readable string
 * @param duration Duration in seconds
 */
export const humanizeApproxDuration = (duration: number) => {
  if (duration === 0) {
    return "0 min";
  }
  const hoursRest: number = duration % HOUR;
  const hours = Math.floor(duration / HOUR);
  const minutes = Math.floor(hoursRest / MINUTE);
  if (!minutes && !hours) {
    return "< 1 min";
  } else if (hours) {
    return `${hours} h ${minutes} min`;
  }
  return `${minutes} min`;
};
