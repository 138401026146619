import { generateUID } from "src/utils/index";
import * as actionTypes from "./actionTypes";
import { INotification } from "src/store/reducers/notifications";

export const notificationShow = (
  notification:
    | INotification
    | Pick<INotification, "message" | "autoDismiss" | "dismissable" | "level">
): { type: string; payload: INotification } => {
  return {
    type: actionTypes.NOTIFICATIONS_SHOW,
    payload: {
      uid: "uid" in notification ? notification.uid : generateUID(),
      ...notification
    }
  };
};

export const notificationDismiss = (
  uid: string
): { type: string; payload: string } => {
  return {
    type: actionTypes.NOTIFICATIONS_DISMISS,
    payload: uid
  };
};

export const notificationsDismissAll = (): {
  type: string;
} => {
  return {
    type: actionTypes.NOTIFICATIONS_DISMISS_ALL
  };
};
