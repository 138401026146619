import React, { useState, useEffect, useRef, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "src/store/reducers";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import { faUserFriends } from "@fortawesome/pro-solid-svg-icons/faUserFriends";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import Button from "src/components/UI/Button/Button";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import "./ContactsList.scss";
import {
  homeToggleDialer,
  homeChangeList,
  homeShowDetails,
  homeHideDetails,
} from "src/store/actions/navigation";
import {
  IHomePageParams,
  NavigationHomeList,
} from "src/store/reducers/navigation";
import SearchInput from "src/components/UI/SearchInput/SearchInput";
import ContactItem from "src/components/ContactItem/ContactItem";
import FlipMove from "react-flip-move";
import ContactDetails from "src/components/ContactDetails/ContactDetails";
import { WindowSizeType } from "src/store/reducers/window";
import { ViewModeType } from "src/store/preferences";
import { IContact } from "src/store/reducers/contacts";
import { User } from "compass.js";
import { filterContacts, sortContacts } from "src/utils/contact";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { trackEvent, TrackCategory, TrackAction } from "src/utils/track";
import * as Infinite from "react-infinite";
import { Loader } from "../UI/Loader/Loader";
import {
  NORMAL_LIST_ITEM_HEIGHT,
  COMPACT_LIST_ITEM_HEIGHT,
  INFINITE_LIST_BREAKPOINT,
  BridgeColor,
} from "src/utils/consts";
import { useForceUpdate } from "src/utils/hooks";
import { useIsSignedIn } from "@microsoft/mgt-react";
import Segment, { ISegmentProps } from "../UI/Segment/Segment";
import { createSelector } from "reselect";
enum OpenedTab {
  contactsAll = "contactsAll",
  contactsCompass = "contactsCompass",
  contactsMs = "contactsMs",
}
const TRACK_CATEGORY = TrackCategory.contactsList;
const appDataSelector = createSelector(
  (state: IRootState) => state.navigation.params as IHomePageParams,
  (state: IRootState) => state.contacts.compassItems,
  (state: IRootState) => state.contacts.addressBookItems,
  (state: IRootState) => (state.auth.user as User)?.id,
  (state: IRootState) => state.contacts.pinned,
  (state: IRootState) => state.window.sizeType,
  (state: IRootState) => state.contacts.addressBookContactsIsLoading,
  (state: IRootState) => state.auth.onboardingMode,
  (state: IRootState) =>
    (state.navigation.params as IHomePageParams).detailsOpened &&
    (state.navigation.params as IHomePageParams).detailsId?.toString(),
  (state: IRootState) => state.navigation.params as IHomePageParams,
  (state: IRootState) => state.preferences.user.defaultHomeList,
  (state: IRootState) => state.preferences.user.viewMode,
  (state: IRootState) =>
    (state.navigation.params as IHomePageParams).callIdForDtmf,
  (
    navigationParams,
    compassContactsItems,
    addressBookContactsItems,
    userId,
    pinnedContactItems,
    windowSizeType,
    addressBookContactsIsLoading,
    onboardingMode,
    openedDetails,
    isDialerActive,
    defaultHomeList,
    viewMode,
    callIdForDtmf
  ) => ({
    compassContactsItems,
    addressBookContactsItems,
    windowSizeType,
    addressBookContactsIsLoading,
    onboardingMode,
    openedDetails,
    isDialerActive,
    defaultHomeList,
    viewMode,
    callIdForDtmf,
    pinnedContactItems,
    userId,
    navigationParams,
  })
);

const ContactsList: React.FC = () => {
  const {
    compassContactsItems,
    addressBookContactsItems,
    windowSizeType,
    addressBookContactsIsLoading,
    onboardingMode,
    openedDetails,
    isDialerActive,
    defaultHomeList,
    viewMode,
    callIdForDtmf,
    pinnedContactItems,
    userId,
  } = useSelector(appDataSelector);

  const dispatch = useDispatch();

  const [searchQuery, setSearchQuery] = useState<string>("");
  const listRef = useRef<HTMLDivElement | null>(null);
  const listWrapperRef = useRef<HTMLDivElement | null>(null);
  const activeItemWrapperRef = useRef<HTMLDivElement | null>(null);
  const lastScrollPos = useRef<number | null>(null);
  const [isSignedIn] = useIsSignedIn();
  const [openedTab, setOpenedTab] = useState(OpenedTab.contactsAll);
  let $activeItemWrapperRef = useRef<HTMLDivElement | null>();
  let $activeItemWrapper = $activeItemWrapperRef.current;
  const onNavigationShowDetails = (id: string) => dispatch(homeShowDetails(id));
  const forceUpdate = useForceUpdate();
  const didMountRef = useRef(false);

  const contacts = React.useMemo(() => {
    let contactsMap = {
      ...compassContactsItems,
      ...addressBookContactsItems,
    };

    switch (openedTab) {
      case OpenedTab.contactsAll:
        contactsMap = {
          ...addressBookContactsItems,
          ...compassContactsItems,
        };
        break;
      case OpenedTab.contactsCompass:
        contactsMap = {
          ...compassContactsItems,
        };
        break;
      case OpenedTab.contactsMs:
        if (isSignedIn) {
          contactsMap = {
            ...addressBookContactsItems,
          };
        }
        break;
      default:
        break;
    }
    delete contactsMap[userId];
    let result = sortContacts(Object.values(contactsMap), pinnedContactItems);

    if (searchQuery) {
      result = filterContacts(result, searchQuery);
    }

    if (searchQuery) {
      result = filterContacts(result, searchQuery);
    }
    return result;
  }, [
    compassContactsItems,
    addressBookContactsItems,
    openedTab,
    userId,
    pinnedContactItems,
    searchQuery,
    isSignedIn,
  ]);
  const useInfiniteList = contacts.length > INFINITE_LIST_BREAKPOINT;
  const getItemHeight = useCallback(() => {
    return viewMode === ViewModeType.comfortable
      ? NORMAL_LIST_ITEM_HEIGHT
      : COMPACT_LIST_ITEM_HEIGHT;
  }, [viewMode]);
  const closeDetails = useCallback(() => {
    dispatch(homeHideDetails());
    setTimeout(() => {
      const $list = listRef.current as HTMLDivElement;
      $list.scrollTop = $list.scrollTop - 1;
      $list.scrollTop = $list.scrollTop + 1;
    }, 100);
  }, [dispatch]);

  const toggleDetails = useCallback(
    (id: IContact["id"]) => {
      if (openedDetails) {
        closeDetails();
      } else {
        dispatch(homeShowDetails(id));
        trackEvent(TRACK_CATEGORY, TrackAction.contactsListOpenDetails);
      }
    },
    [closeDetails, dispatch, openedDetails]
  );
  const onContactClick = useCallback(
    (id: IContact["id"]) => {
      if (onboardingMode) {
        return;
      }
      if (windowSizeType === WindowSizeType.mobile) {
        toggleDetails(id);
        return;
      }
      dispatch(homeShowDetails(id));
    },
    [dispatch, onboardingMode, toggleDetails, windowSizeType]
  );
  const onKeyDown = (contacts: IContact[]) => (e: React.KeyboardEvent) => {
    if (
      windowSizeType === WindowSizeType.mobile ||
      !["ArrowUp", "ArrowDown"].includes(e.key)
    ) {
      return;
    }
    e.preventDefault();
    const currentActiveContactIdx = contacts.findIndex((contact) =>
      isContactDetailsOpened(contact)
    );
    let nextActiveContactIdx: undefined | number;
    let $nextElement: null | Node = null;
    if (e.key === "ArrowUp") {
      // up arrow
      nextActiveContactIdx = Math.max(0, currentActiveContactIdx - 1);
      if ($activeItemWrapper && $activeItemWrapper.parentElement) {
        $nextElement = $activeItemWrapper.parentElement.previousSibling;
      }
    } else if (e.key === "ArrowDown") {
      // down arrow
      nextActiveContactIdx = Math.min(
        contacts.length - 1,
        currentActiveContactIdx + 1
      );
      if ($activeItemWrapper && $activeItemWrapper.parentElement) {
        $nextElement = $activeItemWrapper.parentElement.nextSibling;
      }
    }
    if (
      nextActiveContactIdx === undefined ||
      currentActiveContactIdx === nextActiveContactIdx ||
      !contacts[nextActiveContactIdx]
    ) {
      return;
    }
    onNavigationShowDetails(contacts[nextActiveContactIdx].id);
    if ($nextElement) {
      ($nextElement as HTMLDivElement).scrollIntoView({
        behavior: "smooth",
        block: "nearest",
      });
    }
  };
  const onSearchInputChanged = useCallback((value: string) => {
    setSearchQuery(value);
  }, []);

  const openQueuesList = useCallback(() => {
    dispatch(homeChangeList(NavigationHomeList.queues));
  }, [dispatch]);

  const isContactDetailsOpened = useCallback(
    (contact: IContact): boolean => {
      return contact.id === openedDetails;
    },
    [openedDetails]
  );
  const scrollDetailsUpForMobile = useCallback(() => {
    if (
      lastScrollPos.current &&
      (windowSizeType !== WindowSizeType.mobile || !openedDetails)
    ) {
      lastScrollPos.current = null;
    }

    if (
      !openedDetails ||
      windowSizeType !== WindowSizeType.mobile ||
      !listWrapperRef.current ||
      !listRef.current
    ) {
      return;
    }
    const activeItemIdx = contacts.findIndex(
      (item) => item.id === openedDetails
    );
    if (activeItemIdx < 0) {
      return;
    }
    const scrollPosition =
      activeItemIdx * getItemHeight() + 15; /* list padding */
    if (lastScrollPos.current === scrollPosition) {
      return;
    }
    if (activeItemWrapperRef.current) {
      activeItemWrapperRef.current.scrollIntoView({
        behavior:
          lastScrollPos.current && lastScrollPos.current !== scrollPosition
            ? "auto"
            : "smooth",
        block: "start",
      });
      lastScrollPos.current = scrollPosition;
    } else if (useInfiniteList) {
      const $infiniteList = listWrapperRef.current.querySelector(
        ".contacts-list-infinite"
      );
      if ($infiniteList) {
        $infiniteList.scrollTo(0, scrollPosition);
        lastScrollPos.current = scrollPosition;
      }
    }
  }, [contacts, getItemHeight, openedDetails, useInfiniteList, windowSizeType]);
  const segmentProps: ISegmentProps = React.useMemo(() => {
    const openTabHandler = (openedTab: OpenedTab) => {
      if (onboardingMode) {
        return;
      }
      setOpenedTab(openedTab);
    };
    return {
      onChange: (value: OpenedTab) => {
        openTabHandler(value);
      },
      value: openedTab,
      items: [
        {
          title: "All contacts",
          value: OpenedTab.contactsAll,
        },
        {
          title: "Colleagues",
          value: OpenedTab.contactsCompass,
        },
        ...(isSignedIn
          ? [
              {
                title: "Microsoft contacts",
                value: OpenedTab.contactsMs,
              },
            ]
          : []),
      ],
    };
  }, [openedTab, isSignedIn, onboardingMode]);

  const getContactsListItems = useCallback(
    (contacts: IContact[]): React.ReactNode => {
      const $items = contacts.map((contact) => {
        const isItemActive = openedDetails === contact.id;
        const wrapperCssClasses = ["contacts-list__item-wrapper"];
        if (isItemActive) {
          wrapperCssClasses.push("contacts-list__item-wrapper--active");
        }
        const isActive = isContactDetailsOpened(contact);
        return (
          <div key={contact.id} className={wrapperCssClasses.join(" ")}>
            <div ref={isItemActive ? activeItemWrapperRef : null}>
              <ContactItem
                id={contact.id}
                isActive={isActive}
                disableSkeleton={isActive}
                key={contact.id}
                onClick={onContactClick}
                showPinBtn={true}
                onDetailsToggle={toggleDetails}
              />
            </div>
            {isItemActive ? (
              <div className="contacts-list__details-wrap br-screen-small">
                <ContactDetails
                  id={contact.id}
                  hideHeader={true}
                  inline={true}
                />
              </div>
            ) : null}
          </div>
        );
      });
      if (!useInfiniteList) {
        return (
          <FlipMove
            enterAnimation="none"
            leaveAnimation="none"
            disableAllAnimations={!!openedDetails}
          >
            {$items}
          </FlipMove>
        );
      }
      if (!listRef.current) {
        return null;
      }
      return (
        // @ts-ignore
        <Infinite
          containerHeight={listRef.current.clientHeight}
          elementHeight={getItemHeight()}
          // @ts-ignore
          preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
          className="contacts-list-infinite"
        >
          {$items}
        </Infinite>
      );
    },
    [
      getItemHeight,
      isContactDetailsOpened,
      onContactClick,
      openedDetails,
      toggleDetails,
      useInfiniteList,
    ]
  );
  const contactsWrapCssClasses = ["contacts-list-wrap"];
  const currentlyOpenedContact = contacts.find((contact) =>
    isContactDetailsOpened(contact)
  );
  if (currentlyOpenedContact) {
    contactsWrapCssClasses.push("contacts-list-wrap--opened-details");
  }
  if (useInfiniteList) {
    contactsWrapCssClasses.push("contacts-list-wrap--infinite");
  }
  if (isSignedIn) {
    contactsWrapCssClasses.push("contacts-list-wrap--isSignedIn");
  }
  const containerHeaderCssClasses: string[] = ["container-header--no-border"];
  if (!!currentlyOpenedContact) {
    containerHeaderCssClasses.push("container-header--no-mobile-border");
  }
  useEffect(() => {
    if (didMountRef.current) {
      scrollDetailsUpForMobile();
    } else didMountRef.current = true;
  });

  useEffect(() => {
    if (useInfiniteList) {
      forceUpdate();
    }
  }, [forceUpdate, useInfiniteList]);

  // add forceUpdate to get proper list height after MS contacts are loaded
  useEffect(() => {
    if (isSignedIn) {
      requestAnimationFrame(() => {
        if (listRef.current) {
          forceUpdate();
        }
      });
    }
  }, [forceUpdate, isSignedIn, segmentProps]);

  return (
    <>
      <ContainerHeader
        title={"Contacts"}
        enableBackBtn={
          defaultHomeList !== NavigationHomeList.contacts && !onboardingMode
        }
        backBtnClicked={openQueuesList}
        className={
          containerHeaderCssClasses.length
            ? containerHeaderCssClasses.join(" ")
            : undefined
        }
        backBtnTrack={[TRACK_CATEGORY, TrackAction.contactsListGoBack]}
      >
        <div className="container-header-left">
          <SearchInput
            changed={onSearchInputChanged}
            value={searchQuery}
            tooltip={"Search contacts"}
          />
          {addressBookContactsIsLoading ? <Loader /> : null}
        </div>
        <div className="container-header-buttons">
          {onboardingMode ? (
            <Button
              small={true}
              icononly={true}
              onClick={openQueuesList}
              onboardingStep={OnboardingStepId.navigateQueues}
              color={BridgeColor.gs300}
              tooltip={"Queues"}
              track={[TRACK_CATEGORY, TrackAction.contactsListOpenQueues]}
            >
              <FontAwesomeIcon icon={faUserFriends} />
            </Button>
          ) : defaultHomeList === NavigationHomeList.contacts ? (
            <Button
              small={true}
              icononly={true}
              onClick={openQueuesList}
              onboardingStep={OnboardingStepId.navigateQueues}
              color={BridgeColor.gs300}
              tooltip={"Queues"}
              track={[TRACK_CATEGORY, TrackAction.contactsListOpenQueues]}
            >
              <FontAwesomeIcon icon={faUserFriends} />
            </Button>
          ) : null}
          <Button
            tooltip={"Dial pad"}
            small={true}
            icononly={true}
            onClick={() => dispatch(homeToggleDialer())}
            color={
              isDialerActive.dialerActive && !callIdForDtmf
                ? BridgeColor.gs800
                : BridgeColor.gs300
            }
            track={[TRACK_CATEGORY, TrackAction.contactsListOpenDialPad]}
          >
            <FontAwesomeIcon icon={faTh} />
          </Button>
        </div>
      </ContainerHeader>
      {isSignedIn && (
        <div className="list">
          <Segment {...segmentProps} />
        </div>
      )}
      <div className={contactsWrapCssClasses.join(" ")} ref={listWrapperRef}>
        <div
          className="contacts-list"
          ref={listRef}
          tabIndex={1}
          onKeyDown={onKeyDown(contacts)}
        >
          <div className="contacts-list__inner">
            <List>
              {contacts.length ? (
                getContactsListItems(contacts)
              ) : (
                <ListEmptyMsg>
                  {!contacts.length
                    ? "You currently have no contacts."
                    : "There is no contact with this number or name."}
                </ListEmptyMsg>
              )}
            </List>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactsList;
