import { IContact, ContactType } from "src/store/reducers/contacts";
import { parsePhoneNumber } from "src/utils";

interface OfficeContact {
  id: string;
  givenName?: string;
  surname?: string;
  middleName?: string;
  mobilePhone: string;
  businessPhones: string[];
  homePhones: string[];
  emailAddresses: { name: string; address: string }[];
  companyName?: string;
  personalNotes?: string;
}

export const syncOfficeContacts = (
  officeContacts: OfficeContact[]
): Promise<{ [key: string]: IContact }> => {
  return new Promise((resolve, reject) => {
    try {
      const abContacts: { [key: string]: IContact } = {};
      officeContacts.forEach((officeContact) => {
        const {
          id,
          givenName,
          surname,
          mobilePhone,
          businessPhones,
          homePhones,
          middleName,
          emailAddresses,
          companyName,
          personalNotes,
        } = officeContact;
        const phones: IContact["phones"] = [];
        const emails: IContact["emails"] = [];

        if (mobilePhone) {
          phones.push({
            label: "Mobile",
            value: parsePhoneNumber(mobilePhone),
          });
        }

        if (businessPhones && businessPhones.length > 0) {
          phones.push({
            label: "Business",
            value: parsePhoneNumber(businessPhones[0]),
          });
        }
        if (homePhones && homePhones.length > 0) {
          phones.push({
            label: "Home",
            value: parsePhoneNumber(homePhones[0]),
          });
        }

        if (emailAddresses) {
          emailAddresses.forEach((email) => {
            emails.push({
              label: "",
              value: email.address,
            });
          });
        }

        abContacts[id] = {
          id,
          name: [givenName, middleName, surname]
            .filter((item) => !!item)
            .join(" "),
          type: ContactType.addressBook,
          phones,
          emails,
          company: companyName,
          notes: personalNotes,
        };
      });
      resolve(abContacts);
    } catch (error) {
      reject(error);
    }
  });
};
