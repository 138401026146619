import * as React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { humanizeApproxDuration } from "src/utils/dateTime";
import {
  getQueueAvgWaitingTime,
  getQueueActiveMembers,
  getQueueLongestWaitingTime,
} from "src/utils/queue";
import { useEffect, useState } from "react";
export enum QueueDetailCounterType {
  avgWaiting = "awgWaiting",
  longestWaiting = "longestWaiting",
  agents = "agents",
  callers = "callers",
}
const QueueDetailCounter: React.FC<{
  id: string;
  type: QueueDetailCounterType;
}> = ({ id, type }) => {
  const queue = useSelector((state: IRootState) => state.queues.items[id]);

  const getValue = React.useCallback((): string | undefined => {
    switch (type) {
      case QueueDetailCounterType.agents:
        return getQueueActiveMembers(queue)
          .filter((item) => !item.pausedSince)
          .length.toString();
      case QueueDetailCounterType.callers:
        return queue.getCalls().length.toString();
      case QueueDetailCounterType.avgWaiting:
        return humanizeApproxDuration(getQueueAvgWaitingTime(queue));
      case QueueDetailCounterType.longestWaiting:
        return humanizeApproxDuration(getQueueLongestWaitingTime(queue));
      default:
        return "";
    }
  }, [queue, type]);

  const [value, setValue] = useState<string | undefined>(getValue());

  // Update the value every second
  useEffect(() => {
    const tickIntervalId = setInterval(() => {
      const newValue = getValue();
      if (newValue !== value) {
        setValue(newValue);
      }
    }, 1000);

    return () => {
      clearInterval(tickIntervalId);
    };
  }, [getValue, value]);

  return <>{value || null}</>;
};

export default QueueDetailCounter;
