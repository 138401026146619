import * as React from "react";
import "./PrivacyStatementModal.scss";

class IconInformationModal extends React.Component {
  public render() {
    return (
      <div className="privacy-statement-modal">
        <p>
          We take your privacy very seriously. Our Privacy Policy below covers
          how we collect, use, disclose, transfer and store your information.
          Please take a moment to get to know our practices.
        </p>
        <h2>Collection and use of data</h2>
        <p>
          In collecting data, we differentiate between Personally Identifiable
          Information and Non-Personally Identifiable Information, where:
        </p>
        <ul>
          <li>
            Personally Identifiable Information (PII) is data that can be used
            to uniquely identify or contact a specific individual.
          </li>
          <li>
            Non-Personally Identifiable Information (NPII) is data that cannot,
            on its own, be used to uniquely identify a specific individual.
          </li>
        </ul>
        <p>
          We make a point of collecting PII as little as possible, but to
          provide you with our services, in some cases it is necessary to do so.
          Providing the information we request is never mandatory, but if you
          choose not to do so, in many cases this means we will not be able to
          provide you with our products or services, or respond to your
          inquiries.
        </p>
        <p>
          <b>What Personally Identifiable Information we collect</b>
        </p>
        <ul>
          <li>
            For providing our services we collect a variety of information,
            including your email address, password, phone number, company
            information and a record of your communication with us.
          </li>
        </ul>
        <p>
          <b>What Non-Personally Identifiable Information we collect</b>
        </p>
        <ul>
          <li>
            We use Matomo for our online analytics, this only collects data that
            is not personally identifiable, such as parts of the IP address and
            country of origin.
          </li>
        </ul>
        <p>
          We may combine personal and non-personal information for certain
          purposes; this data will then be treated as personal information for
          as long as it remains combined. Conversely, aggregated information,
          i.e. data that might have originated from personal information, but
          that has been processed in such a way as to not allow personal
          identification, is treated as non-personal information.
        </p>

        <h3>How we use and protect your personal information</h3>
        <p>
          All personal data we collect and process in accordance with the
          relevant data protection regulations, in particular the General Data
          Protection Regulation (GDPR). This means that we may process your
          personal information for only the purposes described in this Privacy
          Policy with your consent. Disclosing this information is strictly
          prohibited, but in the event of a legal investigation it might be
          required. We take appropriate security measures to protect against
          unauthorised access to the data. These include internal reviews of our
          data collection, storage and processing practices and security
          measures, including appropriate encryption and physical security
          measures to guard against unauthorised access to systems where we
          store personal data. Within Talksome, we restrict access to personal
          information to only those employees who need to know that information
          in order to deploy and maintain our services. These individuals are
          bound by confidentiality agreements and may be subject to discipline,
          including termination and criminal prosecution, if they fail to meet
          these obligations.
        </p>

        <h2>Third parties</h2>
        <p>
          We work together with other companies who provide information
          processing services. We only share Personally Identifiable Information
          with these companies if you have agreed to the transfer, or if it is
          permitted by data protection law. The information we share is limited
          to the data necessary for the third parties to provide their services.
          These companies are obligated to protect your information in
          accordance with data protection law and provide the necessary
          safeguards if they are outside of the EU. The companies are bound by a
          processor agreement that exists between Talksome and them, and are not
          allowed to use the shared data for any other purpose.
        </p>

        <h2>Data storage</h2>
        <p>
          We have specific demands when storing data. When we can, we make sure
          all data we collect is stored on our own hosted servers. When it comes
          to data being stored through our tools, we are particular on where the
          data gets stored. We use Zendesk for our operational contact with
          customers, data that is collected is stored on their hosted servers in
          the US. When it comes to our own product, Compass and its accompanying
          modules, we store the data we collect at our own hosted servers that
          are located in The Netherlands.
        </p>
      </div>
    );
  }
}

export default IconInformationModal;
