import * as React from "react";
import "./ContactDetailsCalls.scss";
import { List, ListEmptyMsg } from "src/components/UI/List/";
import { IRootState } from "src/store/reducers";
import { useSelector } from "react-redux";
import "./ContactDetailsCalls.scss";
import { Call, User } from "compass.js";
import { ContactType } from "src/store/reducers/contacts";
import ContactDetailsCallItem from "../ContactDetailsCallItem/ContactDetailsCallItem";
import { shouldShowCallForUser } from "src/utils/call";
//import { usePrevious } from "src/utils/hooks";

const ContactDetailsCalls: React.FC<{
  contactId: string;
}> = ({ contactId }) => {
  const contactCallsIds = useSelector((state: IRootState) => {
    const contact =
      state.contacts.compassItems[contactId] ||
      state.contacts.addressBookItems[contactId];
    let contactCallsIds: Array<Call["id"]> = [];
    if (contact.type === ContactType.compass && contact.user) {
      contactCallsIds = contact.user
        .getCalls()
        .filter((item) => shouldShowCallForUser(item, contact.user as User))
        // NOTE: make sure call have metadata
        .filter((item) => !!state.calls.allCallsMetadata[item.id])
        .map((item) => item.id);
    }
    return contactCallsIds;
  });

  if (!contactCallsIds.length) {
    return (
      <List>
        <ListEmptyMsg>No active calls</ListEmptyMsg>
      </List>
    );
  }

  return (
    <List>
      {contactCallsIds.map((callId) => (
        <ContactDetailsCallItem
          key={callId}
          contactId={contactId}
          callId={callId}
        />
      ))}
    </List>
  );
};

export default ContactDetailsCalls;
