import * as React from "react";
import "./Radio.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-regular-svg-icons/faCircle";
import { faDotCircle } from "@fortawesome/pro-regular-svg-icons/faDotCircle";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

export interface RadioOption {
  value: string;
  name: string;
  label?: string;
  labelIcon?: IconDefinition;
}

const Radio: React.FC<{
  options: RadioOption[];
  disabled?: boolean;
  value?: string;
  vertical?: boolean;
  onSelect: (value: string) => void;
}> = ({ options, value, onSelect, disabled, vertical }) => {
  return (
    <div className="br-radio" data-disabled={disabled} data-vertical={vertical}>
      {options.map(
        ({ value: optionValue, name: optionName, label, labelIcon }) => (
          <div
            key={optionValue}
            className={`br-radio__option ${
              value === optionValue ? `br-radio__option--active` : ""
            }`}
            onClick={!disabled ? onSelect.bind(null, optionValue) : undefined}
          >
            <div className="br-radio__option-icon">
              <FontAwesomeIcon
                icon={optionValue === value ? faDotCircle : faCircle}
              />
            </div>
            <div className="br-radio__option-name">{optionName}</div>
            {label || labelIcon ? (
              <div className="br-radio__option-label">
                <div className="br-radio__option-label-text">{label}</div>
                {labelIcon ? (
                  <div className="br-radio__option-label-icon">
                    <FontAwesomeIcon icon={labelIcon} />
                  </div>
                ) : null}
              </div>
            ) : null}
          </div>
        )
      )}
    </div>
  );
};

export default Radio;
