import * as React from "react";
import Button, { IButtonProps } from "src/components/UI/Button/Button";
import { faShare } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faPause } from "@fortawesome/pro-solid-svg-icons/faPause";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./CallItem.scss";
import {
  Call,
  CallState,
  Side,
  UserCallPoint,
  CallPointType,
  CallPointState,
  User,
  OtherSide,
  Queue,
} from "compass.js";
import { IRootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";

import { phoneAction, PhoneActionType } from "src/store/actions";
import { getUserSide, getCallPointTitle } from "src/utils/call";
import { handleError } from "src/utils/errorHandler";
import { setAsDesiredTransferCall } from "src/store/actions/calls";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import {
  NavigationHomeList,
  IHomePageParams,
  NavigationPage,
} from "src/store/reducers/navigation";
import {
  navigationSet,
  navigationUpdateParams,
} from "src/store/actions/navigation";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { TrackAction, TrackCategory } from "src/utils/track";
import { faDeaf } from "@fortawesome/pro-regular-svg-icons/faDeaf";
import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { faGlasses } from "@fortawesome/pro-regular-svg-icons/faGlasses";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import { BridgeColor } from "src/utils/consts";
import CallDetailCounter, {
  CallDetailCounterType,
} from "src/components/CallDetailCounter/CallDetailCounter";
import { createSelector } from "reselect";
const TRACK_CATEGORY = TrackCategory.callItem;

enum CallItemState {
  answered = "answered",
  attended_transfer = "attended_transfer",
  paused = "paused",
  default = "default",
}

const getCallMetadata = (state: IRootState, callId: Call["id"]) =>
  state.calls.callsMetadata[callId];
const getUser = (state: IRootState) => state.auth.user as User;
const getAllCallsMetadata = (state: IRootState, callMetadata: any) =>
  callMetadata?.listeningToCall?.id
    ? state.calls.allCallsMetadata[callMetadata.listeningToCall.id]
    : null;
const getPhoneCapabilities = (state: IRootState) =>
  state.auth.phone ? state.auth.phone.capabilities : [];
const getCallsIsLoading = (state: IRootState) =>
  state.calls.actionsInProgress > 0;
const getIsHighlighted = (state: IRootState, callId: Call["id"]) =>
  state.calls.highlightedCallIds.includes(callId);
const getOnline = (state: IRootState) => state.window.online;
const getDtmfDialerOpened = (state: IRootState, callId: Call["id"]) =>
  !!state.navigation.params &&
  state.navigation.params.dialerActive &&
  state.navigation.params.callIdForDtmf === callId;
const getDesiredTransferCallId = (state: IRootState) =>
  state.calls.desiredTransferCallId;

export const appDataSelector = createSelector(
  [
    getCallMetadata,
    getUser,
    getAllCallsMetadata,
    getPhoneCapabilities,
    getCallsIsLoading,
    getIsHighlighted,
    getOnline,
    getDtmfDialerOpened,
    getDesiredTransferCallId,
  ],
  (
    callMetadata,
    user,
    allCallsMetadata,
    phoneCapabilities,
    callsIsLoading,
    isHighlighted,
    online,
    dtmfDialerOpened,
    desiredTransferCallId
  ) => ({
    callMetadata,
    user,
    allCallsMetadata,
    phoneCapabilities,
    callsIsLoading,
    isHighlighted,
    online,
    dtmfDialerOpened,
    desiredTransferCallId,
  })
);
const CallItem: React.FC<{
  callId: Call["id"];
  hideTransferBtn?: boolean;
  hideDtmfBtn?: boolean;
}> = ({ callId, hideTransferBtn, hideDtmfBtn }) => {
  const {
    callMetadata,
    user,
    allCallsMetadata,
    phoneCapabilities,
    callsIsLoading,
    isHighlighted,
    online,
    dtmfDialerOpened,
    desiredTransferCallId,
  } = useSelector((state: IRootState) => appDataSelector(state, callId));

  const dispatch = useDispatch();

  const listenedByCalls = callMetadata.listenedByCalls;
  const selfSide = getUserSide(callMetadata.call, user) as Side;
  const selfCallPoint = callMetadata.call.getEndpoint(selfSide);
  const otherCallPoint = callMetadata.call.getEndpoint(OtherSide(selfSide));

  if (selfSide === null) {
    throw new Error(
      `CallItem component supports only calls with authenticated user side`
    );
  }

  const { listenedCallTooltip, numberDisplay, otherCallPointUserId } =
    React.useMemo(() => {
      let otherCallPointUserId: User["id"] | undefined;
      if (otherCallPoint.type === CallPointType.user) {
        otherCallPointUserId = (otherCallPoint as UserCallPoint).getUser().id;
      }

      let numberDisplay = callMetadata.lastConnectedNumber;

      if (callMetadata.listeningToCall) {
        const listeningToCallMetadata = allCallsMetadata;

        numberDisplay = `calling with ${
          listeningToCallMetadata
            ? listeningToCallMetadata.destinationLastConnectedTitle ||
              listeningToCallMetadata.destinationLastConnectedNumber ||
              "unknown"
            : "unknown"
        }`;
      }

      let listenedCallTooltip: string | undefined;
      if (listenedByCalls.length) {
        listenedCallTooltip = `${listenedByCalls
          .map((item) => getCallPointTitle(item.destination))
          .join(", ")} ${
          listenedByCalls.length === 1 ? "is" : "are"
        } listening in on your call.`;
      }

      return { listenedCallTooltip, numberDisplay, otherCallPointUserId };
    }, [
      allCallsMetadata,
      callMetadata.lastConnectedNumber,
      callMetadata.listeningToCall,
      listenedByCalls,
      otherCallPoint,
    ]);

  const userId = user.id;
  const isListened = listenedByCalls.length > 0;
  const isListenIn = !!callMetadata.listeningToCall;

  const isEnded = callMetadata.isEnded;
  const lastConnectedTitle = callMetadata.lastConnectedTitle;
  const isAttendedTransferConsultationLeg =
    callMetadata.isAttendedTransferConsultationLeg;
  const isAttendedTransferMainLeg = callMetadata.isAttendedTransferMainLeg;
  const queueId = callMetadata.queue ? callMetadata.queue.id : undefined;
  const queueName = callMetadata.queue ? callMetadata.queue.name : undefined;
  const hasHoldCapability = phoneCapabilities.includes(PhoneCapability.hold);
  const hasUnHoldCapability = phoneCapabilities.includes(
    PhoneCapability.unhold
  );
  const hasAnswerCapability = phoneCapabilities.includes(
    PhoneCapability.answer
  );
  const callState = callMetadata.call.state;
  const hasParentCall = !!callMetadata.call.parentCall;
  const selfCallPointState = selfCallPoint.state;
  const otherCallPointState = otherCallPoint.state;

  const onPhoneAction = (callId: Call["id"], action: PhoneActionType) =>
    dispatch<any>(phoneAction(callId, action));
  const onSetAsDesiredTransferCall = (id: string) =>
    dispatch(setAsDesiredTransferCall(id));
  const onNavigationSet = (
    page: NavigationPage,
    params: Partial<IHomePageParams>
  ) => dispatch(navigationSet(page, params));
  const onNavigationUpdateParams = (params: Partial<IHomePageParams>) =>
    dispatch(navigationUpdateParams(params));

  const getElementClass = (): string => {
    const cssClasses = ["call-item", `call-item--${getCallState()}`];
    if (isHighlighted) {
      cssClasses.push("call-item--highlighted");
    }
    if (isListened) {
      cssClasses.push("call-item--listened");
    }
    return cssClasses.join(" ");
  };

  const getCallState = (): CallItemState => {
    if (callState === CallState.answered) {
      if (selfCallPointState === CallPointState.inactive) {
        return CallItemState.paused;
      }
      return CallItemState.answered;
    }
    return CallItemState.default;
  };

  const $getHeader = () => {
    const headerNameCssClasses = ["call-item__header-name"];
    let onUserNameClickHandler: (() => void) | undefined;
    if (otherCallPointUserId) {
      headerNameCssClasses.push("call-item__header-name--clickable");
      onUserNameClick = () => {
        if (otherCallPointUserId) {
          onUserNameClick(otherCallPointUserId);
        }
      };
    }
    return (
      <div className="call-item__header">
        <div
          className={headerNameCssClasses.join(" ")}
          onClick={onUserNameClickHandler}
        >
          {isListenIn ? (
            <Tooltip content={"Listening in on the call"} enabled={true}>
              <span>
                <FontAwesomeIcon icon={faEar} />
              </span>
            </Tooltip>
          ) : null}
          {lastConnectedTitle}
        </div>
        <div className="call-item__header-contact">
          {otherCallPointUserId ? (
            <div className="call-item__header-contact-company">Internal</div>
          ) : null}
          <div className="call-item__header-contact-number">
            {numberDisplay}
          </div>
          {!isEnded && callState === CallState.answered ? (
            <div className="call-item__header-duration">
              <CallDetailCounter
                userId={userId}
                callId={callId}
                type={CallDetailCounterType.userDuration}
                precise={true}
              />
            </div>
          ) : null}
        </div>
      </div>
    );
  };

  const $getFooter = () => {
    let callInfoTxt = "-";
    let onCallInfoClick: (() => void) | undefined;
    // NOTE: don't show Connecting/Ringing for incoming calls
    if (isEnded) {
      callInfoTxt = "Call ended";
    } else if (selfSide === Side.source && callState === CallState.connecting) {
      callInfoTxt = "Connecting ...";
    } else if (selfSide === Side.source && callState === CallState.ringing) {
      callInfoTxt = "Ringing ...";
    } else if (isAttendedTransferConsultationLeg) {
      callInfoTxt = "Attended transfer";
    } else if (queueId) {
      callInfoTxt = queueName || "";
      onCallInfoClick = () => {
        if (!queueId) {
          return;
        }
        onQueueNameClick(queueId);
      };
    } else {
      callInfoTxt = "Direct call";
    }
    const infoTxtCssClasses = ["call-item__footer-info-txt"];
    if (onCallInfoClick) {
      infoTxtCssClasses.push("call-item__footer-info-txt--clickable");
    }
    return (
      <div className="call-item__footer">
        <div className={infoTxtCssClasses.join(" ")} onClick={onCallInfoClick}>
          {callInfoTxt}
        </div>
        <div className="call-item__footer-buttons">{$getButtons()}</div>
      </div>
    );
  };

  const onQueueNameClick = (queueId: Queue["id"]) => {
    onNavigationSet(NavigationPage.home, {
      list: NavigationHomeList.queues,
      detailsOpened: true,
      dialerActive: false,
      detailsId: queueId,
    });
  };

  let onUserNameClick = (userId: User["id"]) => {
    onNavigationSet(NavigationPage.home, {
      list: NavigationHomeList.contacts,
      detailsOpened: true,
      dialerActive: false,
      detailsId: userId,
    });
  };

  const $getButtons = (): React.ReactElement[] => {
    if (isEnded) {
      return [];
    }
    if (isListenIn) {
      return $getListenInToCallButtons();
    }
    switch (selfCallPointState) {
      case CallPointState.answered:
      case CallPointState.inactive:
        if (otherCallPointState === CallPointState.ringing) {
          return $getOutgoingButtons();
        }
        return $getAnsweredButtons();
      case CallPointState.ringing:
        return $getRingingButtons();
      case CallPointState.connecting:
        return [$getHangupBtn()];
      default:
        return [];
    }
  };

  const $getListenInToCallButtons = (): React.ReactElement[] => {
    return [$getHoldBtn(), $getHangupBtn(faDeaf)];
  };

  const $getOutgoingButtons = (): React.ReactElement[] => {
    const buttons: React.ReactElement[] = [];
    buttons.push($getHangupBtn());
    return buttons;
  };

  const $getAnsweredButtons = (): React.ReactElement[] => {
    return [
      ...(hideDtmfBtn ? [] : [$getDtmfBtn()]),
      $getHoldBtn(),
      ...(hideTransferBtn ? [] : [$getTransferBtn()]),
      $getHangupBtn(),
    ];
  };

  const $getHoldBtn = (): React.ReactElement => {
    return selfCallPointState === CallPointState.inactive ? (
      <Button
        key="hold-btn"
        icononly={true}
        color={BridgeColor.gs900}
        requiredFeature={UserFeature.callcontrol}
        disabled={callsIsLoading || !hasUnHoldCapability || !online}
        onClick={buttonClicked.bind(null, PhoneActionType.unholdCall)}
        onboardingStep={OnboardingStepId.callUnhold}
        track={[TRACK_CATEGORY, TrackAction.callItemUnhold]}
      >
        <FontAwesomeIcon icon={faPause} />
      </Button>
    ) : (
      <Button
        key="hold-btn"
        icononly={true}
        color={BridgeColor.gs300}
        requiredFeature={UserFeature.callcontrol}
        disabled={callsIsLoading || !hasHoldCapability || !online}
        onboardingStep={OnboardingStepId.callHold}
        onClick={buttonClicked.bind(null, PhoneActionType.holdCall)}
        track={[TRACK_CATEGORY, TrackAction.callItemHold]}
      >
        <FontAwesomeIcon icon={faPause} />
      </Button>
    );
  };

  const $getRingingButtons = (): React.ReactElement[] => {
    return [
      $getHangupBtn(),
      <Button
        key="ringing-btn"
        icononly={true}
        color={BridgeColor.green500}
        fill={"fade"}
        requiredFeature={UserFeature.callcontrol}
        disabled={callsIsLoading || !hasAnswerCapability || !online}
        onboardingStep={OnboardingStepId.callAnswer}
        onClick={buttonClicked.bind(null, PhoneActionType.answerCall)}
        track={[TRACK_CATEGORY, TrackAction.callItemAnswer]}
      >
        <FontAwesomeIcon icon={faPhone} />
      </Button>,
    ];
  };

  const $getHangupBtn = (
    icon: IconDefinition = faTimes,
    color: IButtonProps["color"] = BridgeColor.red500,
    fill: IButtonProps["fill"] = "fade"
  ): React.ReactElement => {
    // NOTE: disable ability to hangup queue dial attempt
    let tooltipMessage: string = "";
    const isQueueDialAttempt = !!queueId && hasParentCall;
    if (isQueueDialAttempt) {
      tooltipMessage = "Queue calls cannot be rejected";
    }
    return (
      <Button
        key="hangup-btn"
        icononly={true}
        color={color}
        fill={fill}
        disabled={callsIsLoading || isQueueDialAttempt || !online}
        tooltip={tooltipMessage}
        onClick={buttonClicked.bind(null, PhoneActionType.hangupCall)}
        track={[TRACK_CATEGORY, TrackAction.callItemHangup]}
      >
        <FontAwesomeIcon icon={icon} />
      </Button>
    );
  };

  const $getTransferBtn = (): React.ReactElement => {
    return (
      <Button
        key="transfer-btn"
        icononly={true}
        className={
          isAttendedTransferMainLeg ? "button--not-clickable" : undefined
        }
        color={
          isAttendedTransferMainLeg ? BridgeColor.gs800 : BridgeColor.gs300
        }
        disabled={callsIsLoading || !online || !!desiredTransferCallId}
        onClick={
          isAttendedTransferMainLeg
            ? undefined
            : setAsDesiredTransferCallHandler
        }
        onboardingStep={OnboardingStepId.callTransfer}
        track={[TRACK_CATEGORY, TrackAction.callItemTransfer]}
      >
        <FontAwesomeIcon icon={faShare} />
      </Button>
    );
  };

  const $getDtmfBtn = (): React.ReactElement => {
    return (
      <Button
        key="dtmf-btn"
        icononly={true}
        track={[TRACK_CATEGORY, TrackAction.callItemDtmf]}
        onClick={toggleDtmf}
        color={dtmfDialerOpened ? BridgeColor.gs800 : BridgeColor.gs300}
      >
        <FontAwesomeIcon icon={faTh} />
      </Button>
    );
  };

  const toggleDtmf = () => {
    const open = !dtmfDialerOpened;
    onNavigationUpdateParams({
      dialerActive: open,
      callIdForDtmf: open ? callId : undefined,
      detailsOpened: false,
    });
  };

  const setAsDesiredTransferCallHandler = () => {
    onSetAsDesiredTransferCall(callId);
  };

  const buttonClicked = (action: PhoneActionType) => {
    onPhoneAction(callId, action).catch(handleError);
  };

  return (
    <div className="call-item__wrapper">
      {listenedCallTooltip ? (
        <Tooltip content={listenedCallTooltip} enabled={true}>
          <div className="call-item__listened-call-icon">
            <FontAwesomeIcon icon={faGlasses} />
          </div>
        </Tooltip>
      ) : null}
      <div className={getElementClass()}>
        {$getHeader()}
        {$getFooter()}
      </div>
    </div>
  );
};

export default CallItem;
