import * as React from "react";

export interface InfiniteItemComponentProps {
  elementId: string;
  disableSkeleton?: boolean;
}

export class InfiniteItemComponent<
  P extends InfiniteItemComponentProps,
  S extends { visible: boolean }
> extends React.PureComponent<P, S> {
  private $listRowContent: HTMLElement | null;
  private intersectionObserver: IntersectionObserver | null;
  private visibleDebounceTimeout: NodeJS.Timer | null;

  componentDidMount() {
    if (this.props.disableSkeleton) {
      if (!this.state.visible) {
        this.setState({
          visible: true
        });
      }
      return;
    }
    this.createIntersectionObserver(this.props.elementId);
  }

  componentWillUnmount() {
    if (!this.intersectionObserver) {
      return;
    }
    this.destroyIntersectionObserver();
  }

  private createIntersectionObserver(itemId: string) {
    this.$listRowContent = document.querySelector(`#${itemId}`);
    if (!this.$listRowContent) {
      return;
    }
    try {
      this.intersectionObserver = new IntersectionObserver(
        entries => {
          const isIntersecting = entries[entries.length - 1].isIntersecting;
          if (this.visibleDebounceTimeout) {
            clearTimeout(this.visibleDebounceTimeout);
            this.visibleDebounceTimeout = null;
          }
          this.visibleDebounceTimeout = setTimeout(() => {
            if (isIntersecting && !this.state.visible) {
              this.setState({
                visible: true
              });
              if (this.intersectionObserver && this.$listRowContent) {
                this.intersectionObserver.unobserve(this.$listRowContent);
                this.intersectionObserver = null;
              }
            }
          }, 50);
        },
        { threshold: [0] }
      );
      if (this.$listRowContent) {
        this.intersectionObserver.observe(this.$listRowContent);
      }
    } catch (error) {
      console.warn("Interception observer not supported");
      this.setState({ visible: true });
    }
  }

  private destroyIntersectionObserver() {
    if (this.visibleDebounceTimeout) {
      clearTimeout(this.visibleDebounceTimeout);
      this.visibleDebounceTimeout = null;
    }
    if (this.intersectionObserver && this.$listRowContent) {
      this.intersectionObserver.unobserve(this.$listRowContent);
      this.intersectionObserver = null;
    }
  }
}
