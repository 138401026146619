import * as actionTypes from "src/store/actions/actionTypes";

// NOTE: should be the same as in /src/theme/variables.scss#$mobile-width !
const MOBILE_BREAKPOINT = 900;

export enum NavbarDropDownId {
  app = "app",
  status = "status"
}

export interface IWindowState {
  readonly sizeType: WindowSizeType;
  readonly bounds: IWindowBounds;
  readonly online: boolean;
  readonly navbarDropdownVisible: NavbarDropDownId | null;
}

export interface IWindowBounds {
  width: number;
  height: number;
}

export enum WindowSizeType {
  mobile = "mobile",
  desktop = "desktop"
}

const getCurrentWindowSizeState = (): {
  sizeType: WindowSizeType;
  bounds: IWindowBounds;
} => {
  return {
    sizeType:
      window.innerWidth >= MOBILE_BREAKPOINT
        ? WindowSizeType.desktop
        : WindowSizeType.mobile,
    bounds: {
      width: window.innerWidth,
      height: window.innerHeight
    }
  };
};

const initialState: IWindowState = {
  ...getCurrentWindowSizeState(),
  online: navigator.onLine,
  navbarDropdownVisible: null
};

const reducer = (
  state: IWindowState = initialState,
  action: any
): IWindowState => {
  switch (action.type) {
    case actionTypes.WINDOW_SIZE_UPDATE:
      return {
        ...state,
        ...getCurrentWindowSizeState()
      };
    case actionTypes.WINDOW_ONLINE_UPDATE:
      return {
        ...state,
        online: navigator.onLine
      };
    case actionTypes.WINDOW_SHOW_NAVBAR_DROPDOWN: {
      return {
        ...state,
        navbarDropdownVisible: action.payload
      };
    }
    case actionTypes.WINDOW_HIDE_NAVBAR_DROPDOWN: {
      return {
        ...state,
        navbarDropdownVisible: null
      };
    }
    default:
      return state;
  }
};

export default reducer;
