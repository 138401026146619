import * as React from "react";
import "./TwoColumn.scss";
import { PropsWithChildren } from "react";

const twoColumn: React.FC<PropsWithChildren<{ rightActive?: boolean }>> = (
  props
) => {
  const cssClasses = ["two-column"];
  if (props.rightActive) {
    cssClasses.push("two-column--right-active");
  }

  return <div className={cssClasses.join(" ")}>{props.children}</div>;
};

export const TwoColumn = twoColumn;
