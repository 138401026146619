import * as React from "react";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
} from "src/components/UI/List/";
import Button from "src/components/UI/Button/Button";
import { IRootState } from "src/store/reducers";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { useDispatch, useSelector } from "react-redux";
import { handleError } from "src/utils/errorHandler";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { pickupQueueCall } from "src/store/actions/calls";
import CallDetailCounter, {
  CallDetailCounterType,
} from "src/components/CallDetailCounter/CallDetailCounter";

import "./QueueDetailsCall.scss";
import { BridgeColor } from "src/utils/consts";
import { useInfiniteComponentItem } from "src/utils/useInfiniteComponentItem";

const appDataSelector = (state: IRootState) => {
  const callsIsLoading = state.calls.actionsInProgress > 0;
  const hasAnswerCapabilities =
    !!state.auth.phone &&
    state.auth.phone.capabilities.includes(PhoneCapability.answer);
  const online = state.window.online;

  return {
    callsIsLoading,
    hasAnswerCapabilities,
    online,
  };
};

const QueueDetailsCall: React.FC<{
  queueId: string;
  callId: string;
  number: number;
  title: string;
}> = ({ queueId, callId, number, title }) => {
  const { callsIsLoading, hasAnswerCapabilities, online } =
    useSelector(appDataSelector);

  const dispatch = useDispatch();
  const elementId = `queue-details-call--${queueId}`;
  const onPickupQueueCall = (queueId: string, callId: string) =>
    dispatch<any>(pickupQueueCall(queueId, callId));

  const { visible } = useInfiniteComponentItem({ elementId });

  const answerCall = (callId: string) => {
    onPickupQueueCall(queueId, callId).catch(handleError);
  };

  let $content = (
    <div className="queue-details-call__invisible">
      <div className="queue-details-call__invisible-name" />
      <div className="queue-details-call__invisible-buttons" />
    </div>
  );
  if (visible) {
    $content = (
      <>
        <ListRowTitle number={number}>{title}</ListRowTitle>
        {
          <ListRowInfo>
            {
              <ListRowInfoItem>
                <CallDetailCounter
                  callId={callId}
                  type={CallDetailCounterType.sourceDuration}
                />
              </ListRowInfoItem>
            }
            {
              <ListRowInfoItem isButton={true}>
                <Button
                  icononly={true}
                  small={true}
                  color={BridgeColor.gs300}
                  onClick={answerCall.bind(null, callId)}
                  requiredFeature={UserFeature.pickupqueuecall}
                  disabled={callsIsLoading || !hasAnswerCapabilities || !online}
                >
                  <FontAwesomeIcon icon={faPhone} />
                </Button>
              </ListRowInfoItem>
            }
          </ListRowInfo>
        }
      </>
    );
  }

  return <ListRow id={elementId}>{$content}</ListRow>;
};

export default QueueDetailsCall;
