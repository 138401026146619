import * as React from "react";
import {
  ListRow,
  ListRowTitle,
  ListRowInfo,
  ListRowInfoItem,
} from "src/components/UI/List/";
import { TrackCategory, TrackAction } from "src/utils/track";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import Button from "src/components/UI/Button/Button";
import { TransferDestinationItemSkeleton } from "src/components/TransferModal/TransferDestinationItemSkeleton/TransferDestinationItemSkeleton";
import { UserStatus } from "src/utils/user";
import {
  InfiniteItemComponent,
  InfiniteItemComponentProps,
} from "src/utils/InfiniteItemComponent";
import "./TransferDestinationItem.scss";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { BridgeColor } from "src/utils/consts";
import { Call } from "compass.js";

const TRACK_CATEGORY = TrackCategory.transferModal;

class TransferDestinationItem extends InfiniteItemComponent<
  ITransferDestinationItemProps,
  ITransferDestinationItemState
> {
  state = {
    visible: false,
  };

  render() {
    if (!this.state.visible) {
      return <TransferDestinationItemSkeleton id={this.props.elementId} />;
    }
    const cssClasses = ["transfer-destination-item"];
    if (this.props.active) {
      cssClasses.push("transfer-destination-item--active");
    }
    if (this.props.showDialerCallCTA) {
      cssClasses.push("transfer-destination-item--has-dialer-cta");
    }
    return (
      <div className={cssClasses.join(" ")} onClick={this.onClick}>
        <ListRow
          id={this.props.elementId}
          className="transfer-destination-item__list-row list__row--force-comfortable"
        >
          <ListRowTitle>
            <div
              className={`transfer-destination-item__status transfer-destination-item__status--${this.props.status}`}
            >
              <FontAwesomeIcon icon={faCircle} />
            </div>
            <div className="transfer-destination-item__name">
              {this.props.title}
            </div>
            {this.props.isPinned ? (
              <div className="transfer-destination-item__pin">
                <FontAwesomeIcon icon={faThumbtack} />
              </div>
            ) : null}
          </ListRowTitle>
          {
            <ListRowInfo>
              {
                <ListRowInfoItem className="transfer-destination-item__number">
                  {this.props.number}
                </ListRowInfoItem>
              }
            </ListRowInfo>
          }
        </ListRow>
        {this.props.showDialerCallCTA
          ? "The call you are getting is an automated call from dialler. Please answer it manually to continue with the attended transfer."
          : null}
        {this.props.active ? (
          <div className="transfer-destination-item__buttons">
            {this.$getButtons()}
          </div>
        ) : null}
      </div>
    );
  }

  private $getButtons() {
    return (
      <>
        <Button
          disabled={this.props.attendedTransferDisabled}
          color={BridgeColor.gs800}
          onClick={this.onAttendedTransferClick}
          onboardingStep={
            this.props.showOnboarding
              ? OnboardingStepId.transferAttended
              : undefined
          }
          track={[TRACK_CATEGORY, TrackAction.transferModalAttendedTransfer]}
        >
          Transfer attended
        </Button>
        <Button
          disabled={this.props.directTransferDisabled}
          color={BridgeColor.prim500}
          onClick={this.onDirectTransferClick}
          track={[TRACK_CATEGORY, TrackAction.transferModalDirectTransfer]}
          onboardingDoNotTriggerNext={true}
        >
          Transfer unattended
        </Button>
      </>
    );
  }

  private onClick = (e: React.MouseEvent) => {
    if (!this.props.click) {
      return;
    }
    this.props.click(this.props.id, e);
  };

  private onDirectTransferClick = (e: React.MouseEvent) => {
    this.props.directTransferClick(this.props.number, e, this.props.callId);
  };
  private onAttendedTransferClick = (e: React.MouseEvent) => {
    this.props.attendedTransferClick(this.props.number, e, this.props.callId);
  };
}

interface ITransferDestinationItemProps extends InfiniteItemComponentProps {
  id: string;
  title: string;
  number: string;
  callId?: Call["id"];
  status: UserStatus;
  active?: boolean;
  click?: (itemId: string, e?: React.MouseEvent) => void;
  directTransferClick: (
    destination: string,
    e?: React.MouseEvent,
    callId?: Call["id"]
  ) => void;
  directTransferDisabled?: boolean;
  attendedTransferClick: (
    destination: string,
    e?: React.MouseEvent,
    callId?: Call["id"]
  ) => void;
  attendedTransferDisabled?: boolean;
  isPinned?: boolean;
  showOnboarding?: boolean;
  showDialerCallCTA?: boolean;
}

interface ITransferDestinationItemState {
  visible: boolean;
}

export default TransferDestinationItem;
