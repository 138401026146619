import * as React from "react";
import { IRootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import ContactItem from "src/components/ContactItem/ContactItem";
import { User } from "compass.js";
import "./QueueDetailsManageAgents.scss";
import Button from "src/components/UI/Button/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/pro-solid-svg-icons/faPlus";
import { faMinus } from "@fortawesome/pro-solid-svg-icons/faMinus";
import { notificationShow, loginQueue, logoutQueue } from "src/store/actions";

import { handleError } from "src/utils/errorHandler";
import { sortContacts, filterContacts } from "src/utils/contact";
import {
  NORMAL_LIST_ITEM_HEIGHT,
  COMPACT_LIST_ITEM_HEIGHT,
  BridgeColor,
} from "src/utils/consts";
import { ViewModeType } from "src/store/preferences";
import Infinite from "react-infinite";
import { getSortedQueueMembers } from "src/utils/queue";
import { useEffect, useRef } from "react";
import { INotification } from "src/store/reducers/notifications";
import { useForceUpdate } from "src/utils/hooks";
import { createSelector } from "reselect";

const selectQueueItems = (state: IRootState) => state.queues.items;
const selectContacts = (state: IRootState) => state.contacts.compassItems;
const selectPreferences = (state: IRootState) =>
  state.preferences.user.viewMode;
const selectUser = (state: IRootState) => state.auth.user as User;
const selectActionsInProgress = (state: IRootState) =>
  state.queues.actionsInProgress;

const makeAppDataSelector = (queueId: string) =>
  createSelector(
    [
      selectQueueItems,
      selectContacts,
      selectPreferences,
      selectUser,
      selectActionsInProgress,
    ],
    (queueItems, contacts, viewMode, user, actionsInProgress) => {
      const queue = queueItems[queueId];
      const agents = getSortedQueueMembers(queue);
      const sortedContacts = sortContacts(Object.values(contacts));
      const queuesIsLoading = actionsInProgress > 0;

      return {
        agents,
        contacts: sortedContacts,
        queue,
        queuesIsLoading,
        viewMode,
        loggedInUserId: user.id,
      };
    }
  );
const QueueDetailsManageAgents: React.FC<{
  queueId: string;
  className?: string;
  query?: string;
}> = ({ queueId, className, query }) => {
  const appDataSelector = makeAppDataSelector(queueId);
  const { agents, contacts, queue, queuesIsLoading, viewMode, loggedInUserId } =
    useSelector((state: IRootState) => appDataSelector(state));

  const dispatch = useDispatch();
  let contentWrapRef = useRef<HTMLDivElement | null>(null);
  let contentWrap = contentWrapRef.current;
  const forceUpdate = useForceUpdate();
  const itemHeight =
    viewMode === ViewModeType.comfortable
      ? NORMAL_LIST_ITEM_HEIGHT
      : COMPACT_LIST_ITEM_HEIGHT;

  const onNotificationShow = (
    notification:
      | INotification
      | Pick<INotification, "message" | "autoDismiss" | "dismissable" | "level">
  ) => dispatch(notificationShow(notification));
  const onLoginQueue = (queueId: string, userId?: string) =>
    dispatch<any>(loginQueue(queueId, userId));
  const onLogoutQueue = (queueId: string, userId?: string) =>
    dispatch<any>(logoutQueue(queueId, userId));
  const notLoggedInContacts = React.useMemo(() => {
    return contacts.filter(
      (item) => !agents.find((agent) => agent.userId === item.id)
    );
  }, [agents, contacts]);
  const getListContent = () => {
    if (query) {
      return filterContacts(contacts, query).map((contact) =>
        contact.user ? (
          <ContactItem
            key={contact.id}
            id={contact.id}
            buttons={[$getLoginBtn(contact.user)]}
          />
        ) : null
      );
    }

    let $elements: React.ReactElement[] = [];
    if (agents.length) {
      $elements = [
        <div
          className="queue-details-manage-agents__title"
          key="agents-in-queue-title"
        >
          Agents in queue
        </div>,
        ...agents.map((agent) => {
          return (
            <div key={agent.userId}>
              <ContactItem
                id={agent.userId}
                type="agent"
                buttons={[$getLoginBtn(agent.getUser())]}
              />
            </div>
          );
        }),
      ];
    }
    if (notLoggedInContacts.length) {
      $elements = [
        ...$elements,
        <div
          className="queue-details-manage-agents__title"
          key="agents-not-in-queue-title"
        >
          Agents not in queue
        </div>,
        ...notLoggedInContacts.map((contact) => (
          <ContactItem
            id={contact.id}
            key={contact.id}
            buttons={[$getLoginBtn(contact.user as User)]}
          />
        )),
      ];
    }
    return $elements;
  };

  const $getLoginBtn = (user: User) => {
    return queue.isUserInQueue(user.id) ? (
      <Button
        key="logout-queue"
        icononly={true}
        small={true}
        color={BridgeColor.gs300}
        tooltip={"Remove agent from this queue"}
        disabled={queuesIsLoading}
        onClick={logoutUserFromQueue.bind(null, user)}
      >
        <FontAwesomeIcon icon={faMinus} />
      </Button>
    ) : (
      <Button
        key="login-queue"
        icononly={true}
        small={true}
        color={BridgeColor.gs300}
        tooltip={"Add agent to this queue"}
        disabled={queuesIsLoading}
        onClick={loginUserOnQueue.bind(null, user)}
      >
        <FontAwesomeIcon icon={faPlus} />
      </Button>
    );
  };

  const loginUserOnQueue = async (user: User) => {
    try {
      await onLoginQueue(queueId, user.id);
      if (loggedInUserId !== user.id) {
        onNotificationShow({
          autoDismiss: 4000,
          message: `${user.name} placed in queue ${queue.name}`,
          level: "success",
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  const logoutUserFromQueue = async (user: User) => {
    try {
      await onLogoutQueue(queueId, user.id);
      if (loggedInUserId !== user.id) {
        onNotificationShow({
          autoDismiss: 4000,
          message: `${user.name} removed from queue ${queue.name}`,
          level: "success",
        });
      }
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    forceUpdate();
  }, [forceUpdate]);

  return (
    <div className={className} ref={contentWrapRef}>
      {contentWrap ? (
        <Infinite
          containerHeight={contentWrap.clientHeight}
          elementHeight={itemHeight}
          preloadAdditionalHeight={Infinite.containerHeightScaleFactor(2)}
        >
          {getListContent()}
        </Infinite>
      ) : null}
    </div>
  );
};

export default QueueDetailsManageAgents;
