import * as React from "react";
import "./Loader.scss";

export const Loader: React.FC<ILoaderProps> = props => {
  const cssClasses = ["loader", "ball-pulse"];
  if (props.color) {
    cssClasses.push(`loader--color-${props.color}`);
  }
  if (props.size) {
    cssClasses.push(`loader--size-${props.size}`);
  }
  return (
    <div className={cssClasses.join(" ")}>
      <div />
      <div />
      <div />
    </div>
  );
};

interface ILoaderProps {
  size?: "default" | "large";
  color?: "primary" | "grey";
}
