import * as React from "react";
import "./Navbar.scss";
import NavbarDropdown, {
  IMenuItem,
  MenuItemType,
} from "./NavbarDropdown/NavbarDropdown";
import { useDispatch, useSelector } from "react-redux";
import { navigationSet } from "src/store/actions/navigation";
import {
  logout,
  closeAskFeedbackModal,
  showFeedbackModal,
  beginOnboarding,
  setUserStatus,
  showLoginPhoneModal,
  logoutUserFromPhone,
} from "src/store/actions/auth";
import { IRootState } from "src/store/reducers";
import { ReceiveCalls, User } from "compass.js";
import IconInformationModal from "src/components/IconInformationModal/IconInformationModal";
import { NavigationPage } from "src/store/reducers/navigation";
import logoImg from "src/assets/imgs/bridge-logo-light.svg";
import { TrackAction, TrackCategory } from "src/utils/track";
import PrivacyStatementModal from "../PrivacyStatementModal/PrivacyStatementModal";
import PremiumFeaturesModal from "../PremiumFeaturesModal/PremiumFeaturesModal";
import { LoginPhoneModalType } from "../LoginPhoneModal/LoginPhoneModal";
import Modal from "src/components/UI/Modal/Modal";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { faChevronDown } from "@fortawesome/pro-solid-svg-icons/faChevronDown";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getUserStatusInfo, UserStatus } from "src/utils/user";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faEllipsisH } from "@fortawesome/pro-solid-svg-icons/faEllipsisH";
import { NavbarDropDownId } from "src/store/reducers/window";

import { isElectron } from "src/utils";
import { handleError } from "src/utils/errorHandler";
import { UserFeature, OnboardingType } from "src/store/reducers/auth";
import { fetchMicrosoftContacts } from "src/store/actions/contacts";
import { notificationShow } from "src/store/actions";
import { BridgeColor } from "src/utils/consts";
import packageJson from "../../../package.json";
import NavbarWrapUpBtn from "./NavbarWrapUpBtn/NavbarWrapUpBtn";
import { useState } from "react";
import { INotification } from "src/store/reducers/notifications";
import { useIsSignedIn } from "@microsoft/mgt-react";
import { Providers } from "@microsoft/mgt";
import { createSelector } from "reselect";

enum AppMenuActionId {
  contactsOverview = "contacts-overview",
  help = "help",
  syncContacts = "sync-contacts",
  settings = "settings",
  beginOnboarding = "begin-onboarding",
  manual = "manual",
  privacy = "privacy",
  premium = "premium",
  logout = "logout",
}
enum StatusMenuActionId {
  available = "available",
  away = "away",
  busy = "busy",
  switchPhone = "switch-phone",
  logOutPhone = "log-out-phone",
}
const TRACK_CATEGORY = TrackCategory.navbar;
const appDataSelector = createSelector(
  [
    (state: IRootState) => state.auth.apiVersion,
    (state: IRootState) => state.auth.user,
    (state: IRootState) => !!state.auth.phone,
    (state: IRootState) => state.auth.user?.id,
    (state: IRootState) => state.auth.phone?.name,
    (state: IRootState) => state.auth.onboardingMode,
    (state: IRootState) => state.auth.showAskFeedbackModal,
    (state: IRootState) => state.auth.features,
    (state: IRootState) =>
      state.auth.user?.name || state.auth.userCachedInfo?.username || "",
    (state: IRootState) => state.contacts.addressBookContactsIsLoading,
    (state: IRootState) => state.auth.user?.status.receiveCalls,
    (state: IRootState) => !!state.auth.phone,
    (state: IRootState) => state.auth.user?.status.wrapupState,
  ],
  (
    apiVersion,
    userAuth,
    userHasPhone,
    userId,
    loggedInPhoneName,
    onboardingMode,
    showAskFeedbackModal,
    userFeatures,
    username,
    addressBookContactsIsLoading,
    receiveCallsStatus,
    isLoggedInToThePhone,
    isWrapUp
  ) => {
    return {
      apiVersion,
      userAuth,
      userHasPhone,
      userId,
      loggedInPhoneName,
      onboardingMode,
      showAskFeedbackModal,
      userFeatures,
      username,
      addressBookContactsIsLoading,
      receiveCallsStatus,
      isLoggedInToThePhone,
      isWrapUp,
    };
  }
);

const Navbar: React.FC = () => {
  const {
    apiVersion,
    userAuth,
    userHasPhone,
    userId,
    loggedInPhoneName,
    onboardingMode,
    showAskFeedbackModal,
    userFeatures,
    username,
    addressBookContactsIsLoading,
    receiveCallsStatus,
    isLoggedInToThePhone,
    isWrapUp,
  } = useSelector(appDataSelector);

  const dispatch = useDispatch();
  let userStatus: UserStatus = UserStatus.loggedOut;
  if (userAuth) {
    userStatus = getUserStatusInfo(userAuth).userStatus;
  }
  const [isHelpModalOpened, setIsHelpModalOpened] = useState(false);
  const [isPrivacyModalOpened, setIsPrivacyModalOpened] = useState(false);
  const [isPremiumFeaturesModalOpened, setIsPremiumFeaturesModalOpened] =
    useState(false);
  const [isLogoutPhoneModalOpened, setIsLogoutPhoneModalOpened] =
    useState(false);
  const [isLogoutPhoneSubmitting, setIsLogoutPhoneSubmitting] = useState(false);
  const onLogout = () => dispatch(logout());
  const onNavigationSet = (page: NavigationPage, params?: any) =>
    dispatch(navigationSet(page, params));

  const [isSignedIn] = useIsSignedIn();

  const onCloseAskFeedbackModal = () => dispatch(closeAskFeedbackModal());
  const onShowFeedbackModal = () => dispatch(showFeedbackModal());
  const onSetUserStatus = (
    receiveCalls: ReceiveCalls,
    displayStatus?: string
  ) => dispatch<any>(setUserStatus(receiveCalls, displayStatus));

  const onShowLoginPhoneModal = (modalType: LoginPhoneModalType) =>
    dispatch(showLoginPhoneModal(modalType));
  const onSyncAddressBookContacts = async () => {
    dispatch<any>(fetchMicrosoftContacts());
  };
  const onNotificationShow = (
    notification:
      | INotification
      | Pick<INotification, "message" | "autoDismiss" | "dismissable" | "level">
  ) => dispatch(notificationShow(notification));
  const onLogoutUserFromPhone = (userId: User["id"]) => {
    dispatch<any>(logoutUserFromPhone(userId));
  };

  const logoutPhone = async () => {
    if (!userId) {
      return;
    }
    setIsLogoutPhoneSubmitting(true);
    try {
      await onLogoutUserFromPhone(userId);
    } catch (error) {
      setIsLogoutPhoneSubmitting(false);
    } finally {
      setIsLogoutPhoneSubmitting(false);
      setIsLogoutPhoneModalOpened(false);
    }
  };

  const handleLogoutModalRequestClose = () => {
    setIsLogoutPhoneModalOpened(false);
  };

  const handleHelpModalRequestClose = () => {
    setIsHelpModalOpened(false);
  };

  const handlePrivacyModalRequestClose = () => {
    setIsPrivacyModalOpened(false);
  };

  const handlePremiumFeaturesModalRequestClose = () => {
    setIsPremiumFeaturesModalOpened(false);
  };

  const openPremiumFeaturesModal = () => {
    setIsPremiumFeaturesModalOpened(true);
  };

  const getStatusMenuDropdownItems = (): IMenuItem[] => {
    const statusTooltip = isLoggedInToThePhone
      ? undefined
      : "Log in to a phone to change status.";
    const statusItems: IMenuItem[] = [
      {
        type: MenuItemType.header,
        title: "Change status",
      },
      {
        action: StatusMenuActionId.available,
        type: MenuItemType.button,
        title: "Available",
        icon: faCircle,
        iconColor: BridgeColor.statusAvailable,
        selected:
          isLoggedInToThePhone && receiveCallsStatus === ReceiveCalls.all,
        track: [TRACK_CATEGORY, TrackAction.navbarStatusAvailable],
        disabled: !isLoggedInToThePhone,
        tooltip: statusTooltip,
      },
      {
        action: StatusMenuActionId.busy,
        type: MenuItemType.button,
        title: "No queue calls",
        icon: faCircle,
        iconColor: BridgeColor.statusBusy,
        selected:
          isLoggedInToThePhone &&
          receiveCallsStatus === ReceiveCalls.onlyDirect,
        track: [TRACK_CATEGORY, TrackAction.navbarStatusBusy],
        disabled: !isLoggedInToThePhone,
        tooltip: statusTooltip,
      },
      {
        action: StatusMenuActionId.away,
        type: MenuItemType.button,
        title: "No calls",
        icon: faCircle,
        iconColor: BridgeColor.statusAway,
        selected:
          isLoggedInToThePhone && receiveCallsStatus === ReceiveCalls.none,
        track: [TRACK_CATEGORY, TrackAction.navbarStatusAway],
        disabled: !isLoggedInToThePhone,
        tooltip: statusTooltip,
      },
      {
        type: MenuItemType.separator,
      },
    ];
    return [
      ...(apiVersion && apiVersion >= 3 ? statusItems : []),
      {
        action: StatusMenuActionId.switchPhone,
        title: userHasPhone ? "Switch phones" : "Log in to a phone",
        type: MenuItemType.button,
        track: [
          TRACK_CATEGORY,
          userHasPhone
            ? TrackAction.navbarSwitchPhone
            : TrackAction.navbarLoginPhone,
        ],
      },
      ...(userHasPhone
        ? [
            {
              action: StatusMenuActionId.logOutPhone,
              title: "Log out of phone",
              type: MenuItemType.button,
              track: [TRACK_CATEGORY, TrackAction.navbarLogoutPhone],
            } as IMenuItem,
          ]
        : []),
    ];
  };

  const getAppMenuDropdownItems = (): IMenuItem[] => {
    return [
      {
        action: AppMenuActionId.contactsOverview,
        title: "Open full-screen colleagues",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarOpenFullScreenContacts],
      },
      {
        type: MenuItemType.separator,
      },
      {
        action: AppMenuActionId.settings,
        title: "Settings",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarOpenSettings],
      },
      {
        type: MenuItemType.separator,
      },
      ...(!onboardingMode
        ? [
            {
              action: AppMenuActionId.beginOnboarding,
              title: "Open tutorial",
              type: MenuItemType.button,
              track: [TRACK_CATEGORY, TrackAction.navbarOpenTutorial],
            } as IMenuItem,
          ]
        : []),
      ...(isSignedIn
        ? [
            {
              action: AppMenuActionId.syncContacts,
              title: "Sync contacts now",
              type: MenuItemType.button,
              disabled: addressBookContactsIsLoading,
              track: [TRACK_CATEGORY, TrackAction.navbarSyncContacts],
            } as IMenuItem,
          ]
        : []),
      {
        action: AppMenuActionId.manual,
        title: "User manual",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarManual],
      },
      {
        action: AppMenuActionId.help,
        title: "Help",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarHelp],
        onboardingStep: OnboardingStepId.navbarHelp,
      },
      {
        action: AppMenuActionId.privacy,
        title: "Privacy Policy",
        type: MenuItemType.button,
        track: [TRACK_CATEGORY, TrackAction.navbarPrivacyPolicy],
      },
      ...(!userFeatures.includes(UserFeature.callcontrol)
        ? [
            {
              action: AppMenuActionId.premium,
              title: "Bridge Premium",
              type: MenuItemType.button,
              track: [TRACK_CATEGORY, TrackAction.navbarPremium],
            } as IMenuItem,
          ]
        : []),
      {
        type: MenuItemType.separator,
      },
      {
        action: AppMenuActionId.logout,
        type: MenuItemType.button,
        title: "Sign out of application",
      },
    ];
  };

  const onStatusItemClick = (actionId: StatusMenuActionId): void => {
    switch (actionId) {
      case StatusMenuActionId.available:
        onSetUserStatus(ReceiveCalls.all).catch(handleError);
        break;
      case StatusMenuActionId.away:
        onSetUserStatus(ReceiveCalls.none).catch(handleError);
        break;
      case StatusMenuActionId.busy:
        onSetUserStatus(ReceiveCalls.onlyDirect).catch(handleError);
        break;
      case StatusMenuActionId.switchPhone:
        onShowLoginPhoneModal(LoginPhoneModalType.switch);
        break;
      case StatusMenuActionId.logOutPhone:
        setIsLogoutPhoneModalOpened(true);
        break;
    }
  };

  const onAppMenuItemClick = async (
    actionId: AppMenuActionId
  ): Promise<void> => {
    switch (actionId) {
      case AppMenuActionId.contactsOverview:
        window.open(
          `${document.location.pathname}?contactsOverview=true`,
          "_blank",
          "location=no,scrollbars=yes,status=no"
        );
        break;
      case AppMenuActionId.help:
        setIsHelpModalOpened(true);
        break;
      case AppMenuActionId.syncContacts:
        onSyncAddressBookContacts().then(
          () => {
            onNotificationShow({
              message: "Contacts successfully updated.",
              level: "success",
              dismissable: true,
              autoDismiss: 4000,
            });
          },
          () => {
            onNotificationShow({
              message:
                "There was an error while attempting to update contacts. Please try again later.",
              level: "danger",
              dismissable: true,
              autoDismiss: 4000,
            });
          }
        );
        break;
      case AppMenuActionId.privacy:
        setIsPrivacyModalOpened(true);
        break;
      case AppMenuActionId.settings:
        onNavigationSet(NavigationPage.settings);
        break;
      case AppMenuActionId.beginOnboarding:
        dispatch<any>(beginOnboarding(OnboardingType.default));
        break;
      case AppMenuActionId.manual:
        let manualUrl = "/docs";

        if (isElectron()) {
          manualUrl = `${packageJson.homepage}/docs`;
        }
        window.open(manualUrl, "_blank");
        break;
      case AppMenuActionId.premium:
        openPremiumFeaturesModal();
        break;
      case AppMenuActionId.logout:
        try {
          if (isSignedIn) {
            await handleMSLogout();
          }
          onNavigationSet(NavigationPage.login);
          onLogout();
        } catch (error) {
          handleError(error);
        }
        break;
    }
  };
  const handleMSLogout = async () => {
    try {
      if (Providers.globalProvider.logout) {
        await Providers.globalProvider.logout();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const statusMenuDropdownItems = getStatusMenuDropdownItems();
  const appMenuDropdownItems = getAppMenuDropdownItems();
  return (
    <>
      <div className="navbar navbar--placeholder" />
      <div className="navbar">
        <div className="navbar__container">
          <div className="navbar__logo">
            <img src={logoImg} alt="Bridge Logo" />
          </div>
          <div className="navbar__menu">
            {apiVersion && apiVersion >= 3 ? <NavbarWrapUpBtn /> : null}
            {
              <NavbarDropdown
                items={statusMenuDropdownItems}
                onClick={onStatusItemClick}
                id={NavbarDropDownId.status}
              >
                <>
                  <div className="navbar__user-dropdown-title">
                    {!isWrapUp ? (
                      <div
                        className={
                          "navbar__user-dropdown-call-state navbar__user-dropdown-call-state--" +
                          userStatus
                        }
                      >
                        <FontAwesomeIcon icon={faCircle} />
                      </div>
                    ) : null}
                    {username}
                  </div>
                  <div className="navbar__user-dropdown-icon">
                    <FontAwesomeIcon icon={faChevronDown} />
                  </div>
                </>
              </NavbarDropdown>
            }
            {
              <NavbarDropdown
                items={appMenuDropdownItems}
                onClick={onAppMenuItemClick}
                id={NavbarDropDownId.app}
              >
                <div className="navbar__app-dropdown-icon">
                  <FontAwesomeIcon icon={faEllipsisH} />
                </div>
              </NavbarDropdown>
            }
          </div>
        </div>
      </div>
      {
        <Modal
          isOpen={isPremiumFeaturesModalOpened}
          title={"Upgrade Compass Bridge to get full access"}
          className={"modal--premium-features"}
          showCloseBtn={true}
          onRequestClose={handlePremiumFeaturesModalRequestClose}
        >
          <PremiumFeaturesModal />
        </Modal>
      }
      {
        <Modal
          isOpen={isHelpModalOpened}
          onRequestClose={handleHelpModalRequestClose}
          title={"Icon Information"}
          className={"modal--help-modal"}
          showCloseBtn={true}
        >
          <IconInformationModal />
        </Modal>
      }
      {
        <Modal
          isOpen={isPrivacyModalOpened}
          title={"Compass Bridge Privacy Policy"}
          onRequestClose={handlePrivacyModalRequestClose}
          showCloseBtn={true}
          className={"modal--privacy"}
        >
          <PrivacyStatementModal />
        </Modal>
      }
      {
        <Modal
          isOpen={isLogoutPhoneModalOpened}
          title={`Log out of phone`}
          onRequestClose={handleLogoutModalRequestClose}
          showCloseBtn={true}
          className={"modal--logout-phone"}
          buttons={[
            {
              text: "Don't log out",
              color: BridgeColor.gs1000,
              clicked: handleLogoutModalRequestClose,
              disabled: isLogoutPhoneSubmitting,
            },
            {
              text: "Log out of my phone",
              color: BridgeColor.prim500,
              clicked: logoutPhone,
              disabled: isLogoutPhoneSubmitting,
            },
          ]}
        >
          You are current logged in to {loggedInPhoneName}. Are you sure you
          want to log out of the phone?
        </Modal>
      }
      {
        <Modal
          isOpen={showAskFeedbackModal}
          title={"We value your opinion"}
          onRequestClose={onCloseAskFeedbackModal}
          showCloseBtn={true}
          className={"modal--ask-feedback"}
          buttons={[
            {
              text: "Give feedback",
              color: BridgeColor.prim500,
              clicked: onShowFeedbackModal,
            },
            {
              text: "Not now",
              color: BridgeColor.gs1000,
              clicked: onCloseAskFeedbackModal,
            },
          ]}
        >
          We are always looking for new ways to improve Bridge. Would you mind
          telling us what you think?
        </Modal>
      }
    </>
  );
};

export default Navbar;
