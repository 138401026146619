import * as React from "react";
import { useEffect, useRef } from "react";
import { Connection } from "compass.js";
import { useSelector, useDispatch } from "react-redux";
import { IRootState } from "src/store/reducers";
import { IContact, ContactType } from "src/store/reducers/contacts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import {
  getUserStatusInfo,
  UserStatusInfo,
  UserStatus,
  getUserStatusDisplay,
} from "src/utils/user";
import "./ContactsOverviewItem.scss";
import Button from "src/components/UI/Button/Button";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import {
  tickIntervalRegister,
  tickIntervalUnregister,
} from "src/store/actions/tickInterval";
import { dialNumber } from "src/store/actions";
import { getUserCallDuration } from "src/utils/call";
import { handleError } from "src/utils/errorHandler";
import { TrackCategory, TrackAction } from "src/utils/track";
import { BridgeColor } from "src/utils/consts";
import { generateUID } from "src/utils";
import { humanizeApproxDuration } from "src/utils/dateTime";
import { useForceUpdate } from "src/utils/hooks";
const TRACK_CATEGORY = TrackCategory.contactsOverview;
const appDataSelector = (state: IRootState, contact: IContact) => {
  const connection = state.auth.connection as Connection;
  const users = connection.model.users;
  const online = state.window.online;
  const statusDisplay = getUserStatusDisplay(contact.id);

  return {
    users,
    online,
    statusDisplay,
  };
};

const ContactsOverviewItem = ({
  contact,
}: IContactsOverviewItemComponentProps) => {
  const { users, online, statusDisplay } = useSelector((state: IRootState) =>
    appDataSelector(state, contact)
  );

  const dispatch = useDispatch();
  const forceUpdate = useForceUpdate();
  let tickIntervalIdRef = useRef<string | undefined>();
  let lastStatusInfoRef = useRef<UserStatusInfo>();
  useEffect(() => {
    tickIntervalIdRef.current = `${ContactsOverviewItem.name}:${generateUID()}`;
    dispatch(
      tickIntervalRegister(tickIntervalIdRef.current, () => {
        if (contact.type !== ContactType.compass) {
          return;
        }
        const statusInfo = getUserStatusInfo(users[contact.id]);
        if (
          statusInfo.call ||
          (lastStatusInfoRef.current && lastStatusInfoRef.current.call)
        ) {
          forceUpdate();
        }
      })
    );

    return () => {
      if (tickIntervalIdRef.current) {
        dispatch(tickIntervalUnregister(tickIntervalIdRef.current));
      }
    };
  }, [contact.id, contact.type, dispatch, forceUpdate, users]);
  const dialNumberHandler = (destination: string | null) => {
    return (
      destination && dispatch<any>(dialNumber(destination)).catch(handleError)
    );
  };

  const user = users[contact.id];
  let statusInfo;
  let duration: null | string = null;
  let userExtension: null | string = null;
  if (contact.type === ContactType.compass) {
    statusInfo = getUserStatusInfo(user);
    lastStatusInfoRef.current = statusInfo;

    if (user.extensions.length > 0) {
      userExtension = user.extensions[0];
    }
  }
  if (
    statusInfo &&
    statusInfo.userStatus === UserStatus.inCall &&
    statusInfo.call
  ) {
    duration = ` (${humanizeApproxDuration(
      getUserCallDuration(statusInfo.call, user)
    )})`;
  }
  const cssStatusInfo =
    contact.type === ContactType.compass
      ? statusInfo?.userStatus
      : UserStatus.loggedOut;

  let phone: string;
  if (contact.type === ContactType.addressBook && contact.phones.length > 0) {
    phone = contact.phones[0].value;
    userExtension = phone;
  }

  return (
    <div
      className={[
        "contacts-overview-list__contact",
        "contacts-overview-list__contact--" + cssStatusInfo,
      ].join(" ")}
      key={contact.id}
    >
      <div className="contacts-overview-list__contact-content">
        <div className="contacts-overview-list__contact-label">
          <div className="contacts-overview-list__contact-status">
            <FontAwesomeIcon icon={faCircle} />
          </div>
          <div className="contacts-overview-list__contact-name">
            {contact.name}
          </div>
        </div>
        <div className="contacts-overview-list__contact-right">
          <div className="contacts-overview-list__contact-status-display">
            {statusDisplay}
            {duration}
          </div>
          <div className="contacts-overview-list__contact-status-btns">
            <Button
              small={true}
              icononly={true}
              color={BridgeColor.gs300}
              disabled={!userExtension || !online}
              onClick={() => dialNumberHandler(userExtension)}
              track={[TRACK_CATEGORY, TrackAction.contactsOverviewDialContact]}
            >
              <FontAwesomeIcon icon={faPhone} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

interface IContactsOverviewItemComponentProps {
  contact: IContact;
}

export default ContactsOverviewItem;
