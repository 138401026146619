import * as React from "react";
import "./ContactsOverview.scss";
import ContactsOverviewList from "src/components/ContactsOverviewList/ContactsOverviewList";
const ContactsOverview: React.FC = () => {
  return (
    <div className="contacts-overview">
      <div className="contacts-overview__header">Contacts overview</div>
      <div className="contacts-overview__list">
        <ContactsOverviewList />
      </div>
    </div>
  );
};

export default ContactsOverview;
