import * as React from "react";
import QueueDetails from "src/components/QueueDetails/QueueDetails";
import QueuesList from "src/components/QueuesList/QueuesList";
import ContactDetails from "src/components/ContactDetails/ContactDetails";
import ContactsList from "src/components/ContactsList/ContactsList";
import Dialer, { DialerType } from "src/components/UI/Dialer/Dialer";
import {
  TwoColumn,
  TwoColumnLeft,
  TwoColumnRight,
} from "src/components/UI/TwoColumn";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  IHomePageParams,
  NavigationHomeList,
} from "src/store/reducers/navigation";
import "./BottomView.scss";
import ContainerHeader from "src/components/UI/ContainerHeader/ContainerHeader";
import { navigationUpdateParams } from "src/store/actions/navigation";
import { WindowSizeType } from "src/store/reducers/window";
import { createSelector } from "reselect";

const selectNavigationParams = (state: IRootState) =>
  state.navigation.params as IHomePageParams;
const selectWindowSizeType = (state: IRootState) => state.window.sizeType;

const appDataSelector = createSelector(
  [selectNavigationParams, selectWindowSizeType],
  (navigationParams, windowSizeType) => {
    const dialerActive = navigationParams.dialerActive;
    const openedDetails = navigationParams.detailsOpened
      ? navigationParams.detailsId
      : undefined;
    const openedList = navigationParams.list;
    const callIdForDtmf = navigationParams.callIdForDtmf;

    return {
      windowSizeType,
      dialerActive,
      openedDetails,
      openedList,
      callIdForDtmf,
    };
  }
);

const BottomView: React.FC = () => {
  const {
    windowSizeType,
    dialerActive,
    openedDetails,
    openedList,
    callIdForDtmf,
  } = useSelector(appDataSelector);

  const dispatch = useDispatch();

  const onNavigationUpdateParams = (params: Partial<IHomePageParams>) =>
    dispatch(navigationUpdateParams(params));

  const $getRightComponent = (): React.ReactNode => {
    if (openedDetails) {
      switch (openedList) {
        case NavigationHomeList.queues:
          return <QueueDetails id={openedDetails} />;
        case NavigationHomeList.contacts:
          return <ContactDetails id={openedDetails} />;
        default:
          return null;
      }
    } else if (dialerActive) {
      return (
        <>
          <div className="br-screen-small">
            <ContainerHeader
              title={""}
              enableBackBtn={true}
              mobileOnlyBackBtn={true}
              className={"container-header--no-border"}
              backBtnClicked={onDialerClose}
            />
          </div>
          <Dialer
            type={callIdForDtmf ? DialerType.dtmf : DialerType.default}
            callIdForDtmf={callIdForDtmf}
          />
        </>
      );
    }
    return null;
  };

  const onDialerClose = () => {
    onNavigationUpdateParams({
      dialerActive: false,
    });
  };

  const $getLeftComponent = (): React.ReactNode => {
    switch (openedList) {
      case "queues":
        return <QueuesList />;
      case "contacts":
        return <ContactsList />;
      default:
        return null;
    }
  };

  return (
    <TwoColumn rightActive={dialerActive}>
      {<TwoColumnLeft>{$getLeftComponent()}</TwoColumnLeft>}
      {windowSizeType === WindowSizeType.desktop || dialerActive ? (
        <TwoColumnRight>{$getRightComponent()}</TwoColumnRight>
      ) : null}
    </TwoColumn>
  );
};

export default BottomView;
