import * as React from "react";
import "./AttendedTransferModal.scss";
import { IRootState } from "src/store/reducers";
import { Call, User, Side, OtherSide, CallPointState } from "compass.js";
import { phoneAction, PhoneActionType } from "src/store/actions";
import { mergeCalls, resetDesiredTransferCall } from "src/store/actions/calls";
import { useDispatch, useSelector } from "react-redux";
import { BridgeColor } from "src/utils/consts";
import CallItem from "src/components/Calls/CallItem/CallItem";
import Button from "../UI/Button/Button";
import { handleError } from "src/utils/errorHandler";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { getUserSide } from "src/utils/call";
import { createSelector } from "reselect";
/**
 * This is a modal that's shown during attended transfer:
 * when the consultation call has been set up,
 * but the transfer has not yet been completed.
 */

const selectConsultationCall = (
  state: IRootState,
  consultationCallId: Call["id"]
) => state.calls.items.find((call) => call.id === consultationCallId);

const selectAuthUser = (state: IRootState) => state.auth.user as User;
const selectCallsIsLoading = (state: IRootState) =>
  !!state.calls.actionsInProgress;
const selectOnboardingMode = (state: IRootState) => state.auth.onboardingMode;

const appDataSelector = createSelector(
  [
    selectConsultationCall,
    selectAuthUser,
    selectCallsIsLoading,
    selectOnboardingMode,
  ],
  (consultationCall, authUser, callsIsLoading, onboardingMode) => ({
    consultationCall,
    authUser,
    callsIsLoading,
    onboardingMode,
  })
);

const AttendedTransferModal: React.FC<{
  mainCallId: Call["id"];
  consultationCallId: Call["id"];
}> = ({ mainCallId, consultationCallId }) => {
  const { consultationCall, authUser, callsIsLoading, onboardingMode } =
    useSelector((state: IRootState) =>
      appDataSelector(state, consultationCallId)
    );

  const dispatch = useDispatch();

  const canFinishTransfer = React.useMemo(() => {
    let canFinishTransfer = false;
    if (consultationCall) {
      const selfSide = getUserSide(consultationCall, authUser) as Side;
      const otherCallPoint = consultationCall.getEndpoint(OtherSide(selfSide));
      canFinishTransfer = [
        CallPointState.answered,
        CallPointState.inactive,
      ].includes(otherCallPoint.state);
    }
    return canFinishTransfer;
  }, [authUser, consultationCall]);

  const onMergeCalls = (callId1: string, callId2: string) =>
    dispatch<any>(mergeCalls(callId1, callId2));
  const onPhoneAction = (callId: Call["id"], action: PhoneActionType) =>
    dispatch<any>(phoneAction(callId, action));
  const onResetDesiredTransferCall = () => dispatch(resetDesiredTransferCall());

  const mergeCallsHandler = async () => {
    if (onboardingMode) {
      return;
    }
    try {
      await onMergeCalls(consultationCallId, mainCallId);
    } catch (error) {
      handleError(error);
    }
  };

  const cancelTransfer = async () => {
    try {
      await onPhoneAction(consultationCallId, PhoneActionType.hangupCall);
      onResetDesiredTransferCall();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <div className="attended-transfer-modal">
      <div className="attended-transfer-modal__calls">
        <CallItem
          callId={mainCallId}
          hideTransferBtn={true}
          hideDtmfBtn={true}
        />
        <CallItem
          callId={consultationCallId}
          hideTransferBtn={true}
          hideDtmfBtn={true}
        />
      </div>
      <div className="attended-transfer-modal__buttons">
        <Button
          disabled={callsIsLoading || !canFinishTransfer}
          color={BridgeColor.prim500}
          tooltip={
            canFinishTransfer
              ? undefined
              : "Transfer will be possible when the call is answered"
          }
          onClick={mergeCallsHandler}
        >
          Transfer the call
        </Button>
        <Button
          disabled={callsIsLoading}
          color={BridgeColor.gs800}
          onClick={cancelTransfer}
          onboardingStep={OnboardingStepId.callCancelAttendedTransfer}
          onboardingTooltipProps={{ placement: "right" }}
        >
          Cancel transfer
        </Button>
      </div>
    </div>
  );
};

export default AttendedTransferModal;
