import * as React from "react";
import "./RingTimeModal.scss";
import Modal from "../UI/Modal/Modal";
import { useSelector } from "react-redux";
import {
  selectCompassPreferences,
  updateUserIdentity,
} from "src/store/preferences";
import { BridgeColor } from "src/utils/consts";
import { useReduxDispatch } from "src/utils/hooks";
import { handleError } from "src/utils/errorHandler";

const ringTimeValues = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50, 55, 60];

const RingTimeModal: React.FC<{
  isOpen: boolean;
  onRequestClose: () => void;
}> = ({ isOpen, onRequestClose }) => {
  const { identity } = useSelector(selectCompassPreferences);
  const [saveInProgress, setSaveInProgress] = React.useState<boolean>(false);
  const [selectedRingTime, setSelectedRingTime] = React.useState<number>();
  const dispatch = useReduxDispatch();

  React.useEffect(() => {
    if (identity) {
      setSelectedRingTime(identity.ringtime);
    }
  }, [identity]);

  const onRingTimeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    setSelectedRingTime(parseInt(e.target.value, 10));
  };

  const onSave = React.useCallback(async () => {
    setSaveInProgress(true);
    try {
      const response = await dispatch(
        updateUserIdentity({ ringtime: selectedRingTime })
      );
      if (response.type === updateUserIdentity.rejected.type) {
        throw response.payload;
      }
      onRequestClose();
    } catch (error) {
      handleError(error);
    } finally {
      setSaveInProgress(false);
    }
  }, [dispatch, selectedRingTime, onRequestClose]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      title="Ring time (seconds)"
      className="modal--ring-time"
      buttonsPlacement="right"
      buttons={[
        {
          text: "Close",
          color: BridgeColor.gs900,
          clicked: onRequestClose,
          disabled: saveInProgress,
        },
        {
          text: "Set time",
          color: BridgeColor.prim500,
          clicked: onSave,
          disabled: saveInProgress,
        },
      ]}
    >
      <div className="ring-time-modal__description">
        Before forwarding my call when I am not answering, wait...
      </div>
      <div className="ring-time-modal__form br-form-group">
        <select value={selectedRingTime} onChange={onRingTimeChange}>
          {ringTimeValues.map((ringTime) => (
            <option key={ringTime} value={ringTime}>
              {ringTime} seconds
            </option>
          ))}
        </select>
      </div>
    </Modal>
  );
};

export default RingTimeModal;
