import * as React from "react";
import { TippyProps } from "@tippy.js/react";
import OnboardingTooltip from "src/components/UI/OnboardingTooltip/OnboardingTooltip";
import { OnboardingStepId } from "./OnboardingStep";

export const wrapOnboarding = (
  $el: React.ReactElement,
  step?: OnboardingStepId | OnboardingStepId[],
  tippyProps?: Partial<TippyProps>,
  extraCondition: boolean = true,
  key?: string | number
) => {
  if (!step || !extraCondition) {
    return $el;
  }
  return (
    <OnboardingTooltip step={step} tippyProps={tippyProps} key={key}>
      {$el}
    </OnboardingTooltip>
  );
};
