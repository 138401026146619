import * as React from "react";
import { parsePhoneNumberFromString, NumberFormat } from "libphonenumber-js";

import { remove as removeDiacritics } from "diacritics";
import shallowequal from "shallowequal";

export const usePrevious = <T>(value: T): T | undefined => {
  const ref = React.useRef<T>();
  React.useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};

export const generateUID = () => {
  return Math.random()
    .toString(36)
    .replace(/[^a-z]+/g, "")
    .substr(2, 10);
};

export const replaceAll = (str: string, find: string, replace: string) => {
  return str.replace(new RegExp(find, "g"), replace);
};

export const stringContains = (str: string, q: string) => {
  return str.toLowerCase().indexOf(q.toLowerCase()) !== -1;
};

export const sortIgnoreCaseComparator = (a: string, b: string) => {
  a = removeDiacritics(a.toLowerCase());
  b = removeDiacritics(b.toLowerCase());
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

export const sortNumbersComparator = (a: number, b: number) => {
  if (a > b) {
    return 1;
  } else if (a < b) {
    return -1;
  }
  return 0;
};

/**
 * Sort list of objects by property
 * @param list list of items
 * @param prop sort property
 * @param comparator comparator function
 */
export const sortByProperty = <ListItemType, PropType>(
  list: ListItemType[],
  prop: keyof ListItemType,
  comparator: (a: PropType, b: PropType) => number
): ListItemType[] => {
  return list.sort((a, b) =>
    comparator(a[prop] as PropType, b[prop] as PropType)
  );
};

/**
 * Sort list of objects by lambda getter function
 * @param list list of objects
 * @param lambda property getter function
 * @param comparator comparator function
 */
export const sortBy = <ListItemType, PropType>(
  list: ListItemType[],
  lambda: (item: ListItemType) => PropType,
  comparator: (a: PropType, b: PropType) => number
): ListItemType[] => {
  return list.sort((a, b) => comparator(lambda(a), lambda(b)));
};

/**
 * Check if app runned by electron
 */

export const isElectron = () => {
  return navigator.userAgent.toLowerCase().indexOf(" electron/") > -1;
};

export enum DesktopEventType {
  setupTelProtocol = "setupTelProtocol",
  tel = "tel",
}

export const reactIsInDevMode = () => {
  return process.env.NODE_ENV === "development";
};

export const cssSafeStr = (str: string) => {
  return str.replace(/[^a-z0-9]/g, (s) => {
    const c = s.charCodeAt(0);
    if (c === 32) {
      return "-";
    }
    if (c >= 65 && c <= 90) {
      return "_" + s.toLowerCase();
    }
    return "__" + ("000" + c.toString(16)).slice(-4);
  });
};

export const stringifyCircularObject = <T>(ref: T) => {
  return JSON.stringify(
    ref,
    (() => {
      const seen = new WeakSet();
      return (key: string, value: any) => {
        if (typeof value === "object" && value !== null) {
          if (seen.has(value)) {
            return;
          }
          seen.add(value);
        }
        return value;
      };
    })()
  );
};

export const isPrimitive = (obj: any) => {
  return obj !== Object(obj);
};

export const isPurePropsEqual = <T extends object>(prev: T, next: T) => {
  const prevPropsPure: Partial<T> = {};
  const nextPropsPure: Partial<T> = {};

  Object.keys(prev)
    .filter((key) => isPrimitive(prev[key as keyof T]))
    .forEach((key) => {
      prevPropsPure[key as keyof T] = prev[key as keyof T];
    });

  Object.keys(next)
    .filter((key) => isPrimitive(next[key as keyof T]))
    .forEach((key) => {
      nextPropsPure[key as keyof T] = next[key as keyof T];
    });

  return shallowequal(prevPropsPure, nextPropsPure);
};

export const compassObjUrlToID = (url: string): number => {
  return parseInt(url.split("/").reverse()[0], 10);
};

/**
 * Parse number to "E.164" format.
 * Default country code is: NL
 * @param phoneNumber - Phone number
 */
export const parsePhoneNumber = (phoneNumber: string): string => {
  const DEFAULT_FORMAT: NumberFormat = "E.164";

  const clearNumberParts = phoneNumber.match(/\d+|\+/g);
  if (!clearNumberParts) {
    return "";
  }
  const clearNumber = clearNumberParts.join("");
  const parsedNumber = parsePhoneNumberFromString(clearNumber);
  if (parsedNumber && parsedNumber.isValid()) {
    return parsedNumber.format(DEFAULT_FORMAT);
  }
  return clearNumber;
};

/**
 * Format number to acceptable by compass.js
 * @param destinationNumber Destination number
 */
export const parseDestinationNumber = (destinationNumber: string) => {
  return destinationNumber[0] === "+"
    ? `00${destinationNumber.slice(1)}`
    : destinationNumber;
};

export const matchPhoneNumbers = (number1: string, number2: string) => {
  return (
    parseDestinationNumber(number1).replace(/\D+/g, "") ===
    parseDestinationNumber(number2).replace(/\D+/g, "")
  );
};
