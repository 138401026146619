import * as React from "react";
import "./DialerNumpad.scss";
import Button from "src/components/UI/Button/Button";
import { BridgeColor } from "src/utils/consts";

interface IProps {
  onKeypress: any;
  currentValue: string;
  dark?: boolean;
  disabled?: boolean;
}

const keysMap = [
  ["1", "2", "3"],
  ["4", "5", "6"],
  ["7", "8", "9"],
  ["*", "0", "#"]
];

const dialerNumpad: React.FC<IProps> = props => {
  return (
    <div className="dialer-numpad">
      {keysMap.map((row, rowIdx) => {
        return (
          <div className="dialer-numpad__row" key={rowIdx}>
            {row.map((cell, cellIdx) => {
              return (
                <div className="dialer-numpad__col" key={cellIdx}>
                  <Button
                    onClick={props.onKeypress.bind(null, cell)}
                    color={props.dark ? BridgeColor.gs800 : BridgeColor.gs300}
                    className="button--dialpad"
                    disabled={
                      props.disabled ||
                      (!props.currentValue && /^[#*]$/.test(cell))
                    }
                  >
                    {cell}
                  </Button>
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
};

export default dialerNumpad;
