import * as React from "react";
import Input from "src/components/UI/Input/Input";
import Button from "src/components/UI/Button/Button";
import { Loader } from "src/components/UI/Loader/Loader";
import "./Login.scss";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { useIntl, FormattedMessage } from "react-intl";
import { navigationSet } from "src/store/actions/navigation";
import { IHomePageParams, NavigationPage } from "src/store/reducers/navigation";
import logoImg from "src/assets/imgs/bridge-logo-light.svg";
import packageJson from "../../../package.json";
import { notificationsDismissAll, notificationShow } from "src/store/actions";
import { login, beginOnboarding } from "src/store/actions/auth";
import { OnboardingType } from "src/store/reducers/auth";
import { BridgeColor } from "src/utils/consts";
import { useState } from "react";
import { INotification } from "src/store/reducers/notifications";
import { createSelector } from "reselect";

const appDataSelector = createSelector(
  [
    (state: IRootState) => state.preferences.user,
    (state: IRootState) => state.auth.loginInProgress,
    (state: IRootState) => state.window.online,
  ],
  (preferences, loginInProgress, online) => ({
    preferences,
    loginInProgress,
    online,
  })
);

const Login: React.FC = () => {
  const dispatch = useDispatch();
  const { preferences, loginInProgress, online } = useSelector(appDataSelector);
  const intl = useIntl();
  const loginFormInitial = {
    username: {
      elementType: "input",
      elementConfig: {
        type: "text",
        placeholder: intl.formatMessage({ id: "labels.username" }).toString(),
      },
      label: intl.formatMessage({ id: "labels.username" }).toString(),
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
    password: {
      elementType: "input",
      elementConfig: {
        type: "password",
        placeholder: intl.formatMessage({ id: "labels.password" }).toString(),
      },
      label: intl.formatMessage({ id: "labels.password" }).toString(),
      value: "",
      validation: {
        required: true,
      },
      valid: false,
      touched: false,
    },
  };
  const [loginForm, setLoginForm] = useState<any>(loginFormInitial);

  const [formIsValid, setFormIsValid] = useState(false);

  const onLogin = (username: string, password: string) =>
    dispatch<any>(login(username, password));
  const onNavigationSet = (page: NavigationPage, params: any) =>
    dispatch(navigationSet(page, params));
  const onNotificationsDismissAll = () => dispatch(notificationsDismissAll());
  const onNotificationShow = (
    notification:
      | INotification
      | Pick<INotification, "message" | "autoDismiss" | "dismissable" | "level">
  ) => dispatch(notificationShow(notification));
  const onBeginOnboarding = (type: OnboardingType) =>
    dispatch<any>(beginOnboarding(type));

  const onDemoClick = (e: React.MouseEvent) => {
    e.preventDefault();
    onBeginOnboarding(OnboardingType.guest);
  };

  const checkValidity = (value: any, rules: any) => {
    let isValid = true;
    if (!rules) {
      return true;
    }

    if (rules.required) {
      isValid = value.trim() !== "" && isValid;
    }

    return isValid;
  };

  const loginHandler = (event: any) => {
    event.preventDefault();
    onNotificationsDismissAll();
    const formData: any = {};
    for (const formElementIdentifier of Object.keys(loginForm)) {
      formData[formElementIdentifier] = loginForm[formElementIdentifier].value;
    }
    if (!formIsValid) {
      let message: string | undefined;
      if (!formData.username && !formData.password) {
        message = "Enter your username and password to sign in.";
      } else if (!formData.username) {
        message = "Username field cannot be empty.";
      } else if (!formData.password) {
        message = "Enter your password to sign in.";
      }
      if (message) {
        onNotificationShow({
          message,
          autoDismiss: 3000,
          level: "danger",
        });
      }
      return;
    }
    onLogin(formData.username, formData.password).then((loggedIn: any) => {
      if (loggedIn) {
        const params: IHomePageParams = {
          list: preferences.defaultHomeList,
          dialerActive: false,
          detailsOpened: false,
        };
        onNavigationSet(NavigationPage.home, params);
      }
    });
  };

  const inputChangedHandler = (event: any, inputIdentifier: any) => {
    const updatedOrderForm = {
      ...loginForm,
    };
    const updatedFormElement = {
      ...updatedOrderForm[inputIdentifier],
    };
    updatedFormElement.value = event.target.value;
    updatedFormElement.valid = checkValidity(
      updatedFormElement.value,
      updatedFormElement.validation
    );
    updatedFormElement.touched = true;
    updatedOrderForm[inputIdentifier] = updatedFormElement;

    let formIsValid = true;
    Object.keys(updatedOrderForm).forEach((key: string) => {
      formIsValid = updatedOrderForm[key].valid && formIsValid;
    });
    setFormIsValid(formIsValid);
    setLoginForm(updatedOrderForm);
  };

  const formElementsArray: any[] = [];
  Object.keys(loginForm).forEach((key: string) => {
    formElementsArray.push({
      id: key,
      config: loginForm[key],
    });
  });

  const inputs = formElementsArray.map((formElement, idx) => (
    <Input
      key={idx}
      elementId={formElement.id}
      elementType={formElement.config.elementType}
      elementConfig={formElement.config.elementConfig}
      value={formElement.config.value}
      invalid={!formElement.config.valid}
      shouldValidate={formElement.config.validation}
      touched={formElement.config.touched}
      label={formElement.config.label}
      changed={inputChangedHandler}
    />
  ));

  return (
    <div className="login">
      <div className="login__content">
        <div className="login__box">
          <div className="login__header">
            <div className="login__header-logo">
              <img src={logoImg} alt="" />
            </div>
            <div className="login__header-version">
              Version {packageJson.version}
            </div>
          </div>
          <form className="br-form login__form" onSubmit={loginHandler}>
            <div>{inputs}</div>
            <div className="login__form-submit-btn">
              {loginInProgress ? (
                <Loader />
              ) : (
                <Button color={BridgeColor.prim500} disabled={!online}>
                  <FormattedMessage id="login.btn" defaultMessage="Sign in" />
                </Button>
              )}
            </div>
            <div className="login__form-submit-btn">
              <Button
                color={BridgeColor.gs400}
                fill={"clear"}
                disabled={loginInProgress}
                onClick={onDemoClick}
              >
                <FormattedMessage
                  id="login.demo-btn"
                  defaultMessage="Show me around"
                />
              </Button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Login;
