import * as React from "react";
import { FC, useEffect, useRef, useState } from "react";
import "./NavbarDropdown.scss";
import { useDispatch, useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import {
  windowShowNavbarDropdown,
  windowHideNavbarDropdown,
} from "src/store/actions/window";
import { OnboardingStepId } from "src/utils/OnboardingStep";
import { TrackAction, TrackCategory, trackEvent } from "src/utils/track";
import { wrapOnboarding } from "src/utils/onboarding";
import { NavbarDropDownId } from "src/store/reducers/window";
import { IconDefinition } from "@fortawesome/fontawesome-common-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-regular-svg-icons/faCheck";
import { faTimes } from "@fortawesome/pro-regular-svg-icons/faTimes";
import { Tooltip } from "src/components/UI/Tooltip/Tooltip";
import { BridgeColor } from "src/utils/consts";
import { createSelector } from "reselect";

export enum MenuItemType {
  button = "button",
  separator = "separator",
  input = "input",
  header = "header",
}

export interface IMenuItem {
  action?: string;
  type: MenuItemType;
  title?: string;
  data?: any;
  selected?: boolean;
  icon?: IconDefinition;
  iconColor?: BridgeColor;
  track?: [TrackCategory, TrackAction];
  disabled?: boolean;
  tooltip?: string;
  onboardingStep?: OnboardingStepId;
}

const selectOnboardingMode = (state: IRootState) => state.auth.onboardingMode;
const selectNavbarDropdownVisible = (state: IRootState) =>
  state.window.navbarDropdownVisible;

const appDataSelector = createSelector(
  [selectOnboardingMode, selectNavbarDropdownVisible],
  (onboardingMode, navbarDropdownVisible) => ({
    onboardingMode,
    navbarDropdownVisible,
  })
);
const NavbarDropdown: FC<{
  items: IMenuItem[];
  id: NavbarDropDownId;
  onClick: (id: string, data?: any, value?: string) => void;
  children?: React.ReactNode;
}> = ({ items, onClick, id, children }) => {
  const { onboardingMode, navbarDropdownVisible } =
    useSelector(appDataSelector);

  const dispatch = useDispatch();
  const [openedInput, setOpenedInput] = useState<string | null>();
  const [openedInputValue, setOpenedInputValue] = useState<string>();

  const $openedInput = useRef<HTMLInputElement | null>(null);
  const $wrapperRef = useRef<HTMLDivElement | null>(null);

  const hideDropDown = React.useCallback(() => {
    if (openedInput && openedInputValue) {
      const menuItem = items.find((item) => item.action === openedInput);
      if (menuItem && menuItem.action) {
        onClick(menuItem.action, menuItem.data, openedInputValue);
      }
    }
    setOpenedInput(null);
    setOpenedInputValue("");
    dispatch(windowHideNavbarDropdown());
  }, [dispatch, items, onClick, openedInput, openedInputValue]);

  useEffect(() => {
    const onDocumentClick = (e: MouseEvent) => {
      if (onboardingMode) {
        return;
      }
      if (
        navbarDropdownVisible === id &&
        $wrapperRef.current &&
        !$wrapperRef.current.contains(e.target as Node)
      ) {
        hideDropDown();
      }
    };
    document.addEventListener("click", onDocumentClick);
    return () => {
      document.removeEventListener("click", onDocumentClick);
    };
  }, [hideDropDown, navbarDropdownVisible, onboardingMode, id]);

  const toggleDropdown = () => {
    if (onboardingMode) {
      return;
    }
    navbarDropdownVisible === id
      ? hideDropDown()
      : dispatch(windowShowNavbarDropdown(id));
  };

  const menuItemButtonClicked = (item: IMenuItem) => {
    if (onboardingMode || item.disabled) {
      return;
    }
    if (item.action) {
      onClick(item.action, item.data);
    }
    hideDropDown();
    if (item.track) {
      trackEvent.apply(null, item.track);
    }
  };

  const menuItemInputClicked = (item: IMenuItem) => {
    if (onboardingMode) {
      return;
    }
    if (item.action) {
      setOpenedInput(item.action);
      setTimeout(() => {
        $openedInput?.current?.focus();
      }, 100);
    }
  };

  const onOpenedInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOpenedInputValue(e.target.value);
  };

  const onOpenedInputCancel = (e: React.MouseEvent<HTMLInputElement>) => {
    setOpenedInputValue("");
    setOpenedInput(null);
  };

  const onOpenedInputKeyPress = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      hideDropDown();
    }
  };

  const $getMenu = () => {
    return (
      <div className="br-navbar-dropdown__menu">
        <div className="br-navbar-dropdown__menu-inner">
          {items.map((item, idx) => {
            let $item: React.ReactElement | null = null;
            switch (item.type) {
              case MenuItemType.button: {
                const cssClasses = ["br-navbar-dropdown__menu-item"];
                if (item.disabled) {
                  cssClasses.push("br-navbar-dropdown__menu-item--disabled");
                }
                $item = (
                  <div
                    key={idx}
                    className={cssClasses.join(" ")}
                    onClick={() => menuItemButtonClicked(item)}
                  >
                    {item.icon ? (
                      <div
                        data-color={item.iconColor || "default"}
                        className="br-navbar-dropdown__menu-item-icon"
                      >
                        <FontAwesomeIcon icon={item.icon} />
                      </div>
                    ) : null}
                    <div className="br-navbar-dropdown__menu-item-content">
                      {item.title}
                    </div>
                    {item.selected ? (
                      <div className="br-navbar-dropdown__menu-item-selected-icon">
                        <FontAwesomeIcon icon={faCheck} />
                      </div>
                    ) : null}
                  </div>
                );
                break;
              }
              case MenuItemType.input: {
                const isOpenedInput = openedInput === item.action;
                const cssClasses = ["br-navbar-dropdown__menu-item"];
                if (item.disabled) {
                  cssClasses.push("br-navbar-dropdown__menu-item--disabled");
                }
                $item = (
                  <div
                    key={idx}
                    className={cssClasses.join(" ")}
                    onClick={
                      isOpenedInput
                        ? null
                        : menuItemInputClicked.bind(this, item)
                    }
                  >
                    <>
                      {item.icon ? (
                        <div
                          className={`br-navbar-dropdown__menu-item-icon br-navbar-dropdown__menu-item-icon--color-${
                            item.iconColor || "default"
                          }`}
                        >
                          <FontAwesomeIcon icon={item.icon} />
                        </div>
                      ) : null}
                      <div
                        className="br-navbar-dropdown__menu-item-content"
                        style={{ display: isOpenedInput ? "none" : undefined }}
                      >
                        {item.title}
                      </div>
                      <div
                        className="br-navbar-dropdown__menu-item-input"
                        style={{ display: !isOpenedInput ? "none" : undefined }}
                      >
                        <input
                          placeholder="Type here ..."
                          ref={$openedInput}
                          value={openedInputValue}
                          onChange={onOpenedInputChange}
                          onKeyPress={onOpenedInputKeyPress}
                        />
                        {openedInputValue ? (
                          <div
                            onClick={onOpenedInputCancel}
                            className="br-navbar-dropdown__menu-item-input-cancel"
                          >
                            <FontAwesomeIcon
                              className="br-navbar-dropdown__menu-item-input-cancel-icon"
                              icon={faTimes}
                            />
                          </div>
                        ) : null}
                      </div>
                      {item.selected && !isOpenedInput ? (
                        <div className="br-navbar-dropdown__menu-item-selected-icon">
                          <FontAwesomeIcon icon={faCheck} />
                        </div>
                      ) : null}
                    </>
                  </div>
                );
                break;
              }
              case MenuItemType.header:
                $item = (
                  <div key={idx} className="br-navbar-dropdown__header">
                    {item.title}
                  </div>
                );
                break;
              case MenuItemType.separator:
                $item = <hr key={idx} />;
                break;
            }
            if ($item && item.onboardingStep) {
              return wrapOnboarding(
                $item,
                item.onboardingStep,
                {
                  placement: "left",
                },
                true,
                `navbar-dropdown-${id}:${idx}`
              );
            }
            if (item.tooltip && $item) {
              return (
                <Tooltip key={idx} content={item.tooltip}>
                  {$item}
                </Tooltip>
              );
            }
            return $item;
          })}
        </div>
      </div>
    );
  };

  return (
    <div className="br-navbar-dropdown" ref={$wrapperRef}>
      <div className="br-navbar-dropdown__btn" onClick={toggleDropdown}>
        {children}
      </div>
      {items && navbarDropdownVisible === id
        ? wrapOnboarding($getMenu(), OnboardingStepId.sectionNavbarDropdown, {
            placement: "left",
          })
        : null}
    </div>
  );
};

export default NavbarDropdown;
