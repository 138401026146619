import { userStorage } from "./userStorage";
import { DateTime } from "luxon";
import { store } from "src/store";
import { showAskFeedbackModal } from "src/store/actions/auth";

export interface IUsageInfo {
  firstLogin: number;
  byDays: {
    [key: string]: {
      minutes: number;
    };
  };
}

export interface IFeedbackModalInfo {
  showNumber: number;
  sentNumber: number;
  lastShow?: number;
  lastSent?: number;
}

const FEEDBACK_MODAL_INFO_KEY = "auth:feedback-modal-info-key:";
const APP_USAGE_INFO_KEY = "auth:app-usage-info-key";

class AppUsage {
  private usageInterval: NodeJS.Timer | null;
  private usageInfo: IUsageInfo | null;
  private feedbackModalInfo: IFeedbackModalInfo | null;

  async init() {
    let usageInfo: IUsageInfo | null = await userStorage.getItem(
      APP_USAGE_INFO_KEY
    );
    if (!usageInfo) {
      usageInfo = {
        firstLogin: +new Date(),
        byDays: {},
      };
      await userStorage.setItem(APP_USAGE_INFO_KEY, usageInfo);
    }
    this.usageInfo = usageInfo;
    let feedbackModalInfo: IFeedbackModalInfo | null =
      await userStorage.getItem(FEEDBACK_MODAL_INFO_KEY);
    if (!feedbackModalInfo) {
      feedbackModalInfo = {
        showNumber: 0,
        sentNumber: 0,
      };
      await userStorage.setItem(FEEDBACK_MODAL_INFO_KEY, feedbackModalInfo);
    }
    this.feedbackModalInfo = feedbackModalInfo;

    // NOTE: log usage time every minute and check if user should be asked for feedback
    this.usageInterval = setInterval(async () => {
      const date = DateTime.local().toISODate();
      if (!this.usageInfo) {
        console.warn(`WARNING: utils/appUsage.ts usageInfo is empty`);
        return;
      }

      if (date) {
        if (!(date in this.usageInfo.byDays)) {
          this.usageInfo.byDays[date] = {
            minutes: 0,
          };
        }
        this.usageInfo.byDays[date].minutes += 1;
      }
      await userStorage.setItem(APP_USAGE_INFO_KEY, this.usageInfo);
      if (this.shouldAskFeedback()) {
        this.askFeedback();
      }
    }, 60000);
    if (this.shouldAskFeedback()) {
      this.askFeedback();
    }
  }

  reset() {
    if (this.usageInterval) {
      clearInterval(this.usageInterval);
      this.usageInterval = null;
    }
    this.usageInfo = null;
    this.feedbackModalInfo = null;
  }

  async logSentFeedback() {
    if (this.feedbackModalInfo) {
      this.feedbackModalInfo.sentNumber +=
        (this.feedbackModalInfo.sentNumber || 0) + 1;
      this.feedbackModalInfo.lastSent = +new Date();
      await userStorage.setItem(
        FEEDBACK_MODAL_INFO_KEY,
        this.feedbackModalInfo
      );
    }
  }

  private async askFeedback() {
    if (!this.feedbackModalInfo) {
      console.warn(`WARNING: utils/appUsage.ts feedbackModalInfo is empty`);
      return;
    }
    store.dispatch(showAskFeedbackModal());
    this.feedbackModalInfo.lastShow = +new Date();
    this.feedbackModalInfo.showNumber += 1;
    await userStorage.setItem(FEEDBACK_MODAL_INFO_KEY, this.feedbackModalInfo);
  }

  /**
   * Check if we automatically should ask feedback
   * rules:
   * - After the first time they sign into the application, users should be
   *   presented with the "Feedback popup" after 5 days of using application
   *   for more than 2 hours a day.
   *
   * - If they skipped giving feedback, as again with the same interval.
   *
   * - If they skipped the second time; ask after 12 weeks.
   *
   * - If they said they wanted to give feedback and sent an email or initiated
   *   a Zendesk ticket, ask again after 12 weeks.
   *
   * - After the 12 week interval has ended, ask again after 6 months. This
   *   interval should stick until we don't want to ask for feedback any more.
   */
  private shouldAskFeedback(): boolean {
    if (!this.feedbackModalInfo || !this.usageInfo) {
      console.warn(
        `WARNING: utils/appUsage.ts feedbackModalInfo or usageInfo is empty`
      );
      return false;
    }
    // NOTE: #669 don't ask user for feedback when there are active calls
    const user = store.getState().auth.user;
    if (user && user.getCalls().length) {
      return false;
    }
    // NOTE: user didn't send feedback yet
    if (this.feedbackModalInfo.sentNumber === 0) {
      // NOTE: first two times show after 5 days of 2 hours usage
      if (this.feedbackModalInfo.showNumber <= 1) {
        const expected2HoursUsageDays =
          this.feedbackModalInfo.showNumber === 0 ? 5 : 10;
        const daysWith2HoursUsage = Object.values(this.usageInfo.byDays).filter(
          (item) => item.minutes >= 120
        );
        return daysWith2HoursUsage.length >= expected2HoursUsageDays;
      }
      // NOTE: after user skipping second time, show after 12 weeks
      if (
        this.feedbackModalInfo.showNumber === 2 &&
        this.feedbackModalInfo.lastShow
      ) {
        return (
          DateTime.fromMillis(this.feedbackModalInfo.lastShow).plus({
            weeks: 12,
          }) <= DateTime.local()
        );
      }
    }
    // NOTE: if user sent feedback 1 time, ask again after 12 weeks
    if (
      this.feedbackModalInfo.sentNumber === 1 &&
      this.feedbackModalInfo.lastSent &&
      // NOTE: if user was already asked for feedback after 12 weeks interval, go to 6 months
      this.feedbackModalInfo.showNumber <= 3
    ) {
      return (
        DateTime.fromMillis(this.feedbackModalInfo.lastSent).plus({
          weeks: 12,
        }) <= DateTime.local()
      );
    }
    // NOTE: after second send, or third skip, ask again after 6 months
    const countFrom = this.feedbackModalInfo.lastShow;
    if (countFrom) {
      return (
        DateTime.fromMillis(countFrom).plus({
          months: 6,
        }) <= DateTime.local()
      );
    }
    return false;
  }
}

export const appUsage = new AppUsage();
