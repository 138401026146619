import * as actionTypes from "./actionTypes";
import { store } from "..";

const TICK_INTERVAL_DURATION = 1000;

export const tickIntervalInit = (): { type: string; payload: any } => {
  const intervalId = setInterval(() => {
    if (store.getState().tickInterval.paused) {
      return;
    }
    Object.values(store.getState().tickInterval.registeredFunctions).forEach(
      func => func()
    );
  }, TICK_INTERVAL_DURATION);
  return {
    type: actionTypes.TICK_INTERVAL_INIT,
    payload: {
      id: intervalId
    }
  };
};

export const tickIntervalDestroy = (): { type: string } => {
  clearInterval(store.getState().tickInterval.intervalId);
  return {
    type: actionTypes.TICK_INTERVAL_DESTROY
  };
};

export const tickIntervalRegister = (id: string, func: () => void) => {
  return {
    type: actionTypes.TICK_INTERVAL_REGISTER,
    payload: {
      id,
      func
    }
  };
};

export const tickIntervalUnregister = (id: string) => {
  return {
    type: actionTypes.TICK_INTERVAL_UNREGISTER,
    payload: {
      id
    }
  };
};

export const tickIntervalPause = () => {
  return {
    type: actionTypes.TICK_INTERVAL_PAUSE
  };
};

export const tickIntervalUnpause = () => {
  return {
    type: actionTypes.TICK_INTERVAL_UNPAUSE
  };
};
