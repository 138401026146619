import * as React from "react";
import { faPhone } from "@fortawesome/pro-solid-svg-icons/faPhone";
import { faPause } from "@fortawesome/pro-solid-svg-icons/faPause";
import { faShare } from "@fortawesome/pro-solid-svg-icons/faShare";
import { faShareAll } from "@fortawesome/pro-solid-svg-icons/faShareAll";
import { faBan } from "@fortawesome/pro-solid-svg-icons/faBan";
import { faTimes } from "@fortawesome/pro-solid-svg-icons/faTimes";
import { faUser } from "@fortawesome/pro-solid-svg-icons/faUser";
import { faTh } from "@fortawesome/pro-solid-svg-icons/faTh";
import { faThumbtack } from "@fortawesome/pro-solid-svg-icons/faThumbtack";
import { faAngleDown } from "@fortawesome/pro-solid-svg-icons/faAngleDown";
import { faAngleUp } from "@fortawesome/pro-solid-svg-icons/faAngleUp";
import { faClock } from "@fortawesome/pro-solid-svg-icons/faClock";
import { faSearch } from "@fortawesome/pro-solid-svg-icons/faSearch";
import { faUserFriends } from "@fortawesome/pro-solid-svg-icons/faUserFriends";
import { faCircle } from "@fortawesome/pro-solid-svg-icons/faCircle";
import { faSlidersH } from "@fortawesome/pro-regular-svg-icons/faSlidersH";
import { faEllipsisH } from "@fortawesome/pro-regular-svg-icons/faEllipsisH";
import { faDeaf } from "@fortawesome/pro-regular-svg-icons/faDeaf";
import { faEar } from "@fortawesome/pro-regular-svg-icons/faEar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./IconInformationModal.scss";
import { IconDefinition } from "@fortawesome/fontawesome-svg-core";

interface IIconsInfoSection {
  title: string;
  icons: Array<{
    icon: IconDefinition;
    label: string;
    color?: string;
  }>;
}

const ICONS_INFO: IIconsInfoSection[] = [
  {
    title: "Call actions",
    icons: [
      {
        icon: faPhone,
        label: "Start/answer a call"
      },
      {
        icon: faTimes,
        label: "Hang up the call"
      },
      {
        icon: faPause,
        label: "Put the call on/off hold"
      },
      {
        icon: faShare,
        label: "Transfer the call"
      },
      {
        icon: faShareAll,
        label: "Initiate attended transfer"
      },
      {
        icon: faBan,
        label: "Cancel attended transfer"
      },
      {
        icon: faEar,
        label: "Listen in the call"
      },
      {
        icon: faDeaf,
        label: "End listening in the call"
      }
    ]
  },
  {
    title: "Actions",
    icons: [
      {
        icon: faUser,
        label: "Open contacts list"
      },
      {
        icon: faUserFriends,
        label: "Open queue list"
      },
      {
        icon: faTh,
        label: "Open dialpad"
      },
      {
        icon: faClock,
        label: "Toggle queue pause"
      },
      {
        icon: faThumbtack,
        label: "Pin/unpin a contact or queue to top"
      },
      {
        icon: faSlidersH,
        label: "Manage agents in queue"
      },
      {
        icon: faAngleDown,
        label: "Open details"
      },
      {
        icon: faAngleUp,
        label: "Close details"
      },
      {
        icon: faSearch,
        label: "Toggle search input"
      },
      {
        icon: faEllipsisH,
        label: "Additional actions"
      }
    ]
  },
  {
    title: "Contact status",
    icons: [
      {
        icon: faCircle,
        label: "Available",
        color: "status-available"
      },
      {
        icon: faCircle,
        label: "Logged out",
        color: "status-logged-out"
      },
      {
        icon: faCircle,
        label: "No queue calls",
        color: "status-in-call"
      },
      {
        icon: faCircle,
        label: "No calls / In call",
        color: "status-away"
      }
    ]
  }
];

class IconInformationModal extends React.Component {
  public render() {
    return (
      <div className="icon-information-modal">
        <div className="icon-information-modal__row">
          {ICONS_INFO.map((item, idx) => {
            return (
              <div className="icon-information-modal__col" key={idx}>
                <div className="icon-information-modal__col-title">
                  {item.title}
                </div>
                <div className="icon-information-madal__col-items">
                  {item.icons.map((iconItem, iconItemIdx) => {
                    return (
                      <div
                        className="icon-information-madal__col-item"
                        key={iconItemIdx}
                      >
                        <div
                          className={
                            "icon-information-modal__col-item-icon" +
                            (iconItem.color
                              ? ` br-color--${iconItem.color}`
                              : "")
                          }
                        >
                          <FontAwesomeIcon icon={iconItem.icon} />
                        </div>
                        <div className="icon-information-modal__col-item-label">
                          {iconItem.label}
                        </div>
                      </div>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default IconInformationModal;
