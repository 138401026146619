import * as React from "react";
import { IRootState } from "../../../store/reducers";
import { useDispatch, useSelector } from "react-redux";
import { Call, User, Side, CallPointState } from "compass.js";
import { shouldShowCallForUser, getUserSide } from "src/utils/call";
import ringing from "src/assets/sounds/ringing.mp3";
import { PhoneActionType, phoneAction } from "src/store/actions";
import { PhoneCapability, UserFeature } from "src/store/reducers/auth";
import { canAuthenticatedUserUseFeature } from "src/utils/user";
import { useCallback, useEffect, useRef, useState } from "react";
import { isElectron } from "src/utils";
import { createSelector } from "reselect";

interface ServiceWorkerMessageEvent extends Event {
  data: {
    type: string;
    action?: string;
    notification?: string;
  };
}
interface ExtendedNotificationOptions extends NotificationOptions {
  actions?: Array<{ action: string; title: string }>;
}

const selectPhoneCapabilities = (state: IRootState) =>
  state.auth.phone?.capabilities || [];
const selectAuthUser = (state: IRootState) => state.auth.user as User;
const selectCallsItems = (state: IRootState) => state.calls.items;
const selectCallsMetadata = (state: IRootState) => state.calls.callsMetadata;
const selectOnline = (state: IRootState) => state.window.online;
const selectOnboardingMode = (state: IRootState) => state.auth.onboardingMode;
const appDataSelector = createSelector(
  [
    selectPhoneCapabilities,
    selectAuthUser,
    selectCallsItems,
    selectCallsMetadata,
    selectOnline,
    selectOnboardingMode,
  ],
  (
    phoneCapabilities,
    authUser,
    callsItems,
    callsMetadata,
    online,
    onboardingMode
  ) => {
    return {
      phoneCapabilities,
      authUser,
      callsItems,
      callsMetadata,
      online,
      onboardingMode,
    };
  }
);

const CallNotifications = () => {
  const {
    phoneCapabilities,
    authUser,
    callsItems,
    callsMetadata,
    online,
    onboardingMode,
  } = useSelector(appDataSelector);

  const [ignore, setIgnore] = useState(true);
  const [initialized, setInitialized] = useState(false);
  const [swRegistration, setSwRegistration] = useState<
    ServiceWorkerRegistration | undefined
  >(undefined);
  const audioRef = useRef<HTMLAudioElement | null>(null);
  const shownNotifications = useRef<{
    [key: string]: Notification | undefined;
  }>({});
  const swApi = navigator.serviceWorker;
  const dispatch = useDispatch();

  const ringingCalls = React.useMemo(() => {
    return callsItems.filter((call) => {
      const selfSide = getUserSide(call, authUser);
      return (
        shouldShowCallForUser(call, authUser) &&
        selfSide === Side.destination &&
        call.destination.state === CallPointState.ringing &&
        !callsMetadata[call.id].isEnded
      );
    });
  }, [authUser, callsItems, callsMetadata]);

  const onSWNotificationClick = useCallback(
    (e: ServiceWorkerMessageEvent) => {
      const onPhoneActionHandler = (
        callId: Call["id"],
        action: PhoneActionType
      ) => dispatch<any>(phoneAction(callId, action));
      if (e.data.type !== "notification-clicked") return;

      focusWindow();
      if (!e.data.action) return;

      const call = ringingCalls.find((item) => item.id === e.data.notification);
      if (!call) return;

      switch (e.data.action) {
        case "answer":
          onPhoneActionHandler(call.id, PhoneActionType.answerCall);
          break;
        case "decline":
          onPhoneActionHandler(call.id, PhoneActionType.hangupCall);
          break;
      }
    },
    [ringingCalls, dispatch]
  );

  const updateAudio = useCallback(() => {
    if (!audioRef.current) return;

    if (ringingCalls.length && online) {
      if (audioRef.current.paused) {
        audioRef.current.play().catch(console.error);
      }
    } else {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }
  }, [ringingCalls, online]);

  const clearExpiredNotifications = useCallback(() => {
    const callsIds = ringingCalls.map((item) => item.id);
    Object.keys(shownNotifications.current).forEach((id) => {
      if (!callsIds.includes(id)) {
        const notification = shownNotifications.current[id];
        if (notification) {
          notification.close();
          removeNotification(id);
        }
      }
    });
  }, [ringingCalls]);

  const showNotifications = useCallback(() => {
    const onNotificationClick = (e: Event) => {
      e.preventDefault();
      focusWindow();
      (e.target as Notification).close();
    };

    if (ignore || onboardingMode) return;

    clearExpiredNotifications();

    ringingCalls.forEach((call) => {
      if (call.id in shownNotifications.current) return;

      const title = callsMetadata[call.id].lastConnectedTitle;
      const queue = callsMetadata[call.id].queue;
      const body = queue
        ? `From Bridge, queue ${queue.name}`
        : "From Bridge, direct call";
      const options: ExtendedNotificationOptions = {
        body,
        requireInteraction: true,
        lang: "en",
        dir: "ltr",
        tag: call.id,
      };

      if (swRegistration && "showNotification" in swRegistration) {
        options.actions = [{ action: "decline", title: "Decline" }];
        if (
          phoneCapabilities.includes(PhoneCapability.answer) &&
          canAuthenticatedUserUseFeature(UserFeature.callcontrol)
        ) {
          options.actions.unshift({ action: "answer", title: "Answer" });
        }

        const registration = swRegistration;
        removeNotification(call.id);
        registration.showNotification(title, options).then(() => {
          registration
            .getNotifications({ tag: call.id })
            .then((notifications) => {
              if (notifications.length) {
                const notification = notifications[0];
                shownNotifications.current[call.id] = notification;
                notification.addEventListener("click", onNotificationClick);
              }
            });
        });
      } else {
        const notification = new Notification(title, options);
        notification.addEventListener("click", onNotificationClick);
        shownNotifications.current[call.id] = notification;
      }
    });
  }, [
    ignore,
    onboardingMode,
    clearExpiredNotifications,
    ringingCalls,
    callsMetadata,
    swRegistration,
    phoneCapabilities,
  ]);

  const focusWindow = () => {
    if (isElectron()) {
      window.remote.getCurrentWindow().show();
    } else {
      window.focus();
    }
  };

  const onSWRegister = (swRegistration: ServiceWorkerRegistration) => {
    setInitialized(true);
    setSwRegistration(swRegistration);
  };

  const checkNotificationsAccess = () => {
    if (!("Notification" in window) || Notification.permission === "denied") {
      setIgnore(true);
    } else if (Notification.permission === "granted") {
      setIgnore(false);
    } else {
      Notification.requestPermission((permission) => {
        setIgnore(permission === "denied");
      });
    }
  };

  const removeNotification = (callId: string) => {
    const notification = shownNotifications.current[callId];
    if (notification) {
      delete shownNotifications.current[callId];
      notification.close();
    }
  };

  useEffect(() => {
    const closeAllNotifications = () => {
      Object.values(shownNotifications.current).forEach((item) => {
        if (item) {
          removeNotification(item.tag);
        }
      });
    };

    checkNotificationsAccess();

    window.addEventListener("beforeunload", closeAllNotifications);
    window.addEventListener("offline", closeAllNotifications);

    if (swApi) {
      swApi.addEventListener("message", onSWNotificationClick);
      swApi.register("/notifications-service-worker.js").then(onSWRegister);
    } else {
      setInitialized(true);
    }

    return () => {
      window.removeEventListener("beforeunload", closeAllNotifications);
      window.removeEventListener("offline", closeAllNotifications);
      if (swApi) {
        swApi.removeEventListener("message", onSWNotificationClick);
      }
    };
  }, [swApi, onSWNotificationClick]);

  useEffect(() => {
    updateAudio();
    showNotifications();
  }, [ringingCalls, online, initialized, updateAudio, showNotifications]);

  return (
    <audio ref={audioRef} preload="auto" autoPlay={false} loop={true}>
      <source src={ringing} type="audio/mpeg" />
    </audio>
  );
};

export default CallNotifications;
