import { store } from "src/store";

export const dispatchWithProgress = <PropType>(
  action: Promise<any>,
  startedAction: string,
  completedAction: string
): Promise<PropType> => {
  return new Promise((resolve, reject) => {
    store.dispatch({ type: startedAction });
    action.then(
      e => {
        store.dispatch({ type: completedAction });
        resolve(e);
      },
      error => {
        store.dispatch({ type: completedAction });
        reject(error);
      }
    );
  });
};
