import * as React from "react";
import { useSelector } from "react-redux";
import { IRootState } from "src/store/reducers";
import { ContactType, IContact } from "src/store/reducers/contacts";
import { sortByProperty, sortIgnoreCaseComparator } from "src/utils";
import "./ContactsOverviewList.scss";
import ContactItem from "src/components/ContactsOverviewList/ContactsOverviewItem/ContactsOverviewItem";
import { User } from "compass.js";
import { trackEvent, TrackCategory, TrackAction } from "src/utils/track";
import { remove as removeDiacritics } from "diacritics";
import { Fragment, useEffect, useRef, useState } from "react";

const LETTER_BLINK_CSS_CLASS = "contacts-overview-list__letter--blink";
const ALPHABET_ARRAY = "#abcdefghijklmnopqrstuvwxyz".split("");
const TRACK_CATEGORY = TrackCategory.contactsOverview;

const appDataSelector = (state: IRootState) => {
  const compassItems = state.contacts.compassItems;
  const authUser = (state.auth.user as User)?.id;

  return {
    compassItems,
    authUser,
  };
};

const ContactsOverviewList: React.FC = () => {
  const { compassItems, authUser } = useSelector(appDataSelector);

  const contacts = React.useMemo(() => {
    return sortByProperty<IContact, string>(
      Object.values(compassItems).filter(
        (contact) =>
          contact.type === ContactType.compass && contact.id !== authUser
      ),
      "name",
      sortIgnoreCaseComparator
    );
  }, [authUser, compassItems]);

  const [scrollToLetter, setScrollToLetter] = useState<string | null>(null);
  const lettersMapRef = useRef<{ [key: string]: HTMLDivElement | null }>({});
  const observedLetterRef = useRef<HTMLElement | undefined>();
  const intersectionObserverRef = useRef<IntersectionObserver | null>(null);

  const lettersObj: { [key: string]: IContact[] } = {};

  contacts.forEach((contact) => {
    let letter =
      contact.name && contact.name.length
        ? removeDiacritics(contact.name)[0].toLowerCase()
        : "#";
    if (!ALPHABET_ARRAY.includes(letter)) {
      letter = "#";
    }
    if (!(letter in lettersObj)) {
      lettersObj[letter] = [];
    }
    lettersObj[letter].push(contact);
  });

  const letters: string[] = Object.keys(lettersObj).sort();

  const cleanObservedLetter = () => {
    if (!observedLetterRef.current) {
      return;
    }
    intersectionObserverRef?.current?.unobserve(observedLetterRef.current);
    observedLetterRef.current = undefined;
  };

  const navigationLetterClicked = (letter: string) => {
    trackEvent(TRACK_CATEGORY, TrackAction.contactsOverviewLetterClick);
    setScrollToLetter(letter);
  };

  const blinkLetterElement = ($element: HTMLElement) => {
    $element.classList.add(LETTER_BLINK_CSS_CLASS);
    setTimeout(() => {
      $element.classList.remove(LETTER_BLINK_CSS_CLASS);
    }, 300);
  };

  useEffect(() => {
    // NOTE: highlight letter when user navigation to it
    // only when it getting fully visible
    intersectionObserverRef.current = new IntersectionObserver(
      (entries) => {
        if (!observedLetterRef.current) {
          return;
        }
        const entry = entries.find(
          (item) => item.target === observedLetterRef.current
        );
        if (!entry || !entry.isIntersecting || entry.intersectionRatio !== 1) {
          return;
        }
        blinkLetterElement(observedLetterRef.current);
        cleanObservedLetter();
      },
      { threshold: [1] }
    );
  });

  useEffect(() => {
    if (scrollToLetter === null) {
      return;
    }
    const $letter = lettersMapRef.current[scrollToLetter];
    if ($letter === null) {
      return;
    }
    if (observedLetterRef.current) {
      cleanObservedLetter();
    }
    setScrollToLetter(null);
    $letter.scrollIntoView({
      behavior: "smooth",
      inline: "start",
    });
    intersectionObserverRef?.current?.observe($letter);
    observedLetterRef.current = $letter;

    return () => {
      cleanObservedLetter();
    };
  }, [scrollToLetter]);

  return (
    <>
      <div className="contacts-overview-list__navigation">
        {ALPHABET_ARRAY.map((letter) => {
          const isActive = letter in lettersObj;
          const cssClasses = ["contacts-overview-list__navigation-letter"];
          if (!isActive) {
            cssClasses.push(
              "contacts-overview-list__navigation-letter--inactive"
            );
          }
          return (
            <div
              className={cssClasses.join(" ")}
              onClick={
                isActive ? navigationLetterClicked.bind(null, letter) : null
              }
              key={`letters-${letter}`}
            >
              {letter}
            </div>
          );
        })}
      </div>
      <div className="contacts-overview-list__wrap">
        <div className="contacts-overview-list">
          {letters.map((letter) => (
            <Fragment key={`contacts-items-${letter}`}>
              <div
                className="contacts-overview-list__letter"
                ref={(ref) => {
                  lettersMapRef.current[letter] = ref; // Store the reference but do not return it
                }}
              >
                <div className="contacts-overview-list__letter-inner">
                  {letter}
                </div>
              </div>
              {lettersObj[letter].map((contact) => (
                <div
                  className="contacts-overview-list__contact-item-wrap"
                  key={contact.id}
                >
                  <ContactItem contact={contact} />
                </div>
              ))}
            </Fragment>
          ))}
        </div>
      </div>
    </>
  );
};

export default ContactsOverviewList;
