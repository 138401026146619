import * as React from "react";
import "./ListRow.scss";

interface IProps {
  children?: React.ReactNode;
  onClick?: any;
  active?: boolean;
  disabled?: boolean;
  className?: string;
  id?: string;
}

// NOTE: using class due to this component used in FlipMove
// which is not supporting SFC components as direct child
export const ListRow: React.FC<IProps> = (props) => {
  const cssClasses = ["list__row"];
  if (props.onClick) {
    cssClasses.push("list__row--clickable");
  }
  if (props.disabled) {
    cssClasses.push("list__row--disabled");
  }
  if (props.active) {
    cssClasses.push("list__row--active");
  }
  if (props.className) {
    cssClasses.push(props.className);
  }
  return (
    <div className={cssClasses.join(" ")} onClick={props.onClick} id={props.id}>
      {props.children}
    </div>
  );
};
