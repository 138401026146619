import * as React from "react";
import Notification from "src/components/Notification/Notification";
import { IRootState } from "src/store/reducers";
import { useDispatch, useSelector } from "react-redux";
import "./Notifications.scss";
import { notificationDismiss } from "src/store/actions/notifications";
import { createSelector } from "reselect";

const selectNotifications = (state: IRootState) => state.notifications.items;

const appDataSelector = createSelector(
  [selectNotifications],
  (notifications) => ({
    notifications,
  })
);

const Notifications: React.FC = () => {
  const dispatch = useDispatch();
  const { notifications } = useSelector(appDataSelector);
  const onNotificationRemove = (uid: string) =>
    dispatch(notificationDismiss(uid));

  const dismissNotification = (uid: string) => {
    onNotificationRemove(uid);
  };
  return (
    <div className="notifications">
      {notifications.length
        ? notifications.map((item, idx) => {
            return (
              <Notification
                {...item}
                key={item.uid}
                onDismiss={dismissNotification.bind(this, item.uid)}
              />
            );
          })
        : null}
    </div>
  );
};

export default Notifications;
