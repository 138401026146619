import * as actionTypes from "src/store/actions/actionTypes";

export interface IHistoryItem {
  readonly id: number;
  readonly phone: string;
  readonly date: string;
  readonly name?: string;
  readonly isMissed?: boolean;
  readonly isOutgoing?: boolean;
}

export interface IHistoryState {
  readonly items: IHistoryItem[];
}

const initialState: IHistoryState = {
  items: []
};

const reducer = (
  state: IHistoryState = initialState,
  action: any
): IHistoryState => {
  switch (action.type) {
    case actionTypes.HISTORY_FETCH:
      return {
        ...state,
        items: action.historyItems
      };
    default:
      return state;
  }
};

export default reducer;
